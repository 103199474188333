import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { registerPlugin } from 'react-filepond';
// import Scss
import './assets/scss/themes.scss';
// regenerator-runtime
import 'regenerator-runtime/runtime';
// imoprt Route
import Route from './Routes';
// Import FilePond styles
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import { ToastContainer } from 'react-toastify';
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize);
function App() {
    return (_jsxs(React.Fragment, { children: [_jsx(ToastContainer, { theme: "colored", position: "top-right", autoClose: 3000, hideProgressBar: false, newestOnTop: false, closeOnClick: true, rtl: false, pauseOnFocusLoss: false, draggable: false, pauseOnHover: false, limit: 0 }), _jsx(Route, {})] }));
}
export default App;
