var _a;
/* eslint-disable camelcase */
import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    user: null,
    error: '', // for error message
    loading: false,
    isUserLogout: false,
    errorMsg: false // for error
};
var loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        apiError: function (state, action) {
            state.error = action.payload.data;
            state.loading = true;
            state.isUserLogout = false;
            state.errorMsg = true;
        },
        loginSuccess: function (state, action) {
            state.user = action.payload;
            state.loading = false;
            state.errorMsg = false;
        },
        logoutUserSuccess: function (state, action) {
            state.isUserLogout = true;
            state.user = null;
        },
        resetLoginFlags: function (state) {
            state.error = '';
            state.loading = false;
            state.errorMsg = false;
        }
    }
});
export var apiError = (_a = loginSlice.actions, _a.apiError), loginSuccess = _a.loginSuccess, logoutUserSuccess = _a.logoutUserSuccess, resetLoginFlags = _a.resetLoginFlags;
export default loginSlice.reducer;
