import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, FormFeedback, Input, Label } from 'reactstrap';
var SwitchComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    return (_jsxs(Col, { xs: ((_a = props.field) === null || _a === void 0 ? void 0 : _a.xs) || 12, sm: ((_b = props.field) === null || _b === void 0 ? void 0 : _b.sm) || 12, md: ((_c = props.field) === null || _c === void 0 ? void 0 : _c.md) || 8, lg: ((_d = props.field) === null || _d === void 0 ? void 0 : _d.lg) || 6, xl: ((_e = props.field) === null || _e === void 0 ? void 0 : _e.xl) || 6, children: [_jsxs("div", { className: "d-flex align-items-center justify-content-between form-switch form-switch-md mt-2 px-0", children: [((_f = props.field) === null || _f === void 0 ? void 0 : _f.label) && (_jsxs(Label, { htmlFor: (_g = props.field) === null || _g === void 0 ? void 0 : _g.value, className: "form-label ".concat(props.labelCss && props.labelCss), children: [props.field.label, props.mode !== 'view' && props.field.mandatory ? (_jsx("span", { className: "text-danger ps-1", children: " *" })) : ('' // <span className="ps-1"> :</span>
                            )] })), _jsx(Input, { role: "switch", name: (_h = props.field) === null || _h === void 0 ? void 0 : _h.value, type: "checkbox", placeholder: "".concat('Enter', " ").concat(props.field.label), disabled: props.mode === 'view' ? true : (_j = props.field) === null || _j === void 0 ? void 0 : _j.disabled, onChange: props.validation.handleChange, onBlur: props.validation.values.handleBlur, value: ((_l = (_k = props === null || props === void 0 ? void 0 : props.validation) === null || _k === void 0 ? void 0 : _k.values) === null || _l === void 0 ? void 0 : _l[(_m = props.field) === null || _m === void 0 ? void 0 : _m.value]) || false, checked: typeof props.validation.values[props.field.value] === 'boolean'
                            ? props.validation.values[props.field.value]
                            : props.validation.values[props.field.value] === 'true', invalid: ((_p = (_o = props === null || props === void 0 ? void 0 : props.validation) === null || _o === void 0 ? void 0 : _o.touched) === null || _p === void 0 ? void 0 : _p[(_q = props.field) === null || _q === void 0 ? void 0 : _q.value]) && ((_s = (_r = props.validation) === null || _r === void 0 ? void 0 : _r.errors) === null || _s === void 0 ? void 0 : _s[(_t = props.field) === null || _t === void 0 ? void 0 : _t.value]) ? true : false }), ((_u = props === null || props === void 0 ? void 0 : props.validation) === null || _u === void 0 ? void 0 : _u.touched[(_v = props.field) === null || _v === void 0 ? void 0 : _v.value]) && ((_w = props === null || props === void 0 ? void 0 : props.validation) === null || _w === void 0 ? void 0 : _w.errors[(_x = props.field) === null || _x === void 0 ? void 0 : _x.value]) ? (_jsx(FormFeedback, { type: "invalid", children: _jsx("div", { children: (_y = props === null || props === void 0 ? void 0 : props.validation) === null || _y === void 0 ? void 0 : _y.errors[(_z = props.field) === null || _z === void 0 ? void 0 : _z.value] }) })) : null] }), ((_0 = props.field) === null || _0 === void 0 ? void 0 : _0.helpText) && _jsx("div", { className: "fs-13 text-muted ", children: (_1 = props === null || props === void 0 ? void 0 : props.field) === null || _1 === void 0 ? void 0 : _1.helpText })] }));
};
export default SwitchComponent;
