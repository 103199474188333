var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from '../../Components/Common/Loader';
import { toastMessages } from '../../Components/Common/ToastMessages';
import { envVariables, getFormTypeAndRecordId } from '../../Components/Common/Util';
import { FormSchema } from '../../Components/constants/TenantData';
import DatePicker from '../../Components/DynamicComponents/DatePicker';
import InputComponent from '../../Components/DynamicComponents/InputComponent';
import { useFormik } from 'formik';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import * as url from '../../helpers/url_helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
var AddTenantMaster = function () {
    var _a;
    document.title = 'Add Tenant Master';
    var envConf = envVariables();
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState({
        emailId: '',
        username: '',
        phoneNumber: '',
        tenantName: '',
        startDate: '',
        endDate: '',
        ownerPhone: '',
        contractStartDate: '',
        contractEndDate: '',
        tenantConfig: {}
    }), formValues = _c[0], setFormValues = _c[1];
    var formTypeAndID = getFormTypeAndRecordId(window.location.hash);
    var formType = formTypeAndID['formType'];
    var recordId = formTypeAndID['recordID'];
    var api = new APIClient();
    var navigate = useNavigate();
    var _d = useState({}), validationObj = _d[0], setValidationObj = _d[1];
    useEffect(function () {
        if (recordId)
            getTenant();
        var validationObject = {};
        FormSchema.forEach(function (obj) {
            var _a;
            var val = '';
            if (obj.regexMessage)
                val =
                    obj.type === 'text' || obj.type === 'tel'
                        ? (_a = Yup.string().required(obj.requiredMessage)) === null || _a === void 0 ? void 0 : _a.matches(obj.regex, obj.regexMessage)
                        : Yup.date().required(obj.requiredMessage);
            else
                val =
                    obj.type === 'text' || obj.type === 'tel'
                        ? Yup.string().required(obj.requiredMessage)
                        : Yup.date().required(obj.requiredMessage);
            validationObject[obj.value] = val;
        });
        setValidationObj(validationObject);
    }, []);
    var getTenant = function () {
        api.get(url.ADMIN_TENANT + '/' + recordId, '', domains.IDM_V1)
            .then(function (resp) {
            resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status === 'success') {
                var obj = {};
                var data = resp === null || resp === void 0 ? void 0 : resp.data;
                obj.tenantName = data === null || data === void 0 ? void 0 : data.name;
                obj.username = data === null || data === void 0 ? void 0 : data.ownername;
                obj.modifiedby = data === null || data === void 0 ? void 0 : data.modifiedby;
                obj.emailId = data === null || data === void 0 ? void 0 : data.email;
                obj.phoneNumber = data === null || data === void 0 ? void 0 : data.phone;
                obj.tenantId = data === null || data === void 0 ? void 0 : data.code;
                /*
                 * obj.startDate = data?.startdate ? new Date(data?.startdate) : '';
                 * obj.endDate = data?.enddate ? new Date(data?.enddate) : '';
                 */
                obj.tenantConfig = __assign({}, data.config);
                setFormValues(obj);
            }
            setLoading(false);
        })
            .catch(function (err) { return setLoading(false); });
    };
    var dynamicFields = function (ele, ind) {
        switch (ele.inputType) {
            case 'input':
                return _jsx(InputComponent, { field: ele, mode: formType, validation: validation });
            case 'date':
                return (_jsx(DatePicker, { field: ele, pickerMinDate: ele.minDate === 'today' ? 'today' : validation.values[ele.minDate], mode: formType, validation: validation }));
        }
    };
    var validation = useFormik({
        enableReinitialize: true,
        initialValues: __assign({}, formValues),
        validationSchema: Yup.object(__assign({}, validationObj)),
        onSubmit: function (values) {
            var dataObj = !recordId
                ? {
                    ownerEmail: values === null || values === void 0 ? void 0 : values.emailId,
                    ownerUsername: values === null || values === void 0 ? void 0 : values.username,
                    tenantName: values === null || values === void 0 ? void 0 : values.tenantName,
                    ownerPhone: values === null || values === void 0 ? void 0 : values.phoneNumber
                    /*
                     *   contractStartDate: values?.startDate?.toISOString(),
                     *   contractEndDate: values?.endDate?.toISOString()
                     */
                }
                : {
                    email: values === null || values === void 0 ? void 0 : values.emailId,
                    ownername: values === null || values === void 0 ? void 0 : values.username,
                    name: values === null || values === void 0 ? void 0 : values.tenantName,
                    phone: values === null || values === void 0 ? void 0 : values.phoneNumber
                    /*
                     *   startDate: values?.startDate?.toISOString(),
                     *   endDate: values?.endDate?.toISOString()
                     */
                };
            if (!recordId)
                dataObj.tenantConfig = {};
            createNdUpdate(dataObj);
        }
    });
    var createNdUpdate = function (dataObj) {
        setLoading(true);
        var apiService;
        if (recordId)
            apiService = api.update(url.ADMIN_TENANT + '/' + recordId, dataObj, domains.IDM_V1);
        else
            apiService = api.create(url.ADMIN_TENANT, dataObj, domains.IDM_V1);
        apiService
            .then(function (resp) {
            resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status === 'success') {
                toast.success(recordId ? toastMessages.tenantUpdated : toastMessages.tenantCreated);
                navigate('/tenantmaster');
            }
            setLoading(false);
        })
            .catch(function (err) { return setLoading(false); });
    };
    return (_jsxs(React.Fragment, { children: [loading && _jsx(Loader, {}), _jsx("div", { className: "page-content h-100 ".concat(loading ? 'postion-relative mask' : ''), children: _jsxs(Container, { fluid: true, children: [_jsx(BreadCrumb, { title: formType === 'add' ? 'Add Tenant Master ' : 'Edit Tenant Master', showBack: true, backLink: "tenantmaster" }), _jsxs(Card, { children: [_jsxs(CardHeader, { children: [_jsx("div", { className: "fw-medium fs-15", children: "Tenant Information" }), _jsx("div", { className: "fs-10", children: "Enter the organizational details along with the contact information. Based on the details provided below, first user of the Tenant will be created." })] }), _jsx(CardBody, { children: _jsx(Row, { className: "px-3", children: FormSchema.map(function (ele, index) { return (_jsx(Col, { xs: 12, sm: 12, md: 8, lg: 6, xl: 6, children: dynamicFields(ele, index) }, index)); }) }) }), _jsx(CardFooter, { children: _jsxs("div", { className: "gap-2 d-flex justify-content-end", children: [_jsx(Button, { type: "button", className: "btn btn-light", onClick: function () { return navigate('/tenantmaster'); }, children: "Cancel" }), _jsx(Button, { color: "primary", onClick: validation.handleSubmit, className: "btn btn-primary", disabled: ((_a = Object.entries(validation.errors)) === null || _a === void 0 ? void 0 : _a.length) > 0, children: formType === 'edit' ? 'Update' : 'Save' })] }) })] })] }) })] }));
};
export default AddTenantMaster;
