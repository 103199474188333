import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
var BreadCrumb = function (_a) {
    var title = _a.title, pageTitle = _a.pageTitle, backLink = _a.backLink, showBack = _a.showBack, customBack = _a.customBack;
    var navigate = useNavigate();
    var handleBack = function () {
        if (customBack)
            backLink();
        else if (backLink)
            navigate('/' + backLink);
    };
    return (_jsx(React.Fragment, { children: _jsx(Row, { children: _jsx(Col, { xs: 12, children: _jsxs("div", { className: "page-title-box d-sm-flex align-items-center justify-content-between", children: [_jsxs("h4", { className: "mb-sm-0 d-flex align-items-center cursor-pointer", onClick: handleBack, children: [showBack && (_jsx("span", { className: "link-primary me-2", children: _jsx("i", { className: "ri-arrow-left-line" }) })), title] }), _jsx("div", { className: "page-title-right", children: _jsx("ol", { className: "breadcrumb m-0", children: _jsx("li", { className: "breadcrumb-item", children: _jsx(Link, { to: "/".concat(backLink), children: pageTitle }) }) }) })] }) }) }) }));
};
export default BreadCrumb;
