var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable no-var */
/* eslint-disable max-nested-callbacks */
/* eslint-disable max-depth */
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse, Container } from 'reactstrap';
import withRouter from '../../Components/Common/withRouter';
import logoSm from '../../assets/images/commons/logo-sm.png';
// i18n
import { withTranslation } from 'react-i18next';
// Import Data
import navdata from '../LayoutMenuData';
import VerticalLayout from '../VerticalLayouts';
// SimpleBar
import SimpleBar from 'simplebar-react';
var TwoColumnLayout = function (props) {
    var navData = navdata().props.children;
    var activateParentDropdown = useCallback(function (item) {
        item.classList.add('active');
        var parentCollapseDiv = item.closest('.collapse.menu-dropdown');
        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add('show');
            parentCollapseDiv.parentElement.children[0].classList.add('active');
            parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');
            if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
                parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');
                var parentParentCollapse = parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling;
                if (parentParentCollapse) {
                    parentParentCollapse.classList.add('active');
                    if (parentParentCollapse.closest('.collapse.menu-dropdown')) {
                        parentParentCollapse.closest('.collapse.menu-dropdown').classList.add('show');
                    }
                }
            }
            activateIconSidebarActive(parentCollapseDiv.getAttribute('id'));
            return false;
        }
        return false;
    }, []);
    var path = window.location.href;
    var initMenu = useCallback(function () {
        var pathName = process.env.PUBLIC_URL + path;
        var ul = document.getElementById('navbar-nav');
        var items = ul.getElementsByTagName('a');
        var itemsArray = __spreadArray([], items, true); // converts NodeList to Array
        removeActivation(itemsArray);
        var matchingMenuItem = itemsArray.find(function (x) {
            var _a;
            var routeArr = (_a = x.href) === null || _a === void 0 ? void 0 : _a.split('/').filter(function (v) { return v !== ''; });
            return pathName.includes(routeArr[3]);
        });
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
        else {
            if (process.env.PUBLIC_URL) {
                var id = pathName.replace(process.env.PUBLIC_URL, '');
                id = id.replace('/', '');
            }
            else {
                id = pathName.replace('/', '');
            }
            if (id)
                document.body.classList.add('twocolumn-panel');
            activateIconSidebarActive(id);
        }
    }, [path, activateParentDropdown]);
    useEffect(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        initMenu();
    }, [path, initMenu]);
    function activateIconSidebarActive(id) {
        // eslint-disable-next-line quotes
        var menu = document.querySelector("#two-column-menu .simplebar-content-wrapper a[sub-items='" + id + "'].nav-icon");
        if (menu !== null) {
            menu.classList.add('active');
        }
    }
    var removeActivation = function (items) {
        var activeItems = items.filter(function (x) { return x.classList.contains('active'); });
        activeItems.forEach(function (item) {
            if (item.classList.contains('menu-link')) {
                if (!item.classList.contains('active')) {
                    item.setAttribute('aria-expanded', false);
                }
                item.nextElementSibling.classList.remove('show');
            }
            if (item.classList.contains('nav-link')) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
                item.setAttribute('aria-expanded', false);
            }
            item.classList.remove('active');
        });
        var ul = document.getElementById('two-column-menu');
        var iconItems = ul.getElementsByTagName('a');
        var itemsArray = __spreadArray([], iconItems, true);
        var activeIconItems = itemsArray.filter(function (x) { return x.classList.contains('active'); });
        activeIconItems.forEach(function (item) {
            item.classList.remove('active');
            var id = item.getAttribute('sub-items');
            var getId = document.getElementById(id);
            if (getId)
                getId.classList.remove('show');
        });
    };
    // Resize sidebar
    var _a = useState('twocolumn'), isMenu = _a[0], setIsMenu = _a[1];
    var windowResizeHover = function () {
        initMenu();
        var windowSize = document.documentElement.clientWidth;
        if (windowSize < 767) {
            document.documentElement.setAttribute('data-layout', 'vertical');
            setIsMenu('vertical');
        }
        else {
            document.documentElement.setAttribute('data-layout', 'twocolumn');
            setIsMenu('twocolumn');
        }
    };
    useEffect(function setupListener() {
        if (props.layoutType === 'twocolumn') {
            window.addEventListener('resize', windowResizeHover);
            // remove classname when component will unmount
            return function cleanupListener() {
                window.removeEventListener('resize', windowResizeHover);
            };
        }
    });
    return (_jsx(React.Fragment, { children: isMenu === 'twocolumn' ? (_jsx("div", { id: "scrollbar", children: _jsxs(Container, { fluid: true, children: [_jsx("div", { id: "two-column-menu", children: _jsxs(SimpleBar, { className: "twocolumn-iconview", children: [_jsx(Link, { to: "#", className: "logo", children: _jsx("img", { src: logoSm, alt: "", height: "22" }) }), (navData || []).map(function (item, key) { return (_jsx(React.Fragment, { children: item.icon &&
                                        (item.subItems ? (_jsx("li", { children: _jsx(Link, { onClick: item.click, to: "#", "sub-items": item.id, className: "nav-icon", "data-bs-toggle": "collapse", children: _jsx("i", { className: item.icon }) }) })) : (_jsx(_Fragment, { children: _jsx(Link, { onClick: item.click, to: item.link ? item.link : '/#', "sub-items": item.id, className: "nav-icon", "data-bs-toggle": "collapse", children: _jsx("i", { className: item.icon }) }) }))) }, key)); })] }) }), _jsx(SimpleBar, { id: "navbar-nav", className: "navbar-nav", children: (navData || []).map(function (item, key) { return (_jsx(React.Fragment, { children: item.subItems ? (_jsx("li", { className: "nav-item", children: _jsx(Collapse, { className: "menu-dropdown", isOpen: item.stateVariables, id: item.id, children: _jsx("ul", { className: "nav nav-sm flex-column test", children: item.subItems &&
                                            (item.subItems || []).map(function (subItem, key) { return (_jsx(React.Fragment, { children: !subItem.isChildItem ? (_jsx("li", { className: "nav-item", children: _jsxs(Link, { to: subItem.link ? subItem.link : '/#', className: "nav-link", children: [props.t(subItem.label), subItem.badgeName ? (_jsx("span", { className: 'badge badge-pill bg-' + subItem.badgeColor, "data-key": "t-new", children: subItem.badgeName })) : null] }) })) : (_jsxs("li", { className: "nav-item", children: [_jsxs(Link, { onClick: subItem.click, className: "nav-link", to: "/#", "data-bs-toggle": "collapse", children: [' ', props.t(subItem.label), subItem.badgeName ? (_jsx("span", { className: 'badge badge-pill bg-' + subItem.badgeColor, "data-key": "t-new", children: subItem.badgeName })) : null] }), _jsx(Collapse, { className: "menu-dropdown", isOpen: subItem.stateVariables, id: item.id, children: _jsx("ul", { className: "nav nav-sm flex-column", children: subItem.childItems &&
                                                                    (subItem.childItems || []).map(function (childItem, key) { return (_jsxs("li", { className: "nav-item", children: [_jsx(Link, { to: childItem.link
                                                                                    ? childItem.link
                                                                                    : '/#', onClick: childItem.click, className: "nav-link", children: props.t(childItem.label) }), _jsx(Collapse, { className: "menu-dropdown", isOpen: childItem.stateVariables, id: item.id, children: _jsx("ul", { className: "nav nav-sm flex-column", children: childItem.isChildItem &&
                                                                                        (childItem.childItems || []).map(function (childItem, key) { return (_jsx("li", { className: "nav-item", children: _jsx(Link, { to: childItem.link
                                                                                                    ? childItem.link
                                                                                                    : '/#', className: "nav-link", children: props.t(childItem.label) }) }, key)); }) }) })] }, key)); }) }) })] })) }, key)); }) }) }) })) : null }, key)); }) })] }) })) : (_jsx(SimpleBar, { id: "scrollbar", className: "h-100", children: _jsxs(Container, { fluid: true, children: [_jsx("div", { id: "two-column-menu" }), _jsx("ul", { className: "navbar-nav", id: "navbar-nav", children: _jsx(VerticalLayout, {}) })] }) })) }));
};
TwoColumnLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};
export default withRouter(withTranslation()(TwoColumnLayout));
