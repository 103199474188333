import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, Input, Label, Row } from 'reactstrap';
var DynamicRadio = function (props) {
    var _a, _b, _c;
    return (_jsxs(Row, { children: [_jsx(Col, { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, children: _jsx(Label, { className: "form-check-label", children: props.field.label }) }), ((_a = props.field) === null || _a === void 0 ? void 0 : _a.helpText) && (_jsx(Col, { xs: 12, sm: 12, md: 12, lg: 7, xl: 7, className: "fs-13 text-muted", children: (_b = props === null || props === void 0 ? void 0 : props.field) === null || _b === void 0 ? void 0 : _b.helpText })), _jsx(Col, { xs: 12, sm: 12, md: 12, lg: 6, xl: 6, children: _jsx(Row, { children: (_c = props.field) === null || _c === void 0 ? void 0 : _c.radios.map(function (ele, index) {
                        var _a, _b, _c;
                        return (_jsxs(Col, { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, children: [_jsxs("div", { className: "form-check form-check-inline mt-2 ", children: [_jsx(Input, { className: "form-check-input", type: "radio", disabled: ele === null || ele === void 0 ? void 0 : ele.disabled, id: ele.value, name: (_a = props === null || props === void 0 ? void 0 : props.field) === null || _a === void 0 ? void 0 : _a.value, value: ele === null || ele === void 0 ? void 0 : ele.value, onBlur: props.validation.handleBlur, onChange: function (event) {
                                                var _a, _b, _c, _d;
                                                if (props === null || props === void 0 ? void 0 : props.onChange) {
                                                    props === null || props === void 0 ? void 0 : props.onChange({ target: { name: (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.name, value: (_b = event === null || event === void 0 ? void 0 : event.target) === null || _b === void 0 ? void 0 : _b.value } });
                                                }
                                                props.validation.handleChange({
                                                    target: { name: (_c = event === null || event === void 0 ? void 0 : event.target) === null || _c === void 0 ? void 0 : _c.name, value: (_d = event === null || event === void 0 ? void 0 : event.target) === null || _d === void 0 ? void 0 : _d.value }
                                                });
                                            } }), _jsx(Label, { className: "form-check-label ", children: ele.label })] }), ((_b = props.field) === null || _b === void 0 ? void 0 : _b.helpText) && _jsx("div", { className: "fs-13 text-muted px-4", children: (_c = props === null || props === void 0 ? void 0 : props.field) === null || _c === void 0 ? void 0 : _c.helpText })] }, index));
                    }) }) })] }));
};
export default DynamicRadio;
