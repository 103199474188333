import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
// import logo
import logoSm from '../assets/images/commons/logo-sm.png';
import logoDark from '../assets/images/commons/logo-dark.svg';
import logoLight from '../assets/images/commons/logo-light.png';
// Import Components
import VerticalLayout from './VerticalLayouts';
import TwoColumnLayout from './TwoColumnLayout';
import { Container } from 'reactstrap';
import HorizontalLayout from './HorizontalLayout';
var Sidebar = function (_a) {
    var layoutType = _a.layoutType;
    useEffect(function () {
        var verticalOverlay = document.getElementsByClassName('vertical-overlay');
        if (verticalOverlay) {
            verticalOverlay[0].addEventListener('click', function () {
                document.body.classList.remove('vertical-sidebar-enable');
            });
        }
    });
    var addEventListenerOnSmHoverMenu = function () {
        // add listener Sidebar Hover icon on change layout from setting
        if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover-active');
        }
        else if (document.documentElement.getAttribute('data-sidebar-size') === 'sm-hover-active') {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        }
        else {
            document.documentElement.setAttribute('data-sidebar-size', 'sm-hover');
        }
    };
    return (_jsxs(React.Fragment, { children: [_jsxs("div", { className: "app-menu navbar-menu", children: [_jsxs("div", { className: "navbar-brand-box", children: [_jsxs(Link, { to: "/", className: "logo logo-dark", children: [_jsx("span", { className: "logo-sm", children: _jsx("img", { src: logoSm, alt: "", height: "22" }) }), _jsx("span", { className: "logo-lg ", children: _jsx("img", { src: logoDark, alt: "", height: "40" }) })] }), _jsxs(Link, { to: "/", className: "logo logo-light", children: [_jsx("span", { className: "logo-sm", children: _jsx("img", { src: logoSm, alt: "", height: "22" }) }), _jsx("span", { className: "logo-lg", children: _jsx("img", { src: logoLight, alt: "", height: "17" }) })] }), _jsx("button", { onClick: addEventListenerOnSmHoverMenu, type: "button", className: "btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover", id: "vertical-hover", children: _jsx("i", { className: "ri-record-circle-line" }) })] }), layoutType === 'horizontal' ? (_jsx("div", { id: "scrollbar", children: _jsxs(Container, { fluid: true, children: [_jsx("div", { id: "two-column-menu" }), _jsx("ul", { className: "navbar-nav", id: "navbar-nav", children: _jsx(HorizontalLayout, {}) })] }) })) : layoutType === 'twocolumn' ? (_jsxs(React.Fragment, { children: [_jsx(TwoColumnLayout, { layoutType: layoutType }), _jsx("div", { className: "sidebar-background" })] })) : (_jsxs(React.Fragment, { children: [_jsx(SimpleBar, { id: "scrollbar", className: "h-100", children: _jsxs(Container, { fluid: true, children: [_jsx("div", { id: "two-column-menu" }), _jsx("ul", { className: "navbar-nav", id: "navbar-nav", children: _jsx(VerticalLayout, { layoutType: layoutType }) })] }) }), _jsx("div", { className: "sidebar-background" })] }))] }), _jsx("div", { className: "vertical-overlay" })] }));
};
export default Sidebar;
