import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
/*
 * Import Images
 * import error400cover from "../../../assets/images/error400-cover.png";
 */
import error400cover from '../../../assets/images/commons/error400-cover.png';
var Error404 = function () {
    document.title = '404 Error';
    var navigate = useNavigate();
    var backToLogin = function () {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    };
    return (_jsx(React.Fragment, { children: _jsx("div", { className: "auth-page-content", children: _jsx("div", { className: "auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100", children: _jsx("div", { className: "auth-page-content overflow-hidden p-0", children: _jsx(Container, { children: _jsx(Row, { className: "justify-content-center", children: _jsx(Col, { xl: 7, lg: 8, children: _jsxs("div", { className: "text-center", children: [_jsx("img", { src: error400cover, alt: "error img", className: "img-fluid" }), _jsxs("div", { className: "mt-3", children: [_jsx("h3", { className: "text-uppercase", children: "Sorry, Page not Found \uD83D\uDE2D" }), _jsx("p", { className: "text-muted mb-4", children: "The page you are looking for not available!" }), _jsx("button", { type: "button", onClick: backToLogin, className: "btn btn-success", children: "Back to Login" })] })] }) }) }) }) }) }) }) }));
};
export default Error404;
