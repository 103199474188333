var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable max-nested-callbacks */
/* eslint-disable max-depth */
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';
// Import Data
import navdata from '../LayoutMenuData';
// i18n
import { withTranslation } from 'react-i18next';
import withRouter from '../../Components/Common/withRouter';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
var VerticalLayout = function (props) {
    var navData = navdata().props.children;
    var path = window.location.href;
    /*
     *layout settings
     */
    var selectLayoutState = function (state) { return state.Layout; };
    var selectLayoutProperties = createSelector(selectLayoutState, function (layout) { return ({
        leftsidbarSizeType: layout.leftsidbarSizeType,
        sidebarVisibilitytype: layout.sidebarVisibilitytype,
        layoutType: layout.layoutType
    }); });
    // Inside your component
    var _a = useSelector(selectLayoutProperties), leftsidbarSizeType = _a.leftsidbarSizeType, sidebarVisibilitytype = _a.sidebarVisibilitytype, layoutType = _a.layoutType;
    // vertical and semibox resize events
    var resizeSidebarMenu = useCallback(function () {
        var windowSize = document.documentElement.clientWidth;
        var humberIcon = document.querySelector('.hamburger-icon');
        var hamburgerIcon = document.querySelector('.hamburger-icon');
        if (windowSize >= 1025) {
            if (document.documentElement.getAttribute('data-layout') === 'vertical') {
                document.documentElement.setAttribute('data-sidebar-size', leftsidbarSizeType);
            }
            if (document.documentElement.getAttribute('data-layout') === 'semibox') {
                document.documentElement.setAttribute('data-sidebar-size', leftsidbarSizeType);
            }
            if ((sidebarVisibilitytype === 'show' || layoutType === 'vertical' || layoutType === 'twocolumn') &&
                document.querySelector('.hamburger-icon')) {
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.remove('open');
                }
            }
            else {
                // var hamburgerIcon = document.querySelector(".hamburger-icon");
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.add('open');
                }
            }
        }
        else if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove('twocolumn-panel');
            if (document.documentElement.getAttribute('data-layout') === 'vertical') {
                document.documentElement.setAttribute('data-sidebar-size', 'sm');
            }
            if (document.documentElement.getAttribute('data-layout') === 'semibox') {
                document.documentElement.setAttribute('data-sidebar-size', 'sm');
            }
            if (humberIcon) {
                humberIcon.classList.add('open');
            }
        }
        else if (windowSize <= 767) {
            document.body.classList.remove('vertical-sidebar-enable');
            if (document.documentElement.getAttribute('data-layout') !== 'horizontal') {
                document.documentElement.setAttribute('data-sidebar-size', 'lg');
            }
            if (humberIcon) {
                humberIcon.classList.add('open');
            }
        }
    }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);
    useEffect(function () {
        window.addEventListener('resize', resizeSidebarMenu, true);
    }, [resizeSidebarMenu]);
    useEffect(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        var initMenu = function () {
            var pathName = process.env.PUBLIC_URL + path;
            pathName = pathName === null || pathName === void 0 ? void 0 : pathName.split('/').filter(function (v) { return v !== ''; });
            var ul = document.getElementById('navbar-nav');
            var items = ul.getElementsByTagName('a');
            var itemsArray = __spreadArray([], items, true); // converts NodeList to Array
            removeActivation(itemsArray);
            var matchingMenuItem = itemsArray.find(function (x) {
                var _a;
                var routeArr = (_a = x.href) === null || _a === void 0 ? void 0 : _a.split('/').filter(function (v) { return v !== ''; });
                return pathName.includes(routeArr[3]);
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        if (props.layoutType === 'vertical') {
            initMenu();
        }
    }, [path, props.layoutType]);
    function activateParentDropdown(item) {
        item.classList.add('active');
        var parentCollapseDiv = item.closest('.collapse.menu-dropdown');
        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add('show');
            parentCollapseDiv.parentElement.children[0].classList.add('active');
            parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');
            if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
                parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');
                if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling)
                    parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.classList.add('active');
                if (parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.closest('.collapse')) {
                    parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling.closest('.collapse').classList.add('show');
                    parentCollapseDiv.parentElement
                        .closest('.collapse')
                        .previousElementSibling.closest('.collapse')
                        .previousElementSibling.classList.add('active');
                }
            }
            return false;
        }
        return false;
    }
    var removeActivation = function (items) {
        var actiItems = items.filter(function (x) { return x.classList.contains('active'); });
        actiItems.forEach(function (item) {
            if (item.classList.contains('menu-link')) {
                if (!item.classList.contains('active')) {
                    item.setAttribute('aria-expanded', false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
            }
            if (item.classList.contains('nav-link')) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
                item.setAttribute('aria-expanded', false);
            }
            item.classList.remove('active');
        });
    };
    return (_jsx(React.Fragment, { children: (navData || []).map(function (item, key) {
            return (_jsx(React.Fragment, { children: item['isHeader'] ? (_jsx("li", { className: "menu-title", children: _jsxs("span", { "data-key": "t-menu", children: [props.t(item.label), " "] }) })) : item.subItems ? (_jsxs("li", { className: "nav-item", children: [_jsxs(Link, { onClick: item.click, className: "nav-link menu-link ", to: item.link ? item.link : '/#', "data-bs-toggle": "collapse", children: [_jsx("i", { className: item.icon }), _jsx("span", { "data-key": "t-apps", children: props.t(item.label) }), item.badgeName ? (_jsx("span", { className: 'badge badge-pill bg-' + item.badgeColor, "data-key": "t-new", children: item.badgeName })) : null] }), _jsx(Collapse, { className: "menu-dropdown", isOpen: item.stateVariables, id: "sidebarApps", children: _jsx("ul", { className: "nav nav-sm flex-column test", children: item.subItems &&
                                    (item.subItems || []).map(function (subItem, key) { return (_jsx(React.Fragment, { children: !subItem.isChildItem ? (_jsx("li", { className: "nav-item", children: _jsxs(Link, { to: subItem.link ? subItem.link : '/#', className: "nav-link", children: [props.t(subItem.label), subItem.badgeName ? (_jsx("span", { className: 'badge badge-pill bg-' + subItem.badgeColor, "data-key": "t-new", children: subItem.badgeName })) : null] }) })) : (_jsxs("li", { className: "nav-item", children: [_jsxs(Link, { onClick: subItem.click, className: "nav-link", to: "/#", "data-bs-toggle": "collapse", children: [props.t(subItem.label), subItem.badgeName ? (_jsx("span", { className: 'badge badge-pill bg-' + subItem.badgeColor, "data-key": "t-new", children: subItem.badgeName })) : null] }), _jsx(Collapse, { className: "menu-dropdown", isOpen: subItem.stateVariables, id: "sidebarEcommerce", children: _jsx("ul", { className: "nav nav-sm flex-column", children: subItem.childItems &&
                                                            (subItem.childItems || []).map(function (childItem, key) { return (_jsx(React.Fragment, { children: !childItem.childItems ? (_jsx("li", { className: "nav-item", children: _jsx(Link, { to: childItem.link ? childItem.link : '/#', className: "nav-link", children: props.t(childItem.label) }) })) : (_jsxs("li", { className: "nav-item", children: [_jsx(Link, { to: "/#", className: "nav-link", onClick: childItem.click, "data-bs-toggle": "collapse", children: props.t(childItem.label) }), _jsx(Collapse, { className: "menu-dropdown", isOpen: childItem.stateVariables, id: "sidebaremailTemplates", children: _jsx("ul", { className: "nav nav-sm flex-column", children: childItem.childItems.map(function (subChildItem, key) { return (_jsx("li", { className: "nav-item", children: _jsxs(Link, { to: subChildItem.link, className: "nav-link", "data-key": "t-basic-action", children: [props.t(subChildItem.label), ' '] }) }, key)); }) }) })] })) }, key)); }) }) })] })) }, key)); }) }) })] })) : (_jsx("li", { className: "nav-item", children: _jsxs(Link, { className: "nav-link menu-link active", to: item.link ? item.link : '/#', children: [_jsx("i", { className: item.icon }), " ", _jsx("span", { children: props.t(item.label) }), item.badgeName ? (_jsx("span", { className: 'badge badge-pill bg-' + item.badgeColor, "data-key": "t-new", children: item.badgeName })) : null] }) })) }, key));
        }) }));
};
VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};
export default withRouter(withTranslation()(VerticalLayout));
