export var toastMessages = {
    roleDelete: 'Role deleted Successfully',
    roleUpdated: 'Role Updated Successfully',
    roleCreated: 'Role created Successfully',
    userCreated: 'User created Successfully',
    userUpdated: 'User updated Successfully',
    tenantCreated: 'Tenant created Successfully.',
    tenantUpdated: 'Tenant updated Successfully.',
    invalidFileFormat: 'Incorrect File Format.',
    statusUpdated: 'Status updated successfully.',
    licenseCreated: 'License created successfully.',
    licenseUpdated: 'License updated successfully.',
    licenseDeleted: 'License deleted successfully.',
    emailCopied: 'Email copied successfully',
    userDeactivated: 'User deactivated successfully.',
    userActivated: 'User activated successfully.',
    tenantDeactivated: 'Tenant deactivated successfully.',
    tenantActivated: 'Tenant activated successfully.',
    selectCheckbox: 'Select checkbox to configure'
};
