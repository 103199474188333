export var usersSchema = [
    {
        Header: 'Name',
        accessor: 'name',
        sortable: false,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        customCell: true,
        fieldType: 'clickable',
        view: true
    },
    {
        Header: 'User Name',
        accessor: 'username',
        sortable: false,
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'Email ID',
        accessor: 'email',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        sortable: false
    },
    {
        Header: 'Role',
        accessor: 'roleName',
        sortable: false,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        sortable: false
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        sortable: false,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        customCell: true,
        fieldType: 'switch',
        fieldKey: 'status',
        width: '30px',
        maxWidth: '30px'
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        sortable: false,
        edit: true,
        width: '10px'
    }
];
export var CONSTANTS = {
    KEYSIZE: 128,
    ITERATION_COUNT: 1000,
    PASSPHRASE: 'iZVhQrsuAwzU7j2pOkywChQ1wawN996fDU8NbIRLGS3G7fLm7y'
};
