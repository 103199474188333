import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withRouter from '../Components/Common/withRouter';
// import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
// import RightSidebar from '../Components/Common/RightSidebar';
// import actions
import { changeLayout, changeSidebarTheme, changeLayoutMode, changeLayoutWidth, changeLayoutPosition, changeTopbarTheme, changeLeftsidebarSizeType, changeLeftsidebarViewType, changeSidebarImageType, changeSidebarVisibility } from '../slices/thunks';
// redux
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
var Layout = function (props) {
    var _a = useState(''), headerClass = _a[0], setHeaderClass = _a[1];
    var dispatch = useDispatch();
    var selectLayoutState = function (state) { return state.Layout; };
    var selectLayoutProperties = createSelector(selectLayoutState, function (layout) { return ({
        layoutType: layout.layoutType,
        leftSidebarType: layout.leftSidebarType,
        layoutModeType: layout.layoutModeType,
        layoutWidthType: layout.layoutWidthType,
        layoutPositionType: layout.layoutPositionType,
        topbarThemeType: layout.topbarThemeType,
        leftsidbarSizeType: layout.leftsidbarSizeType,
        leftSidebarViewType: layout.leftSidebarViewType,
        leftSidebarImageType: layout.leftSidebarImageType,
        preloader: layout.preloader,
        sidebarVisibilitytype: layout.sidebarVisibilitytype
    }); });
    // Inside your component
    var _b = useSelector(selectLayoutProperties), layoutType = _b.layoutType, leftSidebarType = _b.leftSidebarType, layoutModeType = _b.layoutModeType, layoutWidthType = _b.layoutWidthType, layoutPositionType = _b.layoutPositionType, topbarThemeType = _b.topbarThemeType, leftsidbarSizeType = _b.leftsidbarSizeType, leftSidebarViewType = _b.leftSidebarViewType, leftSidebarImageType = _b.leftSidebarImageType, sidebarVisibilitytype = _b.sidebarVisibilitytype;
    /*
     *layout settings
     */
    useEffect(function () {
        if (layoutType ||
            leftSidebarType ||
            layoutModeType ||
            layoutWidthType ||
            layoutPositionType ||
            topbarThemeType ||
            leftsidbarSizeType ||
            leftSidebarViewType ||
            leftSidebarImageType ||
            sidebarVisibilitytype) {
            window.dispatchEvent(new Event('resize'));
            dispatch(changeLeftsidebarViewType(leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
            dispatch(changeSidebarTheme(leftSidebarType));
            dispatch(changeLayoutMode(layoutModeType));
            dispatch(changeLayoutWidth(layoutWidthType));
            dispatch(changeLayoutPosition(layoutPositionType));
            dispatch(changeTopbarTheme(topbarThemeType));
            dispatch(changeLayout(layoutType));
            dispatch(changeSidebarImageType(leftSidebarImageType));
            dispatch(changeSidebarVisibility(sidebarVisibilitytype));
        }
    }, [
        layoutType,
        leftSidebarType,
        layoutModeType,
        layoutWidthType,
        layoutPositionType,
        topbarThemeType,
        leftsidbarSizeType,
        leftSidebarViewType,
        leftSidebarImageType,
        sidebarVisibilitytype,
        dispatch
    ]);
    /*
     *call dark/light mode
     */
    var onChangeLayoutMode = function (value) {
        if (changeLayoutMode) {
            dispatch(changeLayoutMode(value));
        }
    };
    // class add remove in header
    useEffect(function () {
        window.addEventListener('scroll', scrollNavigation, true);
    });
    function scrollNavigation() {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass('topbar-shadow');
        }
        else {
            setHeaderClass('');
        }
    }
    useEffect(function () {
        var humberIcon = document.querySelector('.hamburger-icon');
        if (sidebarVisibilitytype === 'show' || layoutType === 'vertical' || layoutType === 'twocolumn') {
            humberIcon.classList.remove('open');
        }
        else {
            humberIcon && humberIcon.classList.add('open');
        }
    }, [sidebarVisibilitytype, layoutType]);
    return (_jsx(React.Fragment, { children: _jsxs("div", { id: "layout-wrapper", children: [_jsx(Header, { headerClass: headerClass, layoutModeType: layoutModeType, onChangeLayoutMode: onChangeLayoutMode }), _jsx(Sidebar, { layoutType: layoutType }), _jsxs("div", { className: "main-content", children: [props.children, _jsx(Footer, {})] })] }) }));
};
Layout.propTypes = {
    children: PropTypes.object
};
export default withRouter(Layout);
