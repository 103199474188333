var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { changeHTMLAttribute } from './utils';
import { changeLayoutAction, changeLayoutModeAction, changeSidebarThemeAction, changeLayoutWidthAction, changeLayoutPositionAction, changeTopbarThemeAction, changeLeftsidebarSizeTypeAction, changeLeftsidebarViewTypeAction, changeSidebarImageTypeAction, changePreLoaderAction, changeSidebarVisibilityAction } from './reducer';
/**
 * Changes the layout type
 * @param {*} param0
 */
export var changeLayout = function (layout) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            if (layout === 'twocolumn') {
                document.documentElement.removeAttribute('data-layout-width');
            }
            else if (layout === 'horizontal') {
                document.documentElement.removeAttribute('data-sidebar-size');
            }
            else if (layout === 'semibox') {
                changeHTMLAttribute('data-layout-width', 'fluid');
                changeHTMLAttribute('data-layout-style', 'default');
            }
            changeHTMLAttribute('data-layout', layout);
            dispatch(changeLayoutAction(layout));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the layout mode
 * @param {*} param0
 */
export var changeLayoutMode = function (layoutMode) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-bs-theme', layoutMode);
            dispatch(changeLayoutModeAction(layoutMode));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the left sidebar theme
 * @param {*} param0
 */
export var changeSidebarTheme = function (theme) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-sidebar', theme);
            dispatch(changeSidebarThemeAction(theme));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the layout width
 * @param {*} param0
 */
export var changeLayoutWidth = function (layoutWidth) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            if (layoutWidth === 'lg') {
                changeHTMLAttribute('data-layout-width', 'fluid');
            }
            else {
                changeHTMLAttribute('data-layout-width', 'boxed');
            }
            dispatch(changeLayoutWidthAction(layoutWidth));
        }
        catch (error) {
            return [2 /*return*/, error];
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the layout position
 * @param {*} param0
 */
export var changeLayoutPosition = function (layoutposition) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-layout-position', layoutposition);
            dispatch(changeLayoutPositionAction(layoutposition));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the topbar themes
 * @param {*} param0
 */
export var changeTopbarTheme = function (topbarTheme) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-topbar', topbarTheme);
            dispatch(changeTopbarThemeAction(topbarTheme));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the topbar themes
 * @param {*} param0
 */
export var changeSidebarImageType = function (leftsidebarImagetype) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-sidebar-image', leftsidebarImagetype);
            dispatch(changeSidebarImageTypeAction(leftsidebarImagetype));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the Preloader
 * @param {*} param0
 */
export var changePreLoader = function (preloaderTypes) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-preloader', preloaderTypes);
            dispatch(changePreLoaderAction(preloaderTypes));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the topbar themes
 * @param {*} param0
 */
export var changeLeftsidebarSizeType = function (leftsidebarSizetype) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            switch (leftsidebarSizetype) {
                case 'lg':
                    changeHTMLAttribute('data-sidebar-size', 'lg');
                    break;
                case 'md':
                    changeHTMLAttribute('data-sidebar-size', 'md');
                    break;
                case 'sm':
                    changeHTMLAttribute('data-sidebar-size', 'sm');
                    break;
                case 'sm-hover':
                    changeHTMLAttribute('data-sidebar-size', 'sm-hover');
                    break;
                default:
                    changeHTMLAttribute('data-sidebar-size', 'lg');
            }
            dispatch(changeLeftsidebarSizeTypeAction(leftsidebarSizetype));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the topbar themes
 * @param {*} param0
 */
export var changeLeftsidebarViewType = function (leftsidebarViewtype) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-layout-style', leftsidebarViewtype);
            dispatch(changeLeftsidebarViewTypeAction(leftsidebarViewtype));
        }
        catch (error) {
            // console.log(error);
        }
        return [2 /*return*/];
    });
}); }; };
/**
 * Changes the sidebar visibility
 * @param {*} param0
 */
export var changeSidebarVisibility = function (sidebarVisibilitytype) { return function (dispatch) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        try {
            changeHTMLAttribute('data-sidebar-visibility', sidebarVisibilitytype);
            dispatch(changeSidebarVisibilityAction(sidebarVisibilitytype));
        }
        catch (error) {
            //
        }
        return [2 /*return*/];
    });
}); }; };
