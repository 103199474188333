var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import AuthSlider from './authCarousel';
import { APIClient, setAuthorization } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import * as domains from '../../helpers/domain_helper';
import * as Yup from 'yup';
import { loginUser } from '../../slices/thunks';
import { togglePassword, togglePasswordChange, togglePasswordChangeVal, togglePasswordStrengthIn, togglePasswordStrengthOut } from '../../Components/Hooks/UserHooks';
import { toast } from 'react-toastify';
import { envVariables } from '../../Components/Common/Util';
var TwosVerify = function () {
    var _a, _b, _c, _d, _e, _f;
    document.title = 'Two Step Verification ';
    var envConf = envVariables();
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var api = new APIClient();
    var authData = JSON.parse(localStorage.getItem('authObj')) || null;
    var _g = useState(false), loading = _g[0], setLoading = _g[1];
    var _h = useState(''), routeParam = _h[0], setRouteParam = _h[1];
    var _j = useState(authData), authObj = _j[0], setAuthObj = _j[1];
    var _k = useState(false), sending = _k[0], setSending = _k[1];
    useEffect(function () {
        if (!authData)
            navigate('/login');
        setAuthObj(authData);
        var locationArr = window.location.hash.split('/');
        var queryString = locationArr[locationArr.length - 1];
        if (queryString === 'login-otp-verify')
            setRouteParam('login');
        if (queryString === 'forgot-otp-verify')
            setRouteParam('resetpassword');
    }, []);
    var moveToNext = function (e, t, field) {
        var _a;
        var _b, _c, _d, _e;
        if (e.which === 8 || e.which === 46)
            validation.setValues(__assign(__assign({}, validation.values), (_a = {}, _a[field.value] = '', _a)));
        if (e.which !== 8 && e.which !== 46)
            0 < ((_c = (_b = e === null || e === void 0 ? void 0 : e.target) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.length) && ((_d = document.getElementById('digit' + t + '-input')) === null || _d === void 0 ? void 0 : _d.focus());
        else
            (_e = document.getElementById("digit".concat(t - 2, "-input"))) === null || _e === void 0 ? void 0 : _e.focus();
    };
    var validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            digitOne: '',
            digitTwo: '',
            digitThree: '',
            digitFour: '',
            digitFive: '',
            digitSix: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: routeParam === 'resetpassword'
            ? Yup.object({
                newPassword: Yup.string().matches(/^\S*$/, 'Spaces Are Not Allowed In Password').required('Please Enter New Password')
            })
            : null,
        onSubmit: function (values) {
            setLoading(true);
            var params = {
                otp: "".concat(values.digitOne).concat(values.digitTwo).concat(values.digitThree).concat(values.digitFour).concat(values.digitFive).concat(values.digitSix),
                username: authObj === null || authObj === void 0 ? void 0 : authObj.username
            };
            if (routeParam === 'resetpassword') {
                params.password = values.newPassword;
                api.create(url.RESET_PASSWORD, params, domains.IDM_V1)
                    .then(function (resp) {
                    resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success')
                        navigate('/successmsg');
                    setLoading(false);
                })
                    .catch(function (err) { return setLoading(false); });
            }
            else {
                params.device = '';
                params.app = 'portal';
                api.create(url.VALIDATE_OTP, params, domains.IDM_V1)
                    .then(function (resp) {
                    var _a, _b;
                    resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (((_a = resp.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'success') {
                        setAuthorization((_b = resp === null || resp === void 0 ? void 0 : resp.data) === null || _b === void 0 ? void 0 : _b.token);
                        dispatch(loginUser(resp, navigate));
                        localStorage.removeItem('authObj');
                        setLoading(false);
                    }
                })
                    .catch(function (err) {
                    setLoading(false);
                });
            }
        }
    });
    var dynamicServiceCall = function (URL, params) {
        api.create(URL, params)
            .then(function (resp) {
            var _a;
            resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (((_a = resp.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'success') {
                toast.success('OTP sent to your registered email address.');
            }
            setLoading(false);
            setSending(false);
        })
            .catch(function (err) {
            setLoading(false);
            setSending(false);
        });
    };
    var handleClickResend = function () {
        var _a;
        validation.setValues(__assign(__assign({}, validation.values), { digitOne: '', digitTwo: '', digitThree: '', digitFour: '', digitFive: '', digitSix: '', newPassword: '', confirmPassword: '' }));
        setSending(true);
        (_a = document.getElementById('digit1-input')) === null || _a === void 0 ? void 0 : _a.focus();
        if (routeParam === 'login') {
            var params = { username: authObj.username, password: authObj.password, tenant: 'TT' };
            dynamicServiceCall(url.LOGIN, params);
        }
        else {
            var params = { username: authObj.username, tenant: 'TT' };
            dynamicServiceCall(url.FORGOT_PASSWORD, params);
        }
    };
    var clearOTP = function () {
        var _a;
        validation.setValues(__assign(__assign({}, validation.values), { digitOne: '', digitTwo: '', digitThree: '', digitFour: '', digitFive: '', digitSix: '' }));
        (_a = document.getElementById('digit1-input')) === null || _a === void 0 ? void 0 : _a.focus();
    };
    var handleConfirmPassword = function (e) {
        validation.setValues(__assign(__assign({}, validation.values), { confirmPassword: e.target.value }));
        validation.setTouched(__assign(__assign({}, validation.touches), { confirmPassword: true }));
        validation.setErrors(__assign(__assign({}, validation.errors), { confirmPassword: e.target.value === ''
                ? 'Please Enter Confirm Password'
                : e.target.value !== validation.values.newPassword
                    ? 'Password did not match'
                    : '' }));
    };
    var handleOtpChange = function (e, field) {
        var pattern = new RegExp(/^[0-9\b]+$/);
        var result = pattern.test(e.target.value);
        return result;
    };
    var fieldsArray = [
        { id: 'digit1-input', value: 'digitOne', index: 0 },
        { id: 'digit2-input', value: 'digitTwo', index: 1 },
        { id: 'digit3-input', value: 'digitThree', index: 2 },
        { id: 'digit4-input', value: 'digitFour', index: 3 },
        { id: 'digit5-input', value: 'digitFive', index: 4 },
        { id: 'digit6-input', value: 'digitSix', index: 5 }
    ];
    var handlePaste = function (e) {
        var _a, _b;
        var value = (_a = JSON.stringify(e.clipboardData.getData('text/plain'))) === null || _a === void 0 ? void 0 : _a.split('');
        var values = value === null || value === void 0 ? void 0 : value.slice(1, value.length - 1);
        validation.setValues(__assign(__assign({}, validation.values), { digitOne: values[0] ? values[0] : '', digitTwo: values[1] ? values[1] : '', digitThree: values[2] ? values[2] : '', digitFour: values[3] ? values[3] : '', digitFive: values[4] ? values[4] : '', digitSix: values[5] ? values[5] : '' }));
        (_b = document.getElementById("digit".concat(values.length, "-input"))) === null || _b === void 0 ? void 0 : _b.focus();
    };
    var handleKeyDown = function (e) {
        //
    };
    return (_jsx(React.Fragment, { children: _jsx("div", { className: "d-flex justify-content-center align-items-center overflow-y-auto", children: _jsx(Col, { lg: 12, children: _jsx(Card, { className: "overflow-x-hidden m-0", children: _jsxs(Row, { className: "g-0 vh-100 vw-100", children: [_jsx(AuthSlider, {}), _jsx(Col, { lg: 4, className: "d-flex justify-content-center align-items-center", children: _jsx("div", { className: "w-100 p-lg-12 p-6", children: _jsxs("div", { className: "p-lg-4 p-4", children: [_jsx("div", { className: "mb-4", children: _jsx("div", { className: "avatar-lg mx-auto", children: _jsx("div", { className: "avatar-title bg-light text-primary display-5 rounded-circle", children: _jsx("i", { className: "ri-mail-line" }) }) }) }), _jsxs("div", { className: "text-muted text-center mt-3 mx-lg-3", children: [_jsx("h4", { className: "", children: "Verify Your Email" }), _jsx("p", { children: "Please enter the 6 digit OTP sent to your Email" })] }), _jsx("div", { className: "mt-4 px-4", children: _jsxs(Form, { autoComplete: "off", onSubmit: function (e) {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }, children: [_jsx("div", { className: "d-flex justify-content-center flex-row verifyOTP mb-1 text-center", children: fieldsArray === null || fieldsArray === void 0 ? void 0 : fieldsArray.map(function (field, index) {
                                                                return (_jsxs("div", { className: "d-flex flex-column", children: [_jsx(Input, { onKeyUp: function (e) { return moveToNext(e, index + 2, field); }, type: "text", maxLength: 1, className: 'form-control mx-2 text-center', id: field.id, autoFocus: index === 0, name: field.value, onKeyDown: function (e) { return handleKeyDown(e); }, onPaste: handlePaste, autoComplete: "off", onChange: function (e) {
                                                                                if (handleOtpChange(e, field))
                                                                                    validation.handleChange(e);
                                                                            }, value: validation.values[field.value] || '' }, index), index === 5 && (_jsx("div", { className: "d-flex align-items-center mt-3 cursor-pointer", onClick: clearOTP, children: _jsx("span", { className: "text-decoration-underline text-primary ps-2", children: "Clear" }) }))] }, index));
                                                            }) }), routeParam === 'resetpassword' && (_jsxs(_Fragment, { children: [_jsxs("div", { className: "text-center pt-2", children: [_jsx("h5", { className: "text-primary", children: "Create new password" }), _jsx("p", { className: 'text-muted', children: "Your new password must be different from previous used password." })] }), _jsxs("div", { className: "mb-4", children: [_jsx(Label, { className: "form-label", children: "New Password" }), _jsxs("div", { className: "position-relative auth-pass-inputgroup", children: [_jsx(Input, { onFocus: function () { return togglePasswordStrengthIn('password-contain'); }, togglePasswordStrengthIn: true, onBlur: function (e) {
                                                                                        validation.handleBlur(e);
                                                                                        togglePasswordChangeVal(validation.values.newPassword) &&
                                                                                            togglePasswordStrengthOut('password-contain');
                                                                                    }, name: "newPassword", type: "password", autoComplete: "new-password", className: 'form-control pe-5 password-input', onPaste: function () { return false; }, placeholder: "Enter new password", id: "password-input", "aria-describedby": "passwordInput", required: true, onChange: function (e) {
                                                                                        validation.handleChange(e);
                                                                                        togglePasswordChange(e.target.value);
                                                                                    }, value: validation.values.newPassword || '', invalid: validation.touched.newPassword && validation.errors.newPassword
                                                                                        ? true
                                                                                        : false }), validation.touched.newPassword && validation.errors.newPassword ? (_jsx(FormFeedback, { type: "invalid", children: _jsx("div", { children: validation.errors.newPassword }) })) : null, validation.values.newPassword &&
                                                                                    validation.values.newPassword !== '' &&
                                                                                    !validation.errors.newPassword && (_jsx(Button, { color: "link", className: "position-absolute end-0 top-0 text-decoration-none cursor-auto text-muted password-addon", type: "button", children: _jsx("i", { id: "password-icon1", onClick: function () {
                                                                                            return togglePassword('password-input', 'password-icon1');
                                                                                        }, className: "ri-eye-off-line align-middle cursor-pointer" }) }))] }), _jsx("div", { id: "passwordInput", className: 'form-text', children: "Must be at least 8 characters." })] }), _jsxs("div", { id: "password-contain", className: "p-3 bg-light mb-2 rounded", children: [_jsx("h5", { className: "fs-13", children: "Password must contain:" }), _jsxs("p", { id: "pass-length", className: "invalid fs-12 mb-2", children: ["Minimum ", _jsx("b", { children: "8 characters" })] }), _jsxs("p", { id: "pass-max-length", className: "invalid fs-12 mb-2", children: ["Maximum ", _jsx("b", { children: "12 characters" })] }), _jsxs("p", { id: "pass-lower", className: "invalid fs-12 mb-2", children: ["At least 1 ", _jsx("b", { children: "lowercase" }), " letter (a-z)"] }), _jsxs("p", { id: "pass-upper", className: "invalid fs-12 mb-2", children: ["At least 1 ", _jsx("b", { children: "uppercase" }), " letter (A-Z)"] }), _jsxs("p", { id: "pass-number", className: "invalid fs-12 mb-2", children: ["At least 1 ", _jsx("b", { children: "number" }), " (0-9)"] }), _jsxs("p", { id: "pass-special", className: "invalid fs-12 mb-0", children: ["At least 1 ", _jsx("b", { children: "Special" }), " Symbol"] })] }), _jsxs("div", { className: "mb-4", children: [_jsx(Label, { className: "form-label", children: "Confirm Password" }), _jsxs("div", { className: "position-relative auth-pass-inputgroup mb-4", children: [_jsx(Input, { disabled: !togglePasswordChangeVal(validation.values.newPassword), type: "password", className: 'form-control pe-5 password-input', onPaste: function () { return false; }, placeholder: "Confirm password", id: "password-input1", required: true, name: "confirmPassword", onChange: function (e) {
                                                                                        handleConfirmPassword(e);
                                                                                    }, value: validation.values.confirmPassword || '', invalid: validation.touched.confirmPassword &&
                                                                                        (validation.errors.confirmPassword ||
                                                                                            validation.values.confirmPassword !==
                                                                                                validation.values.newPassword)
                                                                                        ? true
                                                                                        : false }), validation.touched.confirmPassword ? (_jsx(FormFeedback, { type: "invalid", children: _jsx("div", { children: validation.errors.confirmPassword
                                                                                            ? validation.errors.confirmPassword
                                                                                            : validation.values.confirmPassword !==
                                                                                                validation.values.newPassword
                                                                                                ? 'Passwords did not match.'
                                                                                                : '' }) })) : null, validation.values.confirmPassword &&
                                                                                    validation.values.confirmPassword !== '' &&
                                                                                    !validation.errors.confirmPassword && (_jsx(Button, { color: "link", className: "position-absolute top-0 text-decoration-none cursor-auto text-muted password-addon ".concat(validation.values.confirmPassword !==
                                                                                        validation.values.newPassword
                                                                                        ? 'margin-end-20 end-0'
                                                                                        : 'end-0'), type: "button", children: _jsx("i", { id: "password-icon2", onClick: function () {
                                                                                            return togglePassword('password-input1', 'password-icon2');
                                                                                        }, className: "ri-eye-off-line align-middle cursor-pointer" }) }))] })] })] })), _jsx("div", { className: "mt-3", children: _jsx(Button, { color: "success", type: "submit", className: "w-100", disabled: loading ||
                                                                    (routeParam === 'resetpassword' &&
                                                                        (validation.values.confirmPassword === '' ||
                                                                            validation.errors.newPassword ||
                                                                            validation.values.confirmPassword !== validation.values.newPassword)) ||
                                                                    ((_a = validation.values) === null || _a === void 0 ? void 0 : _a.digitOne) === '' ||
                                                                    ((_b = validation.values) === null || _b === void 0 ? void 0 : _b.digitTwo) === '' ||
                                                                    ((_c = validation.values) === null || _c === void 0 ? void 0 : _c.digitThree) === '' ||
                                                                    ((_d = validation.values) === null || _d === void 0 ? void 0 : _d.digitFour) === '' ||
                                                                    ((_e = validation.values) === null || _e === void 0 ? void 0 : _e.digitFive) === '' ||
                                                                    ((_f = validation.values) === null || _f === void 0 ? void 0 : _f.digitSix) === '', children: _jsxs("span", { className: "d-flex align-items-center justify-content-center", children: ["Confirm", loading && (_jsx("span", { className: "ms-2 spinner-border", role: "status", children: _jsx("span", { className: "visually-hidden", children: "Loading..." }) }))] }) }) })] }) }), _jsx("div", { className: "mt-3 text-center", children: _jsxs("div", { className: "mb-0 d-flex align-items-center justify-content-center", children: ["Didn't receive OTP", _jsx("div", { className: "fw-bold ".concat(sending ? 'text-muted' : 'text-decoration-underline cursor-pointer text-primary', " ps-1"), onClick: sending ? undefined : handleClickResend, children: "Resend" })] }) }), _jsx("div", { className: "mt-3 text-center", children: _jsx("div", { className: "mb-0 d-flex align-items-center justify-content-center", children: _jsx(Link, { to: '/login', className: "fw-semibold text-primary text-decoration-underline", children: "Not my User Name." }) }) })] }) }) })] }) }) }) }) }));
};
export default TwosVerify;
