import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Col, FormFeedback, Input, Label } from 'reactstrap';
var InputComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    return (_jsx(Col, { xs: ((_a = props.field) === null || _a === void 0 ? void 0 : _a.xs) || 12, sm: ((_b = props.field) === null || _b === void 0 ? void 0 : _b.sm) || 12, md: ((_c = props.field) === null || _c === void 0 ? void 0 : _c.md) || 8, lg: ((_d = props.field) === null || _d === void 0 ? void 0 : _d.lg) || 6, xl: ((_e = props.field) === null || _e === void 0 ? void 0 : _e.xl) || 6, children: _jsxs("div", { className: "mt-2 ".concat(props.mainCss), children: [((_f = props.field) === null || _f === void 0 ? void 0 : _f.label) && (_jsxs(Label, { htmlFor: (_g = props.field) === null || _g === void 0 ? void 0 : _g.value, className: "form-label m-0 ".concat(props.labelCss && props.labelCss), children: [props.field.label, props.mode !== 'view' && props.field.mandatory ? (_jsx("span", { className: "text-danger", children: " *" })) : (_jsx("span", { className: "ps-1", children: " :" })), _jsx("i", { className: "".concat(props.field.labelIcon, " text-primary fs-4") })] })), ((_h = props.field) === null || _h === void 0 ? void 0 : _h.helpText) && _jsx("div", { className: "fs-13 text-muted", children: (_j = props === null || props === void 0 ? void 0 : props.field) === null || _j === void 0 ? void 0 : _j.helpText }), _jsx(Input, { className: "mt-2", name: (_k = props.field) === null || _k === void 0 ? void 0 : _k.value, type: (_l = props.field) === null || _l === void 0 ? void 0 : _l.type, placeholder: "".concat('Enter', " ").concat(props.field.label ? props.field.label : (_m = props.field) === null || _m === void 0 ? void 0 : _m.placeHolder), disabled: props.mode === 'view'
                        ? true
                        : props.field.disableWhileEdit
                            ? props.mode === 'edit'
                                ? props.field.disableWhileEdit
                                : false
                            : (_o = props.field) === null || _o === void 0 ? void 0 : _o.disabled, onChange: props.validation.handleChange, maxLength: (_p = props.field) === null || _p === void 0 ? void 0 : _p.maxLen, rows: ((_q = props.field) === null || _q === void 0 ? void 0 : _q.rows) ? (_r = props.field) === null || _r === void 0 ? void 0 : _r.rows : 3, onBlur: props.validation.handleBlur, value: props.validation.values[(_s = props.field) === null || _s === void 0 ? void 0 : _s.value] || '', invalid: props.validation.touched[(_t = props.field) === null || _t === void 0 ? void 0 : _t.value] && props.validation.errors[(_u = props.field) === null || _u === void 0 ? void 0 : _u.value] ? true : false }), props.validation.touched[(_v = props.field) === null || _v === void 0 ? void 0 : _v.value] && props.validation.errors[(_w = props.field) === null || _w === void 0 ? void 0 : _w.value] ? (_jsx(FormFeedback, { type: "invalid", children: _jsx("div", { children: props.validation.errors[(_x = props.field) === null || _x === void 0 ? void 0 : _x.value] }) })) : null] }) }));
};
export default InputComponent;
