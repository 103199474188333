var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import InputComponent from './InputComponent';
import DynamicRadio from './DynamicRadio';
import SwitchComponent from './SwitchComponent';
import SelectComponent from './SelectComponent';
import DatePicker from './DatePicker';
import ValueComponent from './ValueComponent';
var DynamicFunction = function (_a) {
    var field = _a.field, validation = _a.validation, mode = _a.mode, index = _a.index;
    var inputRef = useRef(null);
    useEffect(function () {
        var _a;
        if (document.activeElement === inputRef.current) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    });
    var commonProps = {
        field: field,
        validation: validation,
        mode: mode,
        ref: inputRef
    };
    switch (field === null || field === void 0 ? void 0 : field.inputType) {
        case 'input':
            return _jsx(InputComponent, __assign({}, commonProps));
        case 'radio':
            return _jsx(DynamicRadio, __assign({}, commonProps));
        case 'switch':
            return _jsx(SwitchComponent, __assign({}, commonProps));
        case 'select':
            return _jsx(SelectComponent, __assign({}, commonProps));
        case 'date':
            return _jsx(DatePicker, __assign({}, commonProps));
        case 'value':
            return _jsx(ValueComponent, __assign({}, commonProps));
        default:
            return null;
    }
};
export default DynamicFunction;
