export var regex = {
    alphabets: '^[a-zA-Z ]*$',
    email: '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$',
    phone: '^[0-9]{10}$',
    floatdecimal: '^[0-9]+(.[0-9]+)?$',
    alphaNumeric: '^[a-zA-Z 0-9]*$',
    alphanumericSpecialChars: '^[ A-Za-z0-9\n_@,<>?":|[();~{}`!=$%^*()./#&+-]*$',
    alphabetsSpecialChars: '^[ A-Za-z_@,~{}!=<>?":|[$%;`^*()./#&+-]*$',
    numbers: '^[0-9]*$',
    pincode: '^[0-9]{6}$',
    primary_email: '^[a-zA-Z0-9._-]+@radiogram.com$',
    notPrimary_email: '^(?!.*@radiogram.com$)[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$'
};
