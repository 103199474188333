var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-shadow */
/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Col, Collapse, Row } from 'reactstrap';
import withRouter from '../../Components/Common/withRouter';
// Import Data
import navdata from '../LayoutMenuData';
// i18n
import { withTranslation } from 'react-i18next';
var HorizontalLayout = function (props) {
    var _a = useState(false), isMoreMenu = _a[0], setIsMoreMenu = _a[1];
    var navData = navdata().props.children;
    var menuItems = [];
    var splitMenuItems = [];
    var menuSplitContainer = 6;
    navData.forEach(function (value, key) {
        if (value['isHeader']) {
            menuSplitContainer++;
        }
        if (key >= menuSplitContainer) {
            var val = value;
            val.childItems = value.subItems;
            val.isChildItem = value.subItems ? true : false;
            delete val.subItems;
            splitMenuItems.push(val);
        }
        else {
            menuItems.push(value);
        }
    });
    menuItems.push({
        id: 'more',
        label: 'More',
        icon: 'ri-briefcase-2-line',
        link: '/#',
        stateVariables: isMoreMenu,
        subItems: splitMenuItems,
        click: function (e) {
            e.preventDefault();
            setIsMoreMenu(!isMoreMenu);
        }
    });
    var path = window.location.href;
    useEffect(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        var initMenu = function () {
            var pathName = process.env.PUBLIC_URL + path;
            var ul = document.getElementById('navbar-nav');
            var items = ul.getElementsByTagName('a');
            var itemsArray = __spreadArray([], items, true); // converts NodeList to Array
            removeActivation(itemsArray);
            var matchingMenuItem = itemsArray.find(function (x) {
                var _a;
                var routeArr = (_a = x.href) === null || _a === void 0 ? void 0 : _a.split('/').filter(function (v) { return v !== ''; });
                return pathName.includes(routeArr[3]);
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        initMenu();
    }, [path, props.layoutType]);
    function activateParentDropdown(item) {
        item.classList.add('active');
        var parentCollapseDiv = item.closest('.collapse.menu-dropdown');
        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add('show');
            parentCollapseDiv.parentElement.children[0].classList.add('active');
            parentCollapseDiv.parentElement.children[0].setAttribute('aria-expanded', 'true');
            if (parentCollapseDiv.parentElement.closest('.collapse.menu-dropdown')) {
                parentCollapseDiv.parentElement.closest('.collapse').classList.add('show');
                var parentElementDiv = parentCollapseDiv.parentElement.closest('.collapse').previousElementSibling;
                if (parentElementDiv)
                    if (parentElementDiv.closest('.collapse'))
                        parentElementDiv.closest('.collapse').classList.add('show');
                parentElementDiv.classList.add('active');
                var parentElementSibling = parentElementDiv.parentElement.parentElement.parentElement.previousElementSibling;
                if (parentElementSibling) {
                    parentElementSibling.classList.add('active');
                }
            }
            return false;
        }
        return false;
    }
    var removeActivation = function (items) {
        var actiItems = items.filter(function (x) { return x.classList.contains('active'); });
        actiItems.forEach(function (item) {
            if (item.classList.contains('menu-link')) {
                if (!item.classList.contains('active')) {
                    item.setAttribute('aria-expanded', false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
            }
            if (item.classList.contains('nav-link')) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove('show');
                }
                item.setAttribute('aria-expanded', false);
            }
            item.classList.remove('active');
        });
    };
    return (_jsx(React.Fragment, { children: (menuItems || []).map(function (item, key) {
            return (_jsx(React.Fragment, { children: !item['isHeader'] ? (item.subItems ? (_jsxs("li", { className: "nav-item", children: [_jsxs(Link, { onClick: item.click, className: "nav-link menu-link", to: item.link ? item.link : '/#', "data-bs-toggle": "collapse", children: [_jsx("i", { className: item.icon }), " ", _jsx("span", { "data-key": "t-apps", children: props.t(item.label) })] }), _jsx(Collapse, { className: item.id === 'baseUi' && item.subItems.length > 13
                                ? 'menu-dropdown mega-dropdown-menu'
                                : 'menu-dropdown', isOpen: item.stateVariables, id: "sidebarApps", children: item.id === 'baseUi' && item.subItems.length > 13 ? (_jsx(React.Fragment, { children: _jsx(Row, { children: item.subItems &&
                                        (item.subItems || []).map(function (subItem, key) { return (_jsx(React.Fragment, { children: key % 2 === 0 ? (_jsx(Col, { lg: 4, children: _jsx("ul", { className: "nav nav-sm flex-column", children: _jsx("li", { className: "nav-item", children: _jsx(Link, { to: item.subItems[key].link, className: "nav-link", children: item.subItems[key].label }) }) }) })) : (_jsx(Col, { lg: 4, children: _jsx("ul", { className: "nav nav-sm flex-column", children: _jsx("li", { className: "nav-item", children: _jsx(Link, { to: item.subItems[key].link, className: "nav-link", children: item.subItems[key].label }) }) }) })) }, key)); }) }) })) : (_jsx("ul", { className: "nav nav-sm flex-column test", children: item.subItems &&
                                    (item.subItems || []).map(function (subItem, key) { return (_jsx(React.Fragment, { children: !subItem.isChildItem ? (_jsx("li", { className: "nav-item", children: _jsx(Link, { to: subItem.link ? subItem.link : '/#', className: "nav-link", children: props.t(subItem.label) }) })) : (_jsxs("li", { className: "nav-item", children: [_jsxs(Link, { onClick: subItem.click, className: "nav-link", to: "/#", "data-bs-toggle": "collapse", children: [' ', props.t(subItem.label)] }), _jsx(Collapse, { className: "menu-dropdown", isOpen: subItem.stateVariables, id: "sidebarEcommerce", children: _jsx("ul", { className: "nav nav-sm flex-column", children: subItem.childItems &&
                                                            (subItem.childItems || []).map(function (subChildItem, key) { return (_jsx(React.Fragment, { children: !subChildItem.isChildItem ? (_jsx("li", { className: "nav-item", children: _jsx(Link, { to: subChildItem.link
                                                                            ? subChildItem.link
                                                                            : '/#', className: "nav-link", children: props.t(subChildItem.label) }) })) : (_jsxs("li", { className: "nav-item", children: [_jsxs(Link, { onClick: subChildItem.click, className: "nav-link", to: "/#", "data-bs-toggle": "collapse", children: [' ', props.t(subChildItem.label)] }), _jsx(Collapse, { className: "menu-dropdown", isOpen: subChildItem.stateVariables, id: "sidebarEcommerce", children: _jsx("ul", { className: "nav nav-sm flex-column", children: subChildItem.childItems &&
                                                                                    (subChildItem.childItems ||
                                                                                        []).map(function (subSubChildItem, key) { return (_jsx("li", { className: "nav-item apex", children: _jsx(Link, { to: subSubChildItem.link
                                                                                                ? subSubChildItem.link
                                                                                                : '/#', className: "nav-link", children: props.t(subSubChildItem.label) }) }, key)); }) }) })] })) }, key)); }) }) })] })) }, key)); }) })) })] })) : (_jsx("li", { className: "nav-item", children: _jsxs(Link, { className: "nav-link menu-link", to: item.link ? item.link : '/#', children: [_jsx("i", { className: item.icon }), " ", _jsx("span", { children: props.t(item.label) })] }) }))) : (_jsx("li", { className: "menu-title", children: _jsx("span", { "data-key": "t-menu", children: props.t(item.label) }) })) }, key));
        }) }));
};
HorizontalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any
};
export default withRouter(withTranslation()(HorizontalLayout));
