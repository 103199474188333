import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-js-pagination';
import { Col, Row } from 'reactstrap';
function Pagination(_a) {
    var data = _a.data, length = _a.length, itemsPerPage = _a.itemsPerPage, onPageChange = _a.onPageChange, _b = _a.activePage, activePage = _b === void 0 ? 1 : _b, _c = _a.pageRangeDisplayed, pageRangeDisplayed = _c === void 0 ? 5 : _c;
    var _d = useState(activePage), currentPage = _d[0], setCurrentPage = _d[1];
    // const totalPages = Math.ceil(data.length / itemsPerPage);
    useEffect(function () {
        // Reset to first page when data changes
        setCurrentPage(activePage);
    }, [data, itemsPerPage, activePage]);
    var handlePageChange = function (pageNumber) {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };
    var handleStaticDataLen = function () {
        // Calculate the starting index of the records on the current page
        var startIndex = (currentPage - 1) * itemsPerPage;
        // Calculate the ending index of the records on the current page
        var endIndex = startIndex + itemsPerPage;
        // Adjust the endIndex if it exceeds the total number of records
        if (endIndex > length) {
            endIndex = length;
        }
        // Calculate the number of records displayed on the current page
        var recordsOnThisPage = endIndex - startIndex;
        return recordsOnThisPage;
    };
    return (_jsxs(Row, { className: "p-3", children: [_jsx(Col, { className: "d-flex justify-content-start", children: _jsxs("div", { className: "text-muted", children: ["Showing ", _jsx("span", { className: "fw-semibold", children: handleStaticDataLen() }), " of ", _jsx("span", { className: "fw-semibold", children: length }), ' ', "Results"] }) }), _jsx(Col, { className: "d-flex justify-content-end", children: _jsx(ReactPaginate, { activePage: currentPage, itemsCountPerPage: itemsPerPage, totalItemsCount: length, pageRangeDisplayed: pageRangeDisplayed, onChange: handlePageChange, prevPageText: "Previous", nextPageText: "Next", firstPageText: "First", lastPageText: "Last", linkClass: "page-link", itemClass: "page-item", innerClass: "pagination pagination-separated pagination-sm mb-0" }) })] }));
}
export default Pagination;
