export var LOGIN = '/admin/auth/local/login';
export var VALIDATE_OTP = '/admin/auth/local/tfa';
export var FORGOT_PASSWORD = '/admin/auth/local/password/forgot';
export var RESET_PASSWORD = '/admin/auth/local/password/reset';
export var ROLES = '/admin/roles';
export var USERS = '/admin/users';
export var ADMIN_PRIVILEGES = '/admin/privileges';
export var REFRESH_TOKEN = '/admin/token/refresh';
// tenant
export var ADMIN_TENANT = '/admin/tenants';
export var FILE = '/file';
export var AUTH_MODES = '/authmodes';
export var LICENSE = '/admin/licenses';
export var LICENSE_OS_DEVICES = '/admin/licenses/devices';
