import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { setAuthorization } from '../helpers/api_helper';
import { useDispatch } from 'react-redux';
import { useProfile } from '../Components/Hooks/UserHooks';
import { logoutUser } from '../slices/auth/login/thunk';
var AuthProtected = function (props) {
    var dispatch = useDispatch();
    var _a = useProfile(), userProfile = _a.userProfile, loading = _a.loading, token = _a.token;
    useEffect(function () {
        if (userProfile && !loading && token) {
            setAuthorization(token);
        }
        else if (!userProfile && loading && !token) {
            dispatch(logoutUser());
        }
    }, [token, userProfile, loading, dispatch]);
    /*
     *Navigate is un-auth access protected routes via url
     */
    if (!userProfile && loading && !token) {
        return _jsx(Navigate, { to: { pathname: '/login' } });
    }
    return _jsx(_Fragment, { children: props.children });
};
export default AuthProtected;
