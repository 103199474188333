import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import AuthSlider from './authCarousel';
var SuccessMsg = function () {
    document.title = 'Success Message ';
    return (_jsx(React.Fragment, { children: _jsx("div", { className: "d-flex justify-content-center align-items-center overflow-y-auto", children: _jsx(Col, { lg: 12, children: _jsx(Card, { className: "overflow-x-hidden m-0", children: _jsxs(Row, { className: "g-0 vh-100 vw-100", children: [_jsx(AuthSlider, {}), _jsx(Col, { lg: 4, className: "d-flex justify-content-center align-items-center", children: _jsxs("div", { className: "p-lg-5 p-4 text-center", children: [_jsx("div", { className: "avatar-lg mx-auto mt-2", children: _jsx("div", { className: "avatar-title bg-light text-success display-3 rounded-circle", children: _jsx("i", { className: "ri-checkbox-circle-fill" }) }) }), _jsxs("div", { className: "mt-4 pt-2", children: [_jsx("h4", { children: "Well done !" }), _jsx("p", { className: "text-muted mx-4", children: "Your password is now updated successfully." }), _jsx("div", { className: "mt-4", children: _jsx(Link, { to: "/login", className: "btn btn-success w-100", children: "Back to Login" }) })] })] }) })] }) }) }) }) }));
};
export default SuccessMsg;
