import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
// Import Images
import error500 from '../../../assets/images/commons/error500.png';
var Error500 = function () {
    document.title = '500 Error ';
    return (_jsx(React.Fragment, { children: _jsx("div", { className: "auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100", children: _jsx("div", { className: "auth-page-content overflow-hidden p-0", children: _jsx(Container, { fluid: true, children: _jsx(Row, { className: "justify-content-center", children: _jsxs(Col, { xl: 4, className: "text-center", children: [_jsxs("div", { className: "error-500 position-relative", children: [_jsx("img", { src: error500, alt: "", className: "img-fluid error-500-img error-img" }), _jsx("h1", { className: "title text-muted", children: "500" })] }), _jsxs("div", { children: [_jsx("h4", { children: "Internal Server Error!" }), _jsx("p", { className: "text-muted w-75 mx-auto", children: "Server Error 500. We're not exactly sure what happened, but our servers say something is wrong." }), _jsx(Link, { to: "/", className: "btn btn-success", children: _jsxs("div", { className: "d-flex align-items-center", children: [_jsx("i", { className: "ri-home-4-line me-1" }), "Back to home"] }) })] })] }) }) }) }) }) }));
};
export default Error500;
