import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
// //login
import ForgetPassword from '../pages/Authentication/ForgetPassword';
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
// // User Profile
import Error401 from '../Components/Common/Errors/Error401';
import Error404 from '../Components/Common/Errors/Error404';
import Error500 from '../Components/Common/Errors/Error500';
import Maintenance from '../Components/Common/Errors/Maintenance';
import Offlinepage from '../Components/Common/Errors/Offlinepage';
import SuccessMsg from '../pages/Authentication/SuccessMsg';
import TwosVerify from '../pages/Authentication/TwosVerify';
import Dashboard from '../pages/Dashboard/Dashboard';
import AddTenantMaster from '../pages/TenantMaster/AddTenantMaster';
import TenantMaster from '../pages/TenantMaster/TenantMaster';
import ViewTenantMaster from '../pages/TenantMaster/ViewTenantMaster';
import UserProfile from '../pages/Authentication/user-profile';
import Roles from '../pages/Roles/Roles';
import { privileges } from '../Components/Common/Util';
import License from '../pages/TenantMaster/License';
import Users from '../pages/Users/Users';
import AddLicense from '../pages/TenantMaster/AddLicense';
var authProtectedRoutes = [
    // User Profile
    { path: '/profile', component: _jsx(UserProfile, {}) },
    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: '/', exact: true, component: _jsx(Navigate, { to: "/dashboard" }) },
    { path: '*', component: _jsx(Navigate, { to: "/" }) },
    { path: '/tenantmaster', component: _jsx(TenantMaster, {}), reader: privileges.SA_TENANT_READER, editor: privileges.SA_TENANT_EDITOR },
    { path: '/tenantmaster/add', component: _jsx(AddTenantMaster, {}), reader: privileges.SA_TENANT_READER, editor: privileges.SA_TENANT_EDITOR },
    {
        path: '/tenantmaster/view/:id',
        component: _jsx(ViewTenantMaster, {}),
        reader: privileges.SA_TENANT_READER,
        editor: privileges.SA_TENANT_EDITOR
    },
    {
        path: '/tenantmaster/edit/:id',
        component: _jsx(AddTenantMaster, {}),
        reader: privileges.SA_TENANT_READER,
        editor: privileges.SA_TENANT_EDITOR
    },
    { path: '/dashboard', component: _jsx(Dashboard, {}) },
    { path: '/roles', component: _jsx(Roles, {}), reader: privileges.SA_ROLE_READER, editor: privileges.SA_ROLE_EDITOR },
    { path: '/users', component: _jsx(Users, {}), reader: privileges.SA_USER_READER, editor: privileges.SA_USER_EDITOR },
    {
        path: '/tenantmaster/view/:tenantid/license',
        component: _jsx(License, {}),
        reader: privileges.SA_TENANT_READER,
        editor: privileges.SA_TENANT_EDITOR
    },
    {
        path: '/tenantmaster/view/:tenantid/license/edit/:id',
        component: _jsx(AddLicense, {}),
        reader: privileges.SA_TENANT_READER,
        editor: privileges.SA_TENANT_EDITOR
    },
    {
        path: '/tenantmaster/view/:tenantid/license/view/:id',
        component: _jsx(AddLicense, {}),
        reader: privileges.SA_TENANT_READER,
        editor: privileges.SA_TENANT_EDITOR
    },
    {
        path: '/tenantmaster/view/:tenantid/license/add',
        component: _jsx(AddLicense, {}),
        reader: privileges.SA_TENANT_READER,
        editor: privileges.SA_TENANT_EDITOR
    }
];
var publicRoutes = [
    // Authentication Page
    { path: '/login', component: _jsx(Login, {}) },
    { path: '/logout', component: _jsx(Logout, {}) },
    { path: '/forgot-password', component: _jsx(ForgetPassword, {}) },
    { path: '/login-otp-verify', component: _jsx(TwosVerify, {}) },
    { path: '/forgot-otp-verify', component: _jsx(TwosVerify, {}) },
    { path: '/register', component: _jsx(Register, {}) },
    { path: '/successmsg', component: _jsx(SuccessMsg, {}) },
    { path: '/auth-404', component: _jsx(Error404, {}) },
    { path: '/auth-401', component: _jsx(Error401, {}) },
    { path: '/auth-500', component: _jsx(Error500, {}) },
    { path: '/auth-offline', component: _jsx(Offlinepage, {}) },
    { path: '/maintenance', component: _jsx(Maintenance, {}) }
];
export { authProtectedRoutes, publicRoutes };
