import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { EnvProvider } from './envContext';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './slices/persistor';
var root = ReactDOM.createRoot(document.getElementById('root'));
root.render(_jsx(Provider, { store: store, children: _jsx(PersistGate, { loading: null, persistor: persistor, children: _jsx(HashRouter, { children: _jsx(EnvProvider, { children: _jsx(React.Fragment, { children: _jsx(App, {}) }) }) }) }) }));
/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
