var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { privileges } from '../Components/Common/Util';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
var Navdata = function () {
    var history = useNavigate();
    // state data
    var _a = useState('Dashboard'), iscurrentState = _a[0], setIscurrentState = _a[1];
    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute('sub-items')) {
            var ul = document.getElementById('two-column-menu');
            var iconItems = ul.querySelectorAll('.nav-icon.active');
            var activeIconItems = __spreadArray([], iconItems, true);
            activeIconItems.forEach(function (item) {
                item.classList.remove('active');
                var id = item.getAttribute('sub-items');
                var getID = document.getElementById(id);
                if (getID)
                    getID.classList.remove('show');
            });
        }
    }
    useEffect(function () {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState === 'Dashboard' && window.location.hash === '#/dashboard') {
            history('/dashboard');
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'Tenant Master' && window.location.hash === '#/tenantmaster') {
            history('/tenantmaster');
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'License Requests') {
            history('/license');
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'Roles' && window.location.hash === '#/roles') {
            history('/roles');
            document.body.classList.add('twocolumn-panel');
        }
    }, [history, iscurrentState]);
    var menuItems = [
        // { label: 'Menu', isHeader: true },
        {
            id: 'dashboard',
            label: 'Dashboard',
            icon: 'ri-dashboard-2-line',
            link: '/dashboard',
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            }
        },
        {
            id: 'tenantmaster',
            label: 'Tenant Master',
            icon: 'ri-shield-user-fill',
            link: '/tenantmaster',
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Tenant Master');
                updateIconSidebar(e);
            }
        },
        {
            id: 'roles',
            label: 'Roles',
            icon: 'ri-user-settings-fill',
            reader: privileges.SA_ROLE_READER,
            editor: privileges.SA_ROLE_EDITOR,
            link: '/roles',
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Roles');
            }
        },
        /*
         * {
         *     id: 'license',
         *     label: 'License Requests',
         *     icon: 'ri-shield-star-fill',
         *     link: '/license',
         *     click: function (e: any) {
         *         e.preventDefault();
         *         setIscurrentState('License Requests');
         *     }
         * },
         */
        {
            id: 'users',
            label: 'Users',
            icon: 'ri-user-add-fill',
            reader: privileges.SA_USER_READER,
            editor: privileges.SA_USER_EDITOR,
            link: '/users',
            click: function (e) {
                e.preventDefault();
                setIscurrentState('Users');
                updateIconSidebar(e);
            }
        }
    ];
    return _jsx(React.Fragment, { children: menuItems });
};
export default Navdata;
