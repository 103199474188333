import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
var Fallback = function (_a) {
    var retry = _a.retry;
    var _b = useState(60), seconds = _b[0], setSeconds = _b[1];
    var _c = useState(false), isActive = _c[0], setIsActive = _c[1];
    var timerId;
    useEffect(function () {
        startCountdown();
    }, []);
    var startCountdown = function () {
        if (isActive)
            return;
        setIsActive(true);
        timerId = setInterval(function () {
            setSeconds(function (prevSeconds) {
                if (prevSeconds <= 1) {
                    setSeconds(60);
                    startCountdown();
                    clearInterval(timerId);
                    if (retry)
                        retry();
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);
    };
    return (_jsx("div", { className: "error-screen", children: _jsx(Card, { className: "p-5 shadow-card", children: _jsxs(CardBody, { children: [_jsx("div", { className: "mb-3", children: _jsx("svg", { width: "80", height: "80", viewBox: "0 0 24 24", children: _jsx("path", { fill: "#f7b84b", d: "M12 0C5.372 0 0 5.372 0 12s5.372 12 12 12 12-5.372 12-12S18.628 0 12 0zM12 22C6.486 22 2 17.514 2 12S6.486 2 12 2s10 4.486 10 10-4.486 10-10 10zm1-15h-2v6h2zm0 8h-2v2h2z" }) }) }), _jsx("h1", { className: "something-went-wrong", children: "Oops! Something went wrong." }), _jsx("p", { className: "check-connection", children: "We couldn't load the data. Please check your connection and try again." }), _jsxs("p", { children: ["Auto Retries in ", _jsx("b", { children: seconds }), " seconds"] })] }) }) }));
};
export default Fallback;
