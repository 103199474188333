/* eslint-disable no-shadow */
var LAYOUT_TYPES;
(function (LAYOUT_TYPES) {
    LAYOUT_TYPES["HORIZONTAL"] = "horizontal";
    LAYOUT_TYPES["VERTICAL"] = "vertical";
    LAYOUT_TYPES["TWOCOLUMN"] = "twocolumn";
    LAYOUT_TYPES["SEMIBOX"] = "semibox";
})(LAYOUT_TYPES || (LAYOUT_TYPES = {}));
var LAYOUT_MODE_TYPES;
(function (LAYOUT_MODE_TYPES) {
    LAYOUT_MODE_TYPES["LIGHTMODE"] = "light";
    LAYOUT_MODE_TYPES["DARKMODE"] = "dark";
})(LAYOUT_MODE_TYPES || (LAYOUT_MODE_TYPES = {}));
var LAYOUT_SIDEBAR_TYPES;
(function (LAYOUT_SIDEBAR_TYPES) {
    LAYOUT_SIDEBAR_TYPES["LIGHT"] = "light";
    LAYOUT_SIDEBAR_TYPES["DARK"] = "dark";
    LAYOUT_SIDEBAR_TYPES["GRADIENT"] = "gradient";
    LAYOUT_SIDEBAR_TYPES["GRADIENT_2"] = "gradient-2";
    LAYOUT_SIDEBAR_TYPES["GRADIENT_3"] = "gradient-3";
    LAYOUT_SIDEBAR_TYPES["GRADIENT_4"] = "gradient-4";
})(LAYOUT_SIDEBAR_TYPES || (LAYOUT_SIDEBAR_TYPES = {}));
var LAYOUT_WIDTH_TYPES;
(function (LAYOUT_WIDTH_TYPES) {
    LAYOUT_WIDTH_TYPES["FLUID"] = "lg";
    LAYOUT_WIDTH_TYPES["BOXED"] = "boxed";
})(LAYOUT_WIDTH_TYPES || (LAYOUT_WIDTH_TYPES = {}));
var LAYOUT_POSITION_TYPES;
(function (LAYOUT_POSITION_TYPES) {
    LAYOUT_POSITION_TYPES["FIXED"] = "fixed";
    LAYOUT_POSITION_TYPES["SCROLLABLE"] = "scrollable";
})(LAYOUT_POSITION_TYPES || (LAYOUT_POSITION_TYPES = {}));
var LAYOUT_TOPBAR_THEME_TYPES;
(function (LAYOUT_TOPBAR_THEME_TYPES) {
    LAYOUT_TOPBAR_THEME_TYPES["LIGHT"] = "light";
    LAYOUT_TOPBAR_THEME_TYPES["DARK"] = "dark";
})(LAYOUT_TOPBAR_THEME_TYPES || (LAYOUT_TOPBAR_THEME_TYPES = {}));
var LEFT_SIDEBAR_SIZE_TYPES;
(function (LEFT_SIDEBAR_SIZE_TYPES) {
    LEFT_SIDEBAR_SIZE_TYPES["DEFAULT"] = "lg";
    LEFT_SIDEBAR_SIZE_TYPES["COMPACT"] = "md";
    LEFT_SIDEBAR_SIZE_TYPES["SMALLICON"] = "sm";
    LEFT_SIDEBAR_SIZE_TYPES["SMALLHOVER"] = "sm-hover";
})(LEFT_SIDEBAR_SIZE_TYPES || (LEFT_SIDEBAR_SIZE_TYPES = {}));
var LEFT_SIDEBAR_VIEW_TYPES;
(function (LEFT_SIDEBAR_VIEW_TYPES) {
    LEFT_SIDEBAR_VIEW_TYPES["DEFAULT"] = "default";
    LEFT_SIDEBAR_VIEW_TYPES["DETACHED"] = "detached";
})(LEFT_SIDEBAR_VIEW_TYPES || (LEFT_SIDEBAR_VIEW_TYPES = {}));
var LEFT_SIDEBAR_IMAGE_TYPES;
(function (LEFT_SIDEBAR_IMAGE_TYPES) {
    LEFT_SIDEBAR_IMAGE_TYPES["NONE"] = "none";
    LEFT_SIDEBAR_IMAGE_TYPES["IMG1"] = "img-1";
    LEFT_SIDEBAR_IMAGE_TYPES["IMG2"] = "img-2";
    LEFT_SIDEBAR_IMAGE_TYPES["IMG3"] = "img-3";
    LEFT_SIDEBAR_IMAGE_TYPES["IMG4"] = "img-4";
})(LEFT_SIDEBAR_IMAGE_TYPES || (LEFT_SIDEBAR_IMAGE_TYPES = {}));
var PERLOADER_TYPES;
(function (PERLOADER_TYPES) {
    PERLOADER_TYPES["ENABLE"] = "enable";
    PERLOADER_TYPES["DISABLE"] = "disable";
})(PERLOADER_TYPES || (PERLOADER_TYPES = {}));
var SIDEBAR_VISIBILITY_TYPES;
(function (SIDEBAR_VISIBILITY_TYPES) {
    SIDEBAR_VISIBILITY_TYPES["SHOW"] = "show";
    SIDEBAR_VISIBILITY_TYPES["HIDDEN"] = "hidden";
})(SIDEBAR_VISIBILITY_TYPES || (SIDEBAR_VISIBILITY_TYPES = {}));
export { LAYOUT_TYPES, LAYOUT_MODE_TYPES, LAYOUT_SIDEBAR_TYPES, LAYOUT_WIDTH_TYPES, LAYOUT_POSITION_TYPES, LAYOUT_TOPBAR_THEME_TYPES, LEFT_SIDEBAR_SIZE_TYPES, LEFT_SIDEBAR_VIEW_TYPES, LEFT_SIDEBAR_IMAGE_TYPES, PERLOADER_TYPES, SIDEBAR_VISIBILITY_TYPES };
