var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
// import info from '../../assets/images/svg/information.svg';
import { UncontrolledTooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import EllipsisToolTip from './Tooltip/Tooltip';
import { store } from '../../slices/persistor';
import { CONSTANTS } from '../../common/constants/Constants';
import CryptoJS from 'crypto-js';
import { useEnv } from '../../envContext';
export var envVariables = function () {
    return useEnv();
};
export var getDate = function (date, dateWithoutTime) {
    var sDate = new Date();
    if (!date) {
        date = new Date(sDate);
    }
    date = new Date(date);
    var DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    if (dateWithoutTime) {
        DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric' };
    }
    return date.toLocaleDateString('en-US', DATE_OPTIONS);
};
export var getTime = function (d) {
    var date = new Date(d);
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};
export var convertBytesTo = function (bytes) {
    var decimals = 0;
    if (!+bytes)
        return '0 Bytes';
    var k = 1023;
    var dm = decimals < 0 ? 0 : decimals;
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = Math.floor(Math.log(bytes) / Math.log(k));
    return "".concat(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), " ").concat(sizes[i]);
};
export var convertDataToBytes = function (size) {
    var unit = size.substring(size.length - 2, size.length);
    var amount = parseInt(size.substring(0, size.length - 2));
    var valueInBytes = 0;
    switch (unit === null || unit === void 0 ? void 0 : unit.toUpperCase()) {
        case 'KB':
            valueInBytes = amount * 1024;
            break;
        case 'MB':
            valueInBytes = amount * 1024 * 1024;
            break;
        case 'GB':
            valueInBytes = amount * 1024 * 1024 * 1024;
            break;
        case 'TB':
            valueInBytes = amount * 1024 * 1024 * 1024 * 1024;
            break;
        default:
            break;
    }
    return valueInBytes;
};
export var convertSecondsTo = function (time) {
    var seconds = Math.floor((time / 1000) % 60);
    var minutes = Math.floor((time / 1000 / 60) % 60);
    return { minutes: minutes, seconds: seconds };
};
export var getDateOnly = function (date) {
    return getDate(date, true);
};
export var getDateUTCFormatt = function (param) {
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var dateObj = new Date(param);
    var month = monthNames[dateObj.getMonth()];
    var day = String(dateObj.getDate()).padStart(2, '0');
    var year = dateObj.getFullYear();
    return month + '\n' + day + ', ' + year;
};
export var getUTCTime = function (date) {
    return date === '' || date === null ? date : new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
};
export var uniqBy = function (arr, predicate) {
    var cb = typeof predicate === 'function' ? predicate : function (o) { return o[predicate]; };
    return __spreadArray([], arr
        .reduce(function (map, item) {
        var key = item === null || item === undefined ? item : cb(item);
        map.has(key) || map.set(key, item);
        return map;
    }, new Map())
        .values(), true);
};
export var uniqueID = function (length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
export var getMonth = function (val) {
    return val > 9 ? '-' + val : '-0' + val;
};
export var getDateVal = function (val) {
    return val > 9 ? val : '0' + val;
};
export var convertTextCase = function (name, param) {
    var _a, _b, _c;
    var result;
    if (param)
        result = ((_a = name === null || name === void 0 ? void 0 : name.charAt(0)) === null || _a === void 0 ? void 0 : _a.toUpperCase()) + ((_b = name === null || name === void 0 ? void 0 : name.substr(1)) === null || _b === void 0 ? void 0 : _b.toLowerCase());
    else
        result = ((_c = name === null || name === void 0 ? void 0 : name.charAt(0)) === null || _c === void 0 ? void 0 : _c.toUpperCase()) + (name === null || name === void 0 ? void 0 : name.substr(1));
    return result;
};
export var getUTCGteLteFromDate = function (dateVal) {
    var time = new Date(dateVal);
    var previousDay = new Date(dateVal - 1);
    var gte = previousDay.getFullYear() + getMonth(previousDay.getMonth() + 1) + '-' + getDateVal(previousDay.getDate()) + 'T18:31:00.000Z';
    var lte = time.getFullYear() + getMonth(time.getMonth() + 1) + '-' + getDateVal(time.getDate()) + 'T18:29:00.000Z';
    return { gte: gte, lte: lte };
};
/*
 * function debounce(func:any, wait:any, immediate:any) {
 *     let timeout:any;
 *     return function () {
 *         // eslint-disable-next-line @typescript-eslint/no-this-alias
 *         var context = this,
 *             args = arguments;
 *         var later = function () {
 *             timeout = null;
 *             if (!immediate) func.apply(context, args);
 *         };
 *         var callNow = immediate && !timeout;
 *         clearTimeout(timeout);
 *         timeout = setTimeout(later, wait);
 *         if (callNow) func.apply(context, args);
 *     };
 * }
 */
export var sortByAsc = function (arr, key) {
    return arr.sort(function (a, b) { return (a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0); });
};
export var InfoMessage = function (message) {
    return (_jsx("div", { className: "d-flex justify-content-end", children: _jsx("div", { className: "d-flex mb-3", children: _jsxs("span", { className: "info-message p-2", children: [_jsx("img", { className: "me-1", src: '', alt: "info Icon" }), message] }) }) }));
};
export var convertUTCtoIST = function (date, dateOnly) {
    if (date !== null && date !== '' && date !== undefined) {
        var dateUTC = new Date(date);
        var dateUTCTime = dateUTC.getTime();
        var dateIST = new Date(dateUTCTime);
        /*
         * dateIST.setHours(dateIST.getHours() + 5); // date shifting for IST timezone (+5 hours and 30 minutes)
         * dateIST.setMinutes(dateIST.getMinutes() + 30);
         */
        return dateOnly ? getDateOnly(dateIST) : getDate(dateIST, '');
    }
    else {
        return dateOnly ? getDateOnly(new Date()) : getDate(new Date(), '');
    }
};
export var convertDate = function (str, key) {
    var date = new Date(str), mnth = ('0' + (date.getMonth() + 1)).slice(-2), day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join(key);
};
export var getFormTypeAndRecordId = function (path) {
    var formType;
    var recordID = '';
    var addExist = path.search('/add') !== -1;
    var createExist = path.search('/create') !== -1;
    var viewExist = path.search('/view') !== -1;
    var editExist = path.search('/edit') !== -1;
    var allExist = path.search('/all') !== -1;
    var cloneExist = path.search('/clone') !== -1;
    var enrollmentTokenExist = path.search('/enrollmenttoken') !== -1;
    if (editExist) {
        formType = 'edit';
        recordID = path.split('/').reverse()[0];
    }
    else if (viewExist) {
        formType = 'view';
        recordID = path.split('/').reverse()[0];
    }
    else if (addExist) {
        formType = 'add';
    }
    else if (createExist) {
        formType = 'create';
    }
    else if (allExist) {
        formType = 'all';
    }
    else if (cloneExist) {
        formType = 'clone';
        recordID = path.split('/').reverse()[0];
    }
    else if (enrollmentTokenExist) {
        recordID = path.split('/').reverse()[0];
    }
    return { formType: formType, recordID: recordID };
};
/*
 * export const getTenantInfo = () => {
 *     let userString = AuthUser();
 *     let user = JSON.parse(userString);
 *     let tenantInfo = user?.data?.tenant;
 *     let tenant = {
 *         COMPANY_LOGO_DARK: tenantInfo?.branding?.tenantLogo ? tenantInfo?.branding?.tenantLogo : '',
 *         COMPANY_LOGO_Light: tenantInfo?.branding?.tenantLogo ? tenantInfo?.branding?.tenantLogo : '',
 *         COMPANY_LOGO_WITH_LABEL_DARK: tenantInfo?.branding?.tenantLogoWithLabel ? tenantInfo?.branding?.tenantLogoWithLabel : '',
 *         COMPANY_LOGO_WITH_LABEL_Light: tenantInfo?.branding?.tenantLogoWithLabelLight ? tenantInfo?.branding?.tenantLogoWithLabelLight : '',
 *         COMPANY_ID: tenantInfo?.tenant ? tenantInfo?.tenant : '',
 *         COMPANY_NAME: tenantInfo?.name ? tenantInfo?.name : '',
 *         COMAPNY_PRIMARY_COLOR: '#0045FF',
 *         COMPANY_FOOTER: tenantInfo?.branding?.footerText ? tenantInfo?.branding?.footerText : '',
 *         COMPANY_FAVICON: tenantInfo?.branding?.tenantFavIcon ? tenantInfo?.branding?.tenantFavIcon : '',
 *         PAGE_NUM: 1,
 *         PAGE_SIZE: 10
 *     };
 *     return tenant;
 * };
 */
/*
 * export const AuthUser = () => {
 *     return store.getState()?.Commons?.authUser;
 * };
 */
/*
 * const LogoutTime = () => {
 *     return Store.getState().Commons?.logoutTime;
 * };
 */
export function DownloadFile(Url, fileNameToDownload) {
    fetch(envVariables().SUPERADMIN_URL + '/api/' + Url).then(function (response) {
        response.blob().then(function (blob) {
            var fileURL = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = fileURL;
            a.download = fileNameToDownload;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
    });
}
export var Tooltip = function (id, label) {
    return (_jsx(UncontrolledTooltip, { placement: "bottom", target: id, children: label }));
};
export function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
    var hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
    var mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
    var sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
    return hDisplay + mDisplay + sDisplay;
}
export function diffTwoDatesHrsMintsSeconds(d1, d2) {
    var date1 = new Date(d1);
    var date2 = new Date(d2);
    var timeDiffInMsNoAbs = date2 - date1;
    var timeDiffInMs = Math.abs(date2 - date1);
    var seconds = Math.floor((timeDiffInMs / 1000) % 60);
    var minutes = Math.floor((timeDiffInMs / 1000 / 60) % 60);
    var hours = Math.floor((timeDiffInMs / 1000 / 60 / 60) % 24);
    var days = Math.floor((timeDiffInMs / 1000 / 60 / 60 / 24) % 7);
    var weeks = Math.floor(timeDiffInMs / 1000 / 60 / 60 / 24 / 7);
    var totalHours = Math.floor(timeDiffInMs / 1000 / 60 / 60);
    var totalMinutes = Math.floor(timeDiffInMs / 1000 / 60);
    var totalSeconds = Math.floor(timeDiffInMs / 1000);
    var totalDays = Math.floor(timeDiffInMs / 1000 / 60 / 60 / 24);
    var totalWeeks = Math.floor(timeDiffInMs / 1000 / 60 / 60 / 24 / 7);
    var totalDaysNoAbs = Math.floor(timeDiffInMsNoAbs / 1000 / 60 / 60 / 24);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    return {
        hours: hours,
        minutes: minutes,
        seconds: seconds,
        days: days,
        weeks: weeks,
        totalWeeks: totalWeeks,
        totalDays: totalDays,
        totalHours: totalHours,
        totalMinutes: totalMinutes,
        totalSeconds: totalSeconds,
        totalDaysNoAbs: totalDaysNoAbs
    };
}
export var ellipsisToolTip = function (label) {
    return _jsx(EllipsisToolTip, { options: { effect: 'solid', place: 'bottom' }, children: label });
};
export var ellipsisWithToolTipId = function (id, val) {
    return (_jsx(EllipsisToolTip, { options: { effect: 'solid', place: 'bottom' }, target: id, children: val }));
};
export var ellipsisWithToolTip = function (val) {
    return _jsx(EllipsisToolTip, { options: { effect: 'solid', place: 'bottom' }, children: val });
};
export var AuthUser = function () {
    var _a, _b;
    var authUser = (_b = (_a = store.getState()) === null || _a === void 0 ? void 0 : _a.Login) === null || _b === void 0 ? void 0 : _b.user;
    return authUser ? authUser : null;
};
export var IsAuthorized = function (props) {
    var _a, _b;
    var userString = AuthUser();
    var user = JSON.parse(JSON.stringify(userString));
    if (props.privRequired) {
        var findPriv = (_b = (_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.privileges) === null || _b === void 0 ? void 0 : _b.includes(props.privRequired);
        if (findPriv)
            return props === null || props === void 0 ? void 0 : props.yes();
        else
            return props === null || props === void 0 ? void 0 : props.no();
    }
    else
        return props === null || props === void 0 ? void 0 : props.yes();
};
IsAuthorized.defaultProps = {
    yes: function () { return null; },
    no: function () { return null; }
};
export var IsAuthorizedCondition = function (props) {
    var _a, _b, _c;
    var userString = AuthUser();
    var user = userString ? JSON.parse(userString) : '';
    if (((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a[props.key]) === props.value || ((_c = (_b = user === null || user === void 0 ? void 0 : user.data) === null || _b === void 0 ? void 0 : _b[props.key]) === null || _c === void 0 ? void 0 : _c.includes(props.value))) {
        return props === null || props === void 0 ? void 0 : props.yes();
    }
    return props === null || props === void 0 ? void 0 : props.no();
};
IsAuthorizedCondition.defaultProps = {
    yes: function () { return null; },
    no: function () { return null; }
};
/*
 * const IsAuthorized = (props) => {
 *     let userString = AuthUser();
 *     let user = JSON.parse(userString);
 *     let roles = user?.data?.roles;
 *     if (props.privRequired) {
 *         let findPriv = roles.filter((role) => role.authority === props.privRequired)?.length > 0;
 *         if (findPriv) return props.yes();
 *         else return props.no();
 *     } else return props.yes();
 * };
 */
/*
 * IsAuthorized.defaultProps = {
 *     yes: () => null,
 *     no: () => null
 * };
 */
/*
 * const PrivCheck = (props) => {
 *     let userString = AuthUser();
 *     let user = JSON.parse(userString);
 *     let roles = user?.data?.roles;
 *     if (props.reader || props.editor) {
 *         let findPriv = roles.filter((role) => role.authority === props.reader || role.authority === props.editor)?.length > 0;
 */
/*
 *         if (findPriv) return props.yes();
 *         else return props.no();
 *     } else return props.yes();
 * };
 */
/*
 * PrivCheck.defaultProps = {
 *     yes: () => null,
 *     no: () => null
 * };
 */
/*
 * export const findReaderPrivilege = (readerPriv) => {
 *     let userString = AuthUser();
 *     let user = JSON.parse(userString);
 *     let roles = user?.data?.roles;
 *     return roles.filter((role) => role.authority === readerPriv)?.length === 0;
 * };
 */
/*
 * export const findEditorPrivilege = (editorPriv) => {
 *     let userString = AuthUser();
 *     let user = JSON.parse(userString);
 *     let roles = user?.data?.roles;
 *     return roles.filter((role) => role.authority === editorPriv)?.length === 0;
 * };
 */
export var sortBy = function (arr, key) {
    return arr.sort(function (a, b) { return (a[key] < b[key] ? -1 : b[key] < a[key] ? 1 : 0); });
};
export var noCaseSensitiveSortBy = function (arr, key) {
    return arr.sort(function (a, b) { var _a, _b, _c, _d; return ((_a = a[key]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) < ((_b = b[key]) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ? -1 : ((_c = b[key]) === null || _c === void 0 ? void 0 : _c.toLowerCase()) < ((_d = a[key]) === null || _d === void 0 ? void 0 : _d.toLowerCase()) ? 1 : 0; });
};
export var guid = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export var generateSeqOfNumbers = function (startingNum, incrementBy) {
    return Array(parseInt(startingNum))
        .fill('')
        .map(function (_element, index) { return index + incrementBy; });
};
export var renderActionItems = function (props, includeEdit, includeDelete) {
    if (includeEdit === void 0) { includeEdit = true; }
    if (includeDelete === void 0) { includeDelete = true; }
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "position-absolute top-0 start-100 icon-zIndex", children: [includeEdit && (_jsxs("div", { className: "badge-override-settings d-flex align-items-center justify-content-center cursor-pointer", onClick: function () { return props.onClickEdit(props.item); }, children: [_jsx("i", { className: "ri-settings-3-line px-1" }), " ", _jsx("span", { className: "showOnHover", children: "Properties" })] })), includeDelete && (_jsxs("div", { className: "badge-override-delete d-flex align-items-center justify-content-center cursor-pointer ", onClick: function () { return props.onClickDelete(props.item); }, children: [_jsx("i", { className: "ri-delete-bin-line px-1" }), " ", _jsx("span", { className: "showOnHover", children: "Delete" })] }))] }) }));
};
export var renderComponentActionItems = function (props) {
    var _a;
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "position-absolute top-0 start-100 icon-zIndex", children: [((_a = props.data) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsxs("div", { className: "badge-override-submit d-flex align-items-center justify-content-center cursor-pointer", onClick: function () { return props.onClickSubmit(); }, children: [_jsx("i", { className: "ri-check-line px-1" }), " ", _jsx("span", { className: "showOnHover", children: "Submit" })] })), _jsxs("div", { className: "badge-override-delete d-flex align-items-center justify-content-center cursor-pointer ", onClick: function () { return props.onClickDelete(); }, children: [_jsx("i", { className: "ri-delete-bin-line px-1" }), " ", _jsx("span", { className: "showOnHover", children: "Delete" })] })] }) }));
};
export var transformDropdownValuesToObjects = function (formSchema, data) {
    formSchema.forEach(function (schema) {
        if (schema.inputType === 'dropdown' || schema.inpuType === 'radio') {
            data.forEach(function (obj) {
                var _a;
                if (obj[schema.value]) {
                    if (!schema.isMulti && typeof obj[schema.value] === 'string') {
                        obj[schema.value] = (_a = schema === null || schema === void 0 ? void 0 : schema.options) === null || _a === void 0 ? void 0 : _a.filter(function (option) { var _a, _b; return ((_a = option.value) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_b = obj[schema.value]) === null || _b === void 0 ? void 0 : _b.toLowerCase()); });
                        obj[schema.value] = obj[schema.value].length > 0 ? obj[schema.value][0] : {};
                    }
                    else if (schema.isMulti) {
                        var selectedOptions_1 = [];
                        obj[schema.value].forEach(function (value) {
                            var _a;
                            selectedOptions_1.push((_a = schema === null || schema === void 0 ? void 0 : schema.options) === null || _a === void 0 ? void 0 : _a.filter(function (option) { return option.value.toLowerCase() === value.toLowerCase(); })[0]);
                        });
                        obj[schema.value] = selectedOptions_1;
                    }
                }
            });
        }
    });
    return data;
};
export var transformDropdownValuesToString = function (formSchema, data) {
    formSchema.forEach(function (schema) {
        if (schema.inputType === 'dropdown') {
            data === null || data === void 0 ? void 0 : data.forEach(function (obj) {
                var _a;
                if (obj[schema.value] && obj[schema.value] !== undefined && Object.keys(obj[schema.value]).length > 0) {
                    if (!schema.isMulti) {
                        obj[schema.value] = obj[schema.value].value;
                    }
                    else {
                        obj[schema.value] = (_a = obj[schema.value]) === null || _a === void 0 ? void 0 : _a.map(function (valObj) { return valObj.value; });
                    }
                }
            });
        }
    });
    return data;
};
export var getChartColorsArray = function (colors) {
    colors = JSON.parse(colors);
    return colors.map(function (value) {
        var newValue = value.replace(' ', '');
        if (newValue.indexOf(',') === -1) {
            var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
            if (color.indexOf('#') !== -1)
                color = color.replace(' ', '');
            if (color)
                return color;
            else
                return newValue;
        }
        else {
            var val = value.split(',');
            if (val.length === 2) {
                var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = 'rgba(' + rgbaColor + ',' + val[1] + ')';
                return rgbaColor;
            }
            else {
                return newValue;
            }
        }
    });
};
export var getChartsData = function (props) {
    return {
        labels: (props === null || props === void 0 ? void 0 : props.labels) ? props === null || props === void 0 ? void 0 : props.labels : [],
        chart: {
            type: 'donut',
            height: 250
        },
        plotOptions: {
            pie: {
                size: 100,
                offsetX: 0,
                offsetY: 0,
                donut: {
                    size: '76%',
                    labels: {
                        show: true,
                        name: {
                            show: true,
                            fontSize: '18px',
                            offsetY: -5
                        },
                        value: {
                            show: true,
                            fontSize: '20px',
                            color: '#343a40',
                            fontWeight: 500,
                            offsetY: 5,
                            formatter: function (val) {
                                return val;
                            }
                        },
                        total: {
                            show: true,
                            fontSize: '13px',
                            label: (props === null || props === void 0 ? void 0 : props.label) ? props === null || props === void 0 ? void 0 : props.label : 'Total Devices',
                            color: '#9599ad',
                            fontWeight: 500,
                            formatter: function (w) {
                                return (props === null || props === void 0 ? void 0 : props.showTotal)
                                    ? props === null || props === void 0 ? void 0 : props.totalDevices
                                    : w.globals.seriesTotals.reduce(function (a, b) {
                                        return a + b;
                                    }, 0);
                            }
                        }
                    }
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        legend: {
            show: false,
            position: 'bottom',
            horizontalAlign: 'center',
            offsetX: 0,
            offsetY: 0,
            markers: {
                width: 15,
                height: 10,
                radius: 2
            },
            itemMargin: {
                horizontal: 12,
                vertical: 0
            }
        },
        colors: (props === null || props === void 0 ? void 0 : props.colors) ? props === null || props === void 0 ? void 0 : props.colors : []
    };
};
export var viewChange = function (displayType, handleView) {
    return (_jsxs(_Fragment, { children: [_jsx("button", { type: "button", onClick: function () { return handleView('grid'); }, className: "me-2 ps-2 pe-2 pt-1 pb-1 btn ".concat(displayType === 'grid' ? 'btn-info' : 'btn-soft-info waves-effect waves-light'), children: _jsx("span", { className: "d-flex align-items-center fs-18", children: _jsx("i", { className: "ri-grid-fill" }) }) }), _jsx("button", { type: "button", onClick: function () { return handleView('table'); }, className: "me-2 ps-2 pe-2 pt-1 pb-1 btn ".concat(displayType === 'table' ? 'btn-info' : 'btn-soft-info waves-effect waves-light'), children: _jsx("span", { className: "d-flex align-items-center fs-18", children: _jsx("i", { className: "ri-menu-fill" }) }) })] }));
};
export var capitalizeFirstLetter = function (string) {
    var _a, _b, _c, _d;
    return ((_b = (_a = string === null || string === void 0 ? void 0 : string.charAt) === null || _a === void 0 ? void 0 : _a.call(string, 0)) === null || _b === void 0 ? void 0 : _b.toUpperCase()) + ((_d = (_c = string === null || string === void 0 ? void 0 : string.slice) === null || _c === void 0 ? void 0 : _c.call(string, 1)) === null || _d === void 0 ? void 0 : _d.toLowerCase());
};
export var handleSaveText = function (type) {
    switch (type) {
        case 'edit':
            return 'Update';
        case 'add':
            return 'Save';
        case 'create':
            return 'Save';
        case 'clone':
            return 'Save';
        default:
            break;
    }
};
var handleRegexTypeMsg = function (field) {
    var _a;
    var regexNames = {
        alphanumericspecial: 'alphanumeric special characters',
        alphanumeric: 'alphanumeric',
        alphabets: 'alphabets',
        alphabetsandspecialchars: 'alphabets and special characters',
        alphabetsandspecs: 'alphabets and special characters',
        floatdecimal: 'decimals'
    };
    var regexName = '';
    if (field && ((_a = Object.keys(field)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        Object.keys(field).forEach(function (key) {
            if (regexNames[key]) {
                regexName = regexNames[key];
            }
        });
    }
    return regexName;
};
export var generateValidationSchema = function (formData) {
    var schema = {};
    formData.forEach(function (field) {
        var validation;
        // Initialize validation based on field type
        switch (field.type) {
            case 'text':
                validation = yup.string();
                break;
            case 'email':
                validation = yup
                    .string()
                    .email('Invalid email address')
                    .matches(/@gmail\.com$/, 'Email should end with "@gmail.com"');
                break;
            case 'number':
                validation = yup.number();
                break;
            case 'object':
                validation = yup.object();
                break;
            case 'array':
                validation = field.mandatory ? yup.array().min(1, "Please select at least one ".concat(field.label)) : yup.array();
                break;
            default:
                validation = yup.string();
        }
        var mandatory = field.mandatory, minLen = field.minLen, maxLen = field.maxLen, regex = field.regex;
        if (mandatory) {
            validation = validation.required("".concat(field.label, " ").concat('is required'));
        }
        if (minLen) {
            validation = validation.min(field.minLen, "".concat(field.label, " must be at least ").concat(field.minLen, " characters"));
        }
        if (maxLen && field.type === 'number') {
            validation = validation.test('max-length', "".concat(field.label, " should be ").concat(maxLen, " digits"), function (value) { return !value || (value === null || value === void 0 ? void 0 : value.toString().length) === maxLen; });
        }
        else if (maxLen) {
            validation = validation.max(field.maxLen, "".concat(field.label, " cannot exceed ").concat(field.maxLen, " characters"));
        }
        if (regex) {
            if (field.type === 'text') {
                validation = validation.matches(new RegExp(regex), "".concat(field.label, " must be ").concat(handleRegexTypeMsg(field)));
            }
            else {
                validation = validation.matches(new RegExp(regex), "".concat(field.label, " is not valid"));
            }
        }
        schema[field.value] = validation;
    });
    return yup.object().shape(schema);
};
export var generateInitialValues = function (formData) {
    var initialValues = {};
    formData.forEach(function (field) {
        switch (field.type) {
            case 'array':
                initialValues[field.value] = [];
                break;
            case 'checkbox':
                initialValues[field.value] = false;
                break;
            default:
                initialValues[field.value] = (field === null || field === void 0 ? void 0 : field.initialValue) || '';
        }
    });
    return initialValues;
};
/*
 * export const handleValidationSchema = (formSchema) => {
 *     let validationSchema = [];
 *     formSchema?.forEach((obj) => {
 *         if (obj?.fieldName) {
 *             let schema = {};
 *             let validations = {};
 *             schema['name'] = obj.fieldName;
 *             schema['label'] = obj.label;
 *             schema['pattern'] = obj.pattern ? obj.pattern : '';
 *             validations['nodeValue'] = [];
 *             if (obj.required) {
 *                 validations['nodeValue'].push('required');
 *             }
 *             if (obj.maxlength) {
 *                 validations['nodeValue'].push('maxLength');
 *                 validations['maxlength'] = obj.maxlength;
 *             }
 *             if (obj.minlength) {
 *                 validations['nodeValue'].push('minLength');
 *                 validations['minlength'] = obj.minlength;
 *             }
 *             if (obj.inputType && Object.keys(obj.inputType).length > 0) {
 *                 validations['nodeValue'].push(obj?.inputType?.value);
 *             }
 *             schema['value'] = '';
 *             schema['validations'] = validations;
 *             validationSchema.push(schema);
 *         }
 *     });
 *     return validationSchema;
 * };
 */
/*
 * export const getSortedData = (data, topIds, checkBy) => {
 *     let priorityData = [];
 *     let nonPriorityData = [];
 *     data?.forEach((obj) => {
 *         if (topIds && topIds?.includes(obj[`${checkBy}`])) {
 *             priorityData.push(obj);
 *         } else {
 *             nonPriorityData.push(obj);
 *         }
 *     });
 *     return priorityData.concat(nonPriorityData);
 * };
 */
export var handleGenderValues = function (value) {
    if (value === 'M')
        return 'Male';
    if (value === 'F')
        return 'Female';
    if (value === 'U')
        return 'Other';
};
export var convertToTitleCase = function (string) {
    string = string === null || string === void 0 ? void 0 : string.toLowerCase().replace(/\b\w/g, function (letter) {
        return letter === null || letter === void 0 ? void 0 : letter.toUpperCase();
    });
    return string;
};
export var handleSwitchModalBody = function (status) {
    return (_jsx(_Fragment, { children: _jsxs("div", { className: "text-centre", children: ["Are you sure you want to ", status === 'active' ? 'inactivate' : 'activate', " this record?"] }) }));
};
export var restrictSpace = function (e, props) {
    var _a, _b, _c, _d, _e, _f, _g;
    if (props.alphanumericspecial) {
        var pattern = new RegExp(/^[ A-Za-z0-9_@.,~{}<>?`":;|()[!$=%^*()/#&+-]*$/g);
        if (!pattern.test(e.key)) {
            e.preventDefault();
        }
    }
    if (props.alphanumeric) {
        var pattern = new RegExp(/^[A-Za-z0-9 ]+$/g);
        if (!pattern.test(e.key)) {
            e.preventDefault();
        }
    }
    if (props.alphabets) {
        var pattern = new RegExp(/^[a-zA-Z\s]*$/);
        if (!pattern.test(e.key)) {
            e.preventDefault();
        }
    }
    if (props.alphabetsandspecialchars) {
        var pattern = new RegExp(/^[ a-zA-Z\n_@,~{}!=$<>?":|`%[;^*()./#&+-/s]*$/g);
        if (!pattern.test(e.key)) {
            e.preventDefault();
        }
    }
    if (props.alphabetsandspecs) {
        var pattern = new RegExp(/^[a-zA-Z-.()\s]*$/g);
        if (!pattern.test(e.key)) {
            e.preventDefault();
        }
    }
    if (props.floatdecimal) {
        var regex = /^0(?! \d+$)/;
        var stringRegex = /^[a-zA-Z!@#$%^&*)(+=,_-]$/;
        if (stringRegex.test(e.key) || e.which === 32 || (((_a = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _a === void 0 ? void 0 : _a.selectionStart) === 0 && e.which === 190))
            e.preventDefault();
        if (regex.test(e.key) && ((_b = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _b === void 0 ? void 0 : _b.selectionStart) === 0) {
            e.preventDefault();
        }
    }
    if (props.maxval) {
        var val = Number(props.value + e.key);
        if (val > props.maxval) {
            e.preventDefault();
        }
    }
    if (props.minval) {
        var val = Number(props.value + e.key);
        if (val < props.minval) {
            e.preventDefault();
        }
    }
    if (props.email) {
        var reg = new RegExp('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$');
        var stringRegex = /^[!#$%^&*)([+=,:;<>/{}`|~'?"]$/;
        if (stringRegex.test(e.key) || e.which === 32 || (((_c = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _c === void 0 ? void 0 : _c.selectionStart) === 0 && e.which === 190)) {
            e.preventDefault();
        }
        if (!reg.test(e.key) && ((_d = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _d === void 0 ? void 0 : _d.selectionStart) === 0) {
            e.preventDefault();
        }
    }
    if (props.numbers) {
        var reg = new RegExp('^[0-9]+$');
        var stringRegex = /^[a-zA-Z!@#$%^&*)([+=,:;<>/{}`|~'?"_-]$/;
        if (stringRegex.test(e.key) || e.which === 32 || (((_e = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _e === void 0 ? void 0 : _e.selectionStart) === 0 && e.which === 190)) {
            e.preventDefault();
        }
        if (!reg.test(e.key) && ((_f = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _f === void 0 ? void 0 : _f.selectionStart) === 0) {
            e.preventDefault();
        }
    }
    if (e.which === 32 && ((_g = e === null || e === void 0 ? void 0 : e.currentTarget) === null || _g === void 0 ? void 0 : _g.selectionStart) === 0)
        e.preventDefault();
    if (props.type === 'number' && (e.which === 69 || e.which === 189 || e.which === 187 || e.which === 190))
        e.preventDefault();
};
/*
 * export const trimValues = (values, schema, t) => {
 *     let flag = {};
 *     Object.keys(values).reduce((acc, key) => {
 *         acc[key] = typeof values[key] === 'string' ? values[key]?.trim() : values[key];
 *         if (Object.keys(acc)?.length > 0) {
 *             Object.keys(acc)?.forEach(() => {
 *                 schema?.forEach((obj) => {
 *                     if (obj?.value === key) {
 *                         if (obj?.inputType === 'input') {
 *                             if (acc[key] === '') {
 *                                 flag = { ...flag, [key]: `${t(obj?.label)} ${t('is required')}` };
 *                             } else {
 *                                 if (flag[key]) {
 *                                     delete flag[key];
 *                                 }
 *                             }
 *                         } else {
 *                             delete flag[key];
 *                         }
 *                     } else if (acc[key] !== '') {
 *                         delete flag[key];
 *                     }
 *                 });
 *             });
 *         }
 *         if (typeof values[key] !== 'string') {
 *             delete flag[key];
 *         }
 *         return flag;
 *     }, {});
 */
/*
 *     return flag;
 * };
 */
/*
 * export const getValuesToSend = (valueObj, formSchema, additionalkeysToSend) => {
 *     let dataToSend = {};
 *     let itemsToInclude = formSchema.map((obj) => obj.value)?.concat(additionalkeysToSend);
 *     Object.keys(valueObj)?.forEach((key) => {
 *         if (itemsToInclude.includes(key)) {
 *             dataToSend[key] = valueObj[key];
 *         }
 *     });
 *     return dataToSend;
 * };
 */
export var unixToISO = function (dateInUnix) {
    var ts = new Date(dateInUnix);
    return ts.toISOString();
};
export var searchByKey = function (array, key, value) {
    return array.filter(function (obj) { var _a, _b; return (_b = (_a = obj[key]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes(value === null || value === void 0 ? void 0 : value.toLowerCase()); });
};
export var convertToNewFormTemplateSchema = function (sections) {
    var newDataToSend = [];
    sections === null || sections === void 0 ? void 0 : sections.forEach(function (section) {
        var _a;
        var copySection = JSON.parse(JSON.stringify(section));
        delete copySection.withVal;
        var layoutData = [];
        (_a = section === null || section === void 0 ? void 0 : section.information) === null || _a === void 0 ? void 0 : _a.forEach(function (layout) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
            if (((_b = (_a = layout === null || layout === void 0 ? void 0 : layout.type) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'text') {
                layoutData === null || layoutData === void 0 ? void 0 : layoutData.push({
                    key: 'common',
                    elementTypeValue: layout === null || layout === void 0 ? void 0 : layout.textDescription,
                    elementTypeCategory: (_c = layout === null || layout === void 0 ? void 0 : layout.type) === null || _c === void 0 ? void 0 : _c.value,
                    title: layout === null || layout === void 0 ? void 0 : layout.title,
                    elementId: '',
                    elementName: '',
                    elementValue: '',
                    col: layout === null || layout === void 0 ? void 0 : layout.col,
                    row: layout === null || layout === void 0 ? void 0 : layout.row,
                    id: layout === null || layout === void 0 ? void 0 : layout.id
                });
            }
            if (((_e = (_d = layout === null || layout === void 0 ? void 0 : layout.type) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.toLowerCase()) === 'asset_type') {
                layoutData === null || layoutData === void 0 ? void 0 : layoutData.push({
                    key: 'common',
                    elementTypeValue: (_f = layout === null || layout === void 0 ? void 0 : layout.assetType) === null || _f === void 0 ? void 0 : _f.value,
                    elementTypeLabel: (_g = layout === null || layout === void 0 ? void 0 : layout.assetType) === null || _g === void 0 ? void 0 : _g.label,
                    elementTypeId: (_h = layout === null || layout === void 0 ? void 0 : layout.assetType) === null || _h === void 0 ? void 0 : _h.id,
                    elementTypeCategory: (_j = layout === null || layout === void 0 ? void 0 : layout.type) === null || _j === void 0 ? void 0 : _j.value,
                    title: layout === null || layout === void 0 ? void 0 : layout.title,
                    elementId: (_k = layout === null || layout === void 0 ? void 0 : layout.asset) === null || _k === void 0 ? void 0 : _k.assetId,
                    elementName: (_l = layout === null || layout === void 0 ? void 0 : layout.asset) === null || _l === void 0 ? void 0 : _l.label,
                    assetLink: layout === null || layout === void 0 ? void 0 : layout.uploadItemLink,
                    col: layout === null || layout === void 0 ? void 0 : layout.col,
                    row: layout === null || layout === void 0 ? void 0 : layout.row,
                    id: layout === null || layout === void 0 ? void 0 : layout.id
                });
            }
            if (((_o = (_m = layout === null || layout === void 0 ? void 0 : layout.type) === null || _m === void 0 ? void 0 : _m.value) === null || _o === void 0 ? void 0 : _o.toLowerCase()) === 'questionnaire_type') {
                layoutData === null || layoutData === void 0 ? void 0 : layoutData.push({
                    key: 'common',
                    elementTypeValue: (_p = layout === null || layout === void 0 ? void 0 : layout.questionnaireType) === null || _p === void 0 ? void 0 : _p.value,
                    elementTypeLabel: (_q = layout === null || layout === void 0 ? void 0 : layout.questionnaireType) === null || _q === void 0 ? void 0 : _q.label,
                    elementTypeId: (_r = layout === null || layout === void 0 ? void 0 : layout.questionnaireType) === null || _r === void 0 ? void 0 : _r.id,
                    elementTypeCategory: (_s = layout === null || layout === void 0 ? void 0 : layout.type) === null || _s === void 0 ? void 0 : _s.value,
                    title: layout === null || layout === void 0 ? void 0 : layout.title,
                    elementId: (_t = layout === null || layout === void 0 ? void 0 : layout.questionnaireTemplate) === null || _t === void 0 ? void 0 : _t.questionnaireId,
                    elementName: (_u = layout === null || layout === void 0 ? void 0 : layout.questionnaireTemplate) === null || _u === void 0 ? void 0 : _u.label,
                    col: layout === null || layout === void 0 ? void 0 : layout.col,
                    row: layout === null || layout === void 0 ? void 0 : layout.row,
                    id: layout === null || layout === void 0 ? void 0 : layout.id
                });
            }
        });
        copySection['information'] = layoutData;
        newDataToSend === null || newDataToSend === void 0 ? void 0 : newDataToSend.push(copySection);
    });
    return newDataToSend;
};
export var convertToOldFormTemplateSchema = function (sections) {
    var newDataToSend = [];
    sections === null || sections === void 0 ? void 0 : sections.forEach(function (section) {
        var _a;
        var copySection = JSON.parse(JSON.stringify(section));
        copySection['withVal'] = true;
        var layoutData = [];
        (_a = section === null || section === void 0 ? void 0 : section.information) === null || _a === void 0 ? void 0 : _a.forEach(function (layout) {
            var _a, _b, _c, _d;
            if (((_a = layout === null || layout === void 0 ? void 0 : layout.elementTypeCategory) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'text') {
                layoutData === null || layoutData === void 0 ? void 0 : layoutData.push({
                    key: 'common',
                    type: {
                        label: 'Text',
                        value: layout === null || layout === void 0 ? void 0 : layout.elementTypeCategory
                    },
                    title: layout === null || layout === void 0 ? void 0 : layout.title,
                    textDescription: layout === null || layout === void 0 ? void 0 : layout.elementTypeValue,
                    col: layout === null || layout === void 0 ? void 0 : layout.col,
                    row: layout === null || layout === void 0 ? void 0 : layout.row,
                    withVal: true,
                    id: layout === null || layout === void 0 ? void 0 : layout.id
                });
            }
            if (((_b = layout === null || layout === void 0 ? void 0 : layout.elementTypeCategory) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'asset_type') {
                layoutData === null || layoutData === void 0 ? void 0 : layoutData.push({
                    key: 'common',
                    type: {
                        label: 'Asset',
                        value: layout === null || layout === void 0 ? void 0 : layout.elementTypeCategory
                    },
                    title: layout === null || layout === void 0 ? void 0 : layout.title,
                    assetType: {
                        label: layout === null || layout === void 0 ? void 0 : layout.elementTypeLabel,
                        value: layout === null || layout === void 0 ? void 0 : layout.elementTypeValue,
                        id: layout === null || layout === void 0 ? void 0 : layout.elementTypeId
                    },
                    asset: (layout === null || layout === void 0 ? void 0 : layout.assetLink) && ((_c = layout === null || layout === void 0 ? void 0 : layout.assetLink) === null || _c === void 0 ? void 0 : _c.length) > 0
                        ? ''
                        : {
                            assetId: layout === null || layout === void 0 ? void 0 : layout.elementId,
                            label: layout === null || layout === void 0 ? void 0 : layout.elementName,
                            value: layout === null || layout === void 0 ? void 0 : layout.elementId,
                            attachment: '',
                            id: uniqueID(10)
                        },
                    uploadItem: '',
                    uploadItemLink: layout === null || layout === void 0 ? void 0 : layout.assetLink,
                    col: layout === null || layout === void 0 ? void 0 : layout.col,
                    row: layout === null || layout === void 0 ? void 0 : layout.row,
                    withVal: true,
                    id: layout === null || layout === void 0 ? void 0 : layout.id
                });
            }
            if (((_d = layout === null || layout === void 0 ? void 0 : layout.elementTypeCategory) === null || _d === void 0 ? void 0 : _d.toLowerCase()) === 'questionnaire_type') {
                layoutData === null || layoutData === void 0 ? void 0 : layoutData.push({
                    key: 'common',
                    type: {
                        label: 'Questionnaire',
                        value: layout === null || layout === void 0 ? void 0 : layout.elementTypeCategory
                    },
                    title: layout === null || layout === void 0 ? void 0 : layout.title,
                    questionnaireType: {
                        value: layout === null || layout === void 0 ? void 0 : layout.elementTypeValue,
                        label: layout === null || layout === void 0 ? void 0 : layout.elementTypeLabel,
                        id: layout === null || layout === void 0 ? void 0 : layout.elementTypeId,
                        type: layout === null || layout === void 0 ? void 0 : layout.elementTypeCategory
                    },
                    questionnaireTemplate: {
                        label: layout === null || layout === void 0 ? void 0 : layout.elementName,
                        value: layout === null || layout === void 0 ? void 0 : layout.elementId,
                        questionnaireId: layout === null || layout === void 0 ? void 0 : layout.elementId
                    },
                    col: layout === null || layout === void 0 ? void 0 : layout.col,
                    row: layout === null || layout === void 0 ? void 0 : layout.row,
                    withVal: true,
                    id: layout === null || layout === void 0 ? void 0 : layout.id
                });
            }
        });
        copySection['information'] = layoutData;
        newDataToSend === null || newDataToSend === void 0 ? void 0 : newDataToSend.push(copySection);
    });
    return newDataToSend;
};
// Making an array Unique by Key and keep the latest occurrence of an object with the same key while making the array unique.
export var makeArrayUniqueByKey = function (array, key) {
    var uniqueArray = [];
    var uniqueKeys = new Set();
    array.forEach(function (obj) {
        if (!(uniqueKeys === null || uniqueKeys === void 0 ? void 0 : uniqueKeys.has(obj === null || obj === void 0 ? void 0 : obj[key]))) {
            uniqueArray === null || uniqueArray === void 0 ? void 0 : uniqueArray.push(obj);
            uniqueKeys.add(obj[key]);
        }
        else {
            var existingIndex = uniqueArray.findIndex(function (item) { return item[key] === obj[key]; });
            uniqueArray[existingIndex] = obj;
        }
    });
    return uniqueArray;
};
export var chunkArray = function (chunkSize, array) {
    var finalArray = [];
    for (var i = 0; i < array.length; i += chunkSize) {
        var chunk = array.slice(i, i + chunkSize);
        finalArray === null || finalArray === void 0 ? void 0 : finalArray.push(chunk);
    }
    return finalArray;
};
export var userLabel = function (userName) {
    var _a, _b, _c, _d, _e, _f;
    var name = userName === null || userName === void 0 ? void 0 : userName.split(' ');
    if ((name === null || name === void 0 ? void 0 : name.length) === 1)
        return (_b = (_a = name === null || name === void 0 ? void 0 : name[0]) === null || _a === void 0 ? void 0 : _a.charAt(0)) === null || _b === void 0 ? void 0 : _b.toUpperCase();
    else
        return ((_d = (_c = name === null || name === void 0 ? void 0 : name[0]) === null || _c === void 0 ? void 0 : _c.charAt(0)) === null || _d === void 0 ? void 0 : _d.toUpperCase()) + ((_f = (_e = name === null || name === void 0 ? void 0 : name[1]) === null || _e === void 0 ? void 0 : _e.charAt(0)) === null || _f === void 0 ? void 0 : _f.toUpperCase());
};
export var getDateAndDay = function (timestamp) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' };
    var date = timestamp ? new Date(timestamp) : new Date();
    var day = date.toLocaleDateString('en-US', DATE_OPTIONS);
    var monthIndex = date.getMonth();
    var monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    var month = monthNames[monthIndex];
    var year = date.getFullYear();
    return {
        date: (_e = (_d = (_c = (_b = (_a = day === null || day === void 0 ? void 0 : day.split('/')) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.split(', ')) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.split(' ')) === null || _e === void 0 ? void 0 : _e[1],
        day: (_h = (_g = (_f = day === null || day === void 0 ? void 0 : day.split('/')) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.split(', ')) === null || _h === void 0 ? void 0 : _h[0],
        month: month,
        year: year
    };
};
export var getLastMonths = function (lastInMonths) {
    var date = new Date();
    var lastMonths = [], monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    for (var i = lastInMonths + 1; i > 0; i--) {
        lastMonths.push("".concat(monthNames[date.getMonth()], " ").concat(date.getFullYear()));
        date.setMonth(date.getMonth() - 1);
    }
    var unique = lastMonths.filter(onlyUnique);
    return unique === null || unique === void 0 ? void 0 : unique.reverse();
};
var onlyUnique = function (value, index, array) {
    return array.indexOf(value) === index;
};
export var getCurrentMonthAndFollowingMonths = function () {
    var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ];
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth();
    var allMonths = [];
    for (var i = currentMonth; i < months.length; i++) {
        allMonths.push(months[i]);
    }
    return allMonths;
};
export var convertImageToBase64 = function (file, callback) {
    var reader = new FileReader();
    reader.onloadend = function () {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
};
export var askLocationPermission = function () { return __awaiter(void 0, void 0, void 0, function () {
    var permissionStatus_1, result, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!navigator.permissions || !navigator.permissions.query) {
                    // Permission API is not supported in this browser
                    toast.error('Geolocation Permission API is not supported in this browser!');
                    return [2 /*return*/, false];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 7, , 8]);
                return [4 /*yield*/, navigator.permissions.query({ name: 'geolocation' })];
            case 2:
                permissionStatus_1 = _a.sent();
                if (!(permissionStatus_1.state === 'granted')) return [3 /*break*/, 3];
                // Location permission already granted
                return [2 /*return*/, true];
            case 3:
                if (!(permissionStatus_1.state === 'prompt')) return [3 /*break*/, 5];
                return [4 /*yield*/, new Promise(function (resolve) {
                        permissionStatus_1.onchange = function () { return resolve(permissionStatus_1.state === 'granted'); };
                    })];
            case 4:
                result = _a.sent();
                return [2 /*return*/, result];
            case 5: 
            // Location permission denied
            return [2 /*return*/, false];
            case 6: return [3 /*break*/, 8];
            case 7:
                error_1 = _a.sent();
                // toast.error('Error requesting location permission:', error);
                return [2 /*return*/, false];
            case 8: return [2 /*return*/];
        }
    });
}); };
export var getGeoLocation = function () { return __awaiter(void 0, void 0, void 0, function () {
    var geolocationAPI, coordinates, position, coords, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                geolocationAPI = navigator.geolocation;
                coordinates = { latitude: '', longitude: '' };
                if (!!geolocationAPI) return [3 /*break*/, 1];
                toast.error('Geolocation API is not available in your browser!');
                return [3 /*break*/, 4];
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, new Promise(function (resolve, reject) {
                        geolocationAPI.getCurrentPosition(resolve, reject);
                    })];
            case 2:
                position = _a.sent();
                coords = position.coords;
                coordinates = {
                    latitude: coords.latitude,
                    longitude: coords.longitude
                };
                return [3 /*break*/, 4];
            case 3:
                error_2 = _a.sent();
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/, coordinates];
        }
    });
}); };
export var getIPAddress = function () { return __awaiter(void 0, void 0, void 0, function () {
    var ipAddress;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                ipAddress = '';
                return [4 /*yield*/, fetch('https://api.ipify.org?format=json')
                        .then(function (response) { return response.json(); })
                        .then(function (data) {
                        // Access the IP address from the response
                        ipAddress = data.ip;
                    })
                        .catch(function (error) {
                        toast.error(error);
                    })];
            case 1:
                _a.sent();
                return [2 /*return*/, ipAddress];
        }
    });
}); };
/*
 *   export const encrypt = (plainText:any) => {
 *       let iv:nay = getRandomKey();
 *       let salt = getRandomKey();
 *       var key = generateKey(salt, CONSTANTS?.PASSPHRASE, CONSTANTS?.KEYSIZE, CONSTANTS?.ITERATION_COUNT);
 *       var encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: CryptoJS.enc.Hex.parse(iv) });
 *       var ciphertextBase64 = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
 *       return `${iv}::${salt}::${ciphertextBase64}`;
 *   };
 */
/*
 *  export const decrypt = (cipherText) => {
 *      const [iv, salt, encryptedText] = cipherText.split('::');
 *      var key = generateKey(salt, CONSTANTS?.PASSPHRASE, CONSTANTS?.KEYSIZE, CONSTANTS?.ITERATION_COUNT);
 *      var cipherParams = CryptoJS.lib.CipherParams.create({
 *          ciphertext: CryptoJS.enc.Base64.parse(encryptedText)
 *      });
 *      var decrypted = CryptoJS.AES.decrypt(cipherParams, key, { iv: CryptoJS.enc.Hex.parse(iv) });
 *      return decrypted.toString(CryptoJS.enc.Utf8);
 *  };
 */
export var getValuesInSchema = function (schema, data) {
    var filteredData = {};
    var keys = Object.keys(schema);
    keys.forEach(function (ele) {
        for (var key in data) {
            if (ele === key) {
                filteredData[key] = data[key];
            }
        }
    });
    return filteredData;
};
export var base64ToBlob = function (base64Data, contentType) {
    var byteCharacters = atob(base64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += 512) {
        var slice = byteCharacters.slice(offset, offset + 512);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
};
export var base64UriToFile = function (base64Uri, fileName) {
    // Extract Base64 data from the URI
    var base64Data = base64Uri.split(',')[1];
    // Determine the content type (MIME type)
    var contentType = base64Uri.split(',')[0].match(/:(.*?);/)[1];
    // Convert Base64 data to Blob
    var blob = base64ToBlob(base64Data, contentType);
    // Create a File object
    return new File([blob], fileName, { type: contentType });
};
export var privileges = {
    SA_ROLE_EDITOR: 'SA_ROLE_EDITOR',
    SA_ROLE_READER: 'SA_ROLE_READER',
    SA_USER_EDITOR: 'SA_USER_EDITOR',
    SA_USER_READER: 'SA_USER_READER',
    SA_TENANT_EDITOR: 'SA_TENANT_EDITOR',
    SA_TENANT_READER: 'SA_TENANT_READER'
};
export var getLicenseParams = function (route) {
    var routeArr = route.split('/');
    if (route.includes('license/edit') || route.includes('license/view')) {
        return {
            recordId: routeArr[routeArr.length - 1],
            mode: route.includes('edit') ? 'edit' : 'view',
            tenantId: routeArr[routeArr.length - 4]
        };
    }
    else if (route.endsWith('license')) {
        return { recordId: '', mode: 'add', tenantId: routeArr[routeArr.length - 2] };
    }
    else {
        return { recordId: '', mode: 'add', tenantId: routeArr[routeArr.length - 3] };
    }
};
export var getRandomKey = function () {
    return CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
};
export var generateKey = function (salt, passPhrase, aesKeySize, aesIterationCount) {
    var keySize = aesKeySize / 32;
    var iterationCount = aesIterationCount;
    var key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
        keySize: keySize,
        hasher: CryptoJS.algo.SHA256,
        iterations: iterationCount
    });
    return key;
};
export var encrypt = function (plainText) {
    var iv = getRandomKey();
    var salt = getRandomKey();
    var key = generateKey(salt, CONSTANTS === null || CONSTANTS === void 0 ? void 0 : CONSTANTS.PASSPHRASE, CONSTANTS === null || CONSTANTS === void 0 ? void 0 : CONSTANTS.KEYSIZE, CONSTANTS === null || CONSTANTS === void 0 ? void 0 : CONSTANTS.ITERATION_COUNT);
    var encrypted = CryptoJS.AES.encrypt(JSON.stringify(plainText), key, { iv: CryptoJS.enc.Hex.parse(iv) });
    var encodedCiphertext = encrypted.ciphertext.toString();
    return "".concat(iv, "::").concat(salt, "::").concat(encodedCiphertext);
};
export var decrypt = function (encryptedData) {
    try {
        var _a = encryptedData.split('::'), iv = _a[0], salt = _a[1], encodedCiphertext = _a[2];
        if (!iv || !salt || !encodedCiphertext) {
            throw new Error('Invalid encrypted data format');
        }
        var key = generateKey(salt, CONSTANTS.PASSPHRASE, CONSTANTS.KEYSIZE, CONSTANTS.ITERATION_COUNT);
        // Create a CipherParams object with the base64-encoded ciphertext
        var cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(encodedCiphertext)
        });
        // Decrypt using the created CipherParams
        var decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
            iv: CryptoJS.enc.Hex.parse(iv)
        });
        var decryptedStr = decrypted.toString(CryptoJS.enc.Utf8);
        if (!decryptedStr) {
            throw new Error('Decryption failed');
        }
        return JSON.parse(decryptedStr);
    }
    catch (error) {
        return null;
    }
};
