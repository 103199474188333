import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import withRouter from '../Components/Common/withRouter';
// redux
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
var NonAuthLayout = function (_a) {
    var children = _a.children;
    var nonauthData = createSelector(function (state) { return state.Layout; }, function (layoutModeType) { return layoutModeType.layoutModeType; });
    // Inside your component
    var layoutModeType = useSelector(nonauthData);
    useEffect(function () {
        if (layoutModeType === 'dark') {
            document.body.setAttribute('data-bs-theme', 'dark');
        }
        else {
            document.body.setAttribute('data-bs-theme', 'light');
        }
        return function () {
            document.body.removeAttribute('data-bs-theme');
        };
    }, [layoutModeType]);
    return _jsx("div", { children: children });
};
export default withRouter(NonAuthLayout);
