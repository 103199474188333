var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-depth */
/* eslint-disable max-nested-callbacks */
import Loader from '../../Components/Common/Loader';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import { envVariables, Tooltip } from '../../Components/Common/Util';
import { COUNTRIES } from '../../Components/Common/constants/Countries';
import { APIClient } from '../../helpers/api_helper';
import * as domains from '../../helpers/domain_helper';
import { toastMessages } from '../../Components/Common/ToastMessages';
import * as url from '../../helpers/url_helper';
import { toast } from 'react-toastify';
var AddUsers = function (props) {
    var _a, _b, _c;
    document.title = 'Users';
    var api = new APIClient();
    var envConf = envVariables();
    var _d = useState(props.mode), mode = _d[0], setMode = _d[1];
    var _e = useState(false), loading = _e[0], setLoading = _e[1];
    var _f = useState(__assign({}, props.record)), formValues = _f[0], setFormValues = _f[1];
    /*
     * const [roles, setRoles] = useState([]);
     * let emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
     */
    var _g = useState(''), roleType = _g[0], setRoleType = _g[1];
    var _h = useState([]), roleTypes = _h[0], setRoleTypes = _h[1];
    var userFormData = [
        { label: 'First Name', value: 'firstname', type: 'text', inputType: 'input' },
        { label: 'Last Name', value: 'lastname', type: 'text', inputType: 'input' },
        { label: 'Email ID', value: 'email', type: 'email', inputType: 'input' },
        { label: 'Mobile Number', value: 'phone', type: 'text', inputType: 'input', enableCountry: true, mobileValue: 'countryCode' },
        { label: 'User Name', value: 'username', type: 'text', inputType: 'input' }
    ];
    useEffect(function () {
        setMode(props.mode);
        setLoading(true);
        promiseHandler();
    }, []);
    var promiseHandler = function () {
        var rolesPromise = new Promise(function (resolve, reject) {
            api.get(url.ROLES, { page: 1, size: 10 }, domains.IDM_V1)
                .then(function (resp) {
                resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp === null || resp === void 0 ? void 0 : resp.data)
                    resolve(resp.data);
                else
                    reject('Roles failed.');
            })
                .catch(function (err) { return reject('Roles failed.'); });
        });
        Promise.allSettled([rolesPromise]).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
            var userTypeArr;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                userTypeArr = [];
                if (((_a = result[0]) === null || _a === void 0 ? void 0 : _a.status) === 'fulfilled') {
                    (_c = (_b = result[0]) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.forEach(function (data) {
                        var _a;
                        var obj = { label: data.name, value: data._id };
                        if (props.record.role === obj.value) {
                            setRoleType(obj);
                            props.handleRoleTypeChange(obj);
                        }
                        // if (data.lastmodifiedBy !== 'SYSTEM')
                        if (!((_a = userTypeArr === null || userTypeArr === void 0 ? void 0 : userTypeArr.map(function (val) { return val.label; })) === null || _a === void 0 ? void 0 : _a.includes(data.name)))
                            userTypeArr.push(obj);
                    });
                }
                setRoleTypes(userTypeArr);
                /*
                 * if (result[1]?.status === 'fulfilled') {
                 *     let superParents = [];
                 *     let flatGroupArr = [];
                 *     if (result[1]?.value) {
                 *         let groupsData:any = [];
                 *         let deviceGroups = props.record?.groups ? props.record?.groups : [];
                 *         if (props.record?.deviceGroups) props.handleGroupSelection(deviceGroups);
                 *         await result[1]?.value.forEach(async (data:any) => {
                 *             data.label = data.name;
                 *             data.key = data.code;
                 *             data.checked = deviceGroups.includes(data.code);
                 *             groupsData.push(data);
                 *             if (data.children) {
                 *                 let obj = await setGroupsData(data.children, data, groupsData, deviceGroups);
                 *                 data.children = obj.deviceGroups;
                 *                 groupsData = [...obj.groupsData];
                 *             }
                 *         });
                 *         flatGroupArr = groupsData;
                 *     }
                 *     setFlatGroups(flatGroupArr);
                 *     superParents = noCaseSensitiveSortBy(result[1]?.value, 'label');
                 *     setGroups(superParents);
                 * }
                 */
                setLoading(false);
                return [2 /*return*/];
            });
        }); });
    };
    useEffect(function () {
        if (props.record)
            setFormValues(props.record);
    }, [props.record]);
    var validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: formValues.firstname ? formValues.firstname : '',
            lastname: formValues.lastname ? formValues.lastname : '',
            email: formValues.email ? formValues.email : '',
            username: formValues.username ? formValues.username : '',
            phone: formValues.phone ? formValues.phone : '',
            countryCode: (formValues === null || formValues === void 0 ? void 0 : formValues.countryCode)
                ? formValues === null || formValues === void 0 ? void 0 : formValues.countryCode
                : {
                    name: 'India',
                    code: 'IN',
                    emoji: '🇮🇳',
                    unicode: 'U+1F1EE U+1F1F3',
                    image: 'IN.svg',
                    dial_code: '91',
                    disaplay_dial_code: '+91'
                }
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required('Please Enter Your First Name'),
            lastname: Yup.string().required('Please Enter Your Last Name'),
            email: Yup.string().required('Please Enter Your Email ID')
        }),
        onSubmit: function (values) {
            //
        }
    });
    useEffect(function () {
        props.formErrors(validation.errors);
    }, [validation.errors]);
    useEffect(function () {
        props.saveFormData(validation.values);
    }, [validation.values]);
    var copyToClipboard = function (val) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            navigator.clipboard.writeText(val).then(function () {
                toast.success(toastMessages.emailCopied);
            });
            return [2 /*return*/];
        });
    }); };
    var handlePhoneChange = function (e) {
        var _a;
        var regex = new RegExp(/^[1-9][0-9]{0,9}$/);
        var valid = regex.test(e.target.value);
        if ((valid && ((_a = e.target.value) === null || _a === void 0 ? void 0 : _a.length) <= 10) || e.target.value === '') {
            validation.setValues(__assign(__assign({}, validation.values), { phone: e.target.value }));
        }
    };
    var inputComponent = function (field) {
        var _a, _b;
        return (_jsx("div", { className: "mb-3 mb-lg-0", children: _jsxs(Row, { children: [_jsx(Col, { xs: 12, sm: 12, md: 12, xl: 3, lg: 3, className: "d-flex align-items-center", children: _jsxs(Label, { className: "form-label mb-0 d-flex align-items-center", children: [field.label, field.notMandatory ? (_jsx("span", { className: "ps-1", children: " :" })) : (mode !== 'view' && field.value !== 'email') || mode === 'add' ? (_jsx("span", { className: "text-danger ps-1", children: " *" })) : (_jsx("span", { className: "ps-1", children: " :" }))] }) }), _jsx(Col, { children: _jsxs(Row, { children: [field.enableCountry && mode !== 'view' && (_jsx(Col, { sm: mode === 'view' ? 1 : 2, md: mode === 'view' ? 1 : 4, lg: mode === 'view' ? 1 : 4, xl: mode === 'view' ? 1 : 4, xxl: mode === 'view' ? 1 : 3, children: _jsx(Select, { getOptionValue: function (option) { return option.disaplay_dial_code; }, getOptionLabel: function (option) { return option.disaplay_dial_code; }, formatOptionLabel: function (option) { return (_jsxs("div", { className: "d-flex align-items-center gap-1", children: [_jsx("span", { children: "(".concat(option === null || option === void 0 ? void 0 : option.disaplay_dial_code, ")") }), _jsx("span", { children: "(".concat(option === null || option === void 0 ? void 0 : option.code, ")") })] })); }, isMulti: field.isMulti ? true : false, isClearable: field.isMulti ? true : false, id: field.label, name: field.mobileValue, options: COUNTRIES, placeholder: 'Select', onBlur: function () { return validation.handleBlur({ target: { name: field.value } }); }, onChange: function (selectedOption) {
                                            var _a;
                                            validation.setValues(__assign(__assign({}, validation.values), (_a = {}, _a[field.mobileValue] = selectedOption, _a)));
                                        }, value: (validation === null || validation === void 0 ? void 0 : validation.values[field.mobileValue]) || '', isSearchable: true, isDisabled: (field === null || field === void 0 ? void 0 : field.disable) ? field === null || field === void 0 ? void 0 : field.disable : false, noOptionsMessage: function () { return 'No data found'; } }) })), _jsx(Col, { children: _jsxs("div", { className: "input-group", children: [(mode === 'edit' ? field.value !== 'username' && field.value !== 'email' : mode !== 'view') ? (_jsx(Input, { name: field.value, id: field.label, className: "form-control", placeholder: "Enter ".concat(field.label), type: field.type, validate: { required: { value: true } }, onChange: field.value === 'phone' ? function (e) { return handlePhoneChange(e); } : validation.handleChange, onBlur: validation.handleBlur, value: ((_a = validation.values[field.value]) === null || _a === void 0 ? void 0 : _a.trimStart()) || '', invalid: validation.touched[field.value] && validation.errors[field.value] ? true : false })) : validation.values[field.value] ? (_jsxs("span", { className: "d-flex align-items-center", children: [field.mobileValue
                                                        ? ((_b = validation === null || validation === void 0 ? void 0 : validation.values[field.mobileValue]) === null || _b === void 0 ? void 0 : _b.disaplay_dial_code) +
                                                            '-' +
                                                            validation.values[field.value]
                                                        : validation.values[field.value], field.value === 'email' && (_jsxs("span", { children: [_jsx("i", { id: 'copy', onClick: function () { return copyToClipboard(validation.values[field.value]); }, className: "ri-file-copy-line ms-1 fs-13 cursor-pointer link-primary" }), Tooltip('copy', 'Copy')] }))] })) : ('–'), validation.touched[field.value] && validation.errors[field.value] ? (_jsx(FormFeedback, { type: "invalid", children: validation.errors[field.value] })) : null] }) })] }) })] }) }));
    };
    var handleRoleTypeChange = function (option) {
        setRoleType(option);
        props.handleRoleTypeChange(option);
    };
    return (_jsxs(React.Fragment, { children: [loading && _jsx(Loader, {}), !loading && (_jsx(Form, { className: "h-100", children: _jsx(Col, { xs: 12, sm: 12, md: 12, lg: 12, xl: 12, className: "p-0", children: _jsx("div", { className: "h-100 ", children: _jsxs("div", { className: "mb-3 mb-lg-0 d-flex flex-column gap-4", children: [(userFormData === null || userFormData === void 0 ? void 0 : userFormData.length) > 0 &&
                                    userFormData.map(function (field, index) {
                                        return (_jsx(React.Fragment, { children: field.inputType === 'input' && inputComponent(field) }, index));
                                    }), _jsx("div", { className: "mb-3 mb-lg-0", children: _jsxs(Row, { children: [_jsx(Col, { xs: 12, sm: 12, md: 12, xl: 3, lg: 3, className: "d-flex align-items-center", children: _jsxs(Label, { className: "form-label mb-0 d-flex align-items-center", children: ["Role Type", mode !== 'view' ? (_jsx("span", { className: "text-danger ps-1", children: " *" })) : (_jsx("span", { className: "ps-1", children: " :" }))] }) }), _jsx(Col, { children: _jsx("div", { className: "input-group", children: mode !== 'view' ? (_jsx(Select, { getOptionValue: function (option) { return option.value; }, getOptionLabel: function (option) { return option.label; }, id: "roleType", name: "roleType", className: "w-100", options: roleTypes, placeholder: "Select Role Type", onChange: function (selectedOption) { return handleRoleTypeChange(selectedOption); }, value: roleType || '', isSearchable: true, noOptionsMessage: function () { return 'No data found'; } })) : (roleType === null || roleType === void 0 ? void 0 : roleType.length) > 0 ? ((_c = (_b = (_a = roleType === null || roleType === void 0 ? void 0 : roleType.map(function (v) { return v.label; })) === null || _a === void 0 ? void 0 : _a.toString()) === null || _b === void 0 ? void 0 : _b.split(',')) === null || _c === void 0 ? void 0 : _c.join(', ')) : (roleType === null || roleType === void 0 ? void 0 : roleType.label) ? (roleType === null || roleType === void 0 ? void 0 : roleType.label) : roleType ? (roleType) : ('–') }) })] }) })] }) }) }) }))] }));
};
export default AddUsers;
