var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Select from 'react-select';
import { Col, Label } from 'reactstrap';
var SelectComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    return (_jsx(Col, { xs: ((_a = props.field) === null || _a === void 0 ? void 0 : _a.xs) || 12, sm: ((_b = props.field) === null || _b === void 0 ? void 0 : _b.sm) || 12, md: ((_c = props.field) === null || _c === void 0 ? void 0 : _c.md) || 8, lg: ((_d = props.field) === null || _d === void 0 ? void 0 : _d.lg) || 6, xl: ((_e = props.field) === null || _e === void 0 ? void 0 : _e.xl) || 6, children: _jsxs("div", { className: "mt-2", children: [((_f = props.field) === null || _f === void 0 ? void 0 : _f.label) && (_jsxs(Label, { htmlFor: (_g = props.field) === null || _g === void 0 ? void 0 : _g.value, className: "form-label m-0 ".concat(props.labelCss && props.labelCss), children: [props.field.label, props.mode !== 'view' && _jsxs("span", { className: "text-danger", children: [" ", props.field.mandatory ? '*' : ''] }), props.mode === 'view' && _jsx("span", { className: "ps-1", children: " :" })] })), ((_h = props.field) === null || _h === void 0 ? void 0 : _h.helpText) && _jsx("div", { className: "fs-13 text-muted", children: (_j = props === null || props === void 0 ? void 0 : props.field) === null || _j === void 0 ? void 0 : _j.helpText }), _jsx(Select, { className: "mt-2", styles: {
                        control: function (styles, state) { return (__assign(__assign({}, styles), { backgroundColor: state.selectProps.isDisabled ? '#f2f2f2' : 'white' })); },
                        menu: function (styles) { return (__assign(__assign({}, styles), { backgroundColor: 'white', zIndex: 999999, lineBreak: 'anywhere' })); },
                        option: function (styles, _a) {
                            var isSelected = _a.isSelected;
                            return (__assign(__assign({}, styles), { color: isSelected ? 'white' : '#000000' }));
                        }
                    }, getOptionValue: function (option) { return option === null || option === void 0 ? void 0 : option.value; }, getOptionLabel: function (option) { return option === null || option === void 0 ? void 0 : option.label; }, autoFocus: false, isDisabled: props.mode === 'view'
                        ? true
                        : props.field.disableWhileEdit
                            ? props.mode === 'edit' && props.field.disableWhileEdit
                            : (_k = props.field) === null || _k === void 0 ? void 0 : _k.disabled, closeMenuOnSelect: !((_l = props.field) === null || _l === void 0 ? void 0 : _l.isMulti), isMulti: props.field.isMulti ? true : false, isClearable: props.field.isMulti ? true : false, id: props.field.label, name: props.field.value, options: props.field.options, placeholder: "".concat('Select', " ").concat(props.field.label), onBlur: function () { return props.validation.handleBlur({ target: { name: props.field.value } }); }, onChange: function (selectedOption) {
                        if (props === null || props === void 0 ? void 0 : props.onChange) {
                            props === null || props === void 0 ? void 0 : props.onChange({ target: { name: props.field.value, value: selectedOption } });
                        }
                        props.validation.handleChange({ target: { name: props.field.value, value: selectedOption } });
                    }, value: ((_m = props.validation) === null || _m === void 0 ? void 0 : _m.values[props.field.value]) || '', isSearchable: true, noOptionsMessage: function () { return 'No data found'; }, classNamePrefix: "".concat(props.validation.touched[(_o = props.field) === null || _o === void 0 ? void 0 : _o.value] && props.validation.errors[(_p = props.field) === null || _p === void 0 ? void 0 : _p.value]
                        ? 'border-danger border-rounded-2'
                        : null) }), props.validation.touched[(_q = props.field) === null || _q === void 0 ? void 0 : _q.value] && props.validation.errors[(_r = props.field) === null || _r === void 0 ? void 0 : _r.value] ? (_jsx("p", { className: "text-danger fs-12 pt-1", children: props.validation.errors[(_s = props.field) === null || _s === void 0 ? void 0 : _s.value] })) : null] }) }));
};
export default SelectComponent;
