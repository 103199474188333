import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
var FullScreenDropdown = function () {
    var _a = useState(true), isFullScreenMode = _a[0], setIsFullScreenMode = _a[1];
    var toggleFullscreen = function () {
        var document = window.document;
        document.body.classList.add('fullscreen-enable');
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
            setIsFullScreenMode(false);
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            }
            else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            }
            else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            }
        }
        else {
            setIsFullScreenMode(true);
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            }
            else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            }
            else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
        var exitHandler = function () {
            if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement)
                document.body.classList.remove('fullscreen-enable');
        };
        document.addEventListener('fullscreenchange', exitHandler);
        document.addEventListener('webkitfullscreenchange', exitHandler);
        document.addEventListener('mozfullscreenchange', exitHandler);
    };
    return (_jsx(_Fragment, { children: _jsx("div", { className: "ms-1 header-item d-none d-sm-flex", children: _jsx("button", { onClick: toggleFullscreen, type: "button", className: "btn btn-icon btn-topbar btn-ghost-secondary rounded-circle", children: _jsx("i", { className: isFullScreenMode ? 'ri-fullscreen-line fs-22' : 'ri-fullscreen-exit-line fs-22' }) }) }) }));
};
export default FullScreenDropdown;
