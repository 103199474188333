var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { APIClient, setAuthorization } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { loginUser } from '../../../slices/thunks';
import { AuthUser, envVariables } from '../Util';
var Error401 = function () {
    document.title = '401 Error';
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var envConf = envVariables();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var user = AuthUser();
    var api = new APIClient();
    useEffect(function () {
        var _a, _b;
        var userObj = user ? JSON.parse(JSON.stringify(user)) : {};
        var refreshToken = ((_a = userObj === null || userObj === void 0 ? void 0 : userObj.data) === null || _a === void 0 ? void 0 : _a.refreshToken) ? (_b = userObj === null || userObj === void 0 ? void 0 : userObj.data) === null || _b === void 0 ? void 0 : _b.refreshToken : '';
        if (refreshToken) {
            setAuthorization(refreshToken);
            setLoading(true);
            api.get(url.REFRESH_TOKEN, '', domains.IDM_V1)
                .then(function (resp) {
                var _a, _b;
                resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    setAuthorization((_a = resp === null || resp === void 0 ? void 0 : resp.data) === null || _a === void 0 ? void 0 : _a.token);
                    userObj = __assign(__assign({}, userObj), { data: __assign(__assign({}, userObj.data), { token: (_b = resp === null || resp === void 0 ? void 0 : resp.data) === null || _b === void 0 ? void 0 : _b.token }) });
                    dispatch(loginUser(userObj, navigate));
                    var route = localStorage.getItem('route401');
                    localStorage.removeItem('authObj');
                    localStorage.removeItem('route401');
                    navigate(route);
                }
                setLoading(false);
            })
                .catch(function (err) {
                setLoading(false);
                localStorage.clear();
                sessionStorage.clear();
                window.location.href = window.location.origin + '/#/login';
            });
        }
    }, []);
    var backToLogin = function () {
        localStorage.clear();
        sessionStorage.clear();
        navigate('/login');
    };
    return (_jsx(React.Fragment, { children: _jsxs("div", { className: "auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100", children: [_jsx("div", { className: "bg-overlay" }), _jsx("div", { className: "auth-page-content overflow-hidden pt-lg-5", children: _jsx(Container, { children: _jsx(Row, { className: "justify-content-center", children: _jsx(Col, { xl: 5, children: _jsx(Card, { className: "overflow-hidden", children: _jsx(CardBody, { className: "p-4", children: _jsxs("div", { className: "text-center", children: [_jsx("h1", { className: "text-primary mb-4", children: "Oops !" }), _jsx("h4", { className: "text-uppercase", children: "Sorry, Invalid Credentials" }), _jsx("p", { className: "text-muted mb-4", children: "The page you are looking for is not available" }), !loading && (_jsx("button", { type: "button", onClick: backToLogin, className: "btn btn-success", children: "Back to Login" }))] }) }) }) }) }) }) })] }) }));
};
export default Error401;
