var options = {
    AE_DASHBOARD_WIDGETS: [
        { label: 'Device Status Cards', value: 'dashboard_devices' },
        { label: 'Device Online Summary', value: 'dashboard_device_online_summary' },
        { label: 'OS Distribution', value: 'dashboard_os_distribution' },
        { label: 'Device Distribution', value: 'dashboard_device_distribution' },
        { label: 'Apps', value: 'dashboard_apps' },
        { label: 'Device Count by Supplier', value: 'supplier_count' },
        { label: 'Power ON Devices (Post Enrolment)', value: 'power_on_status' },
        { label: 'Top (5) Most Used Apps', value: 'most_used_apps' },
        { label: 'Login & Logout History', value: 'login_history' }
    ],
    AE_FILTER_PANELS: [
        { label: 'App Status', value: 'appStatusArr' },
        { label: 'Device Status', value: 'device_status' },
        { label: 'Groups', value: 'groups' },
        { label: 'Online Status', value: 'online_status' },
        { label: 'Policies', value: 'policies' },
        { label: 'Violations', value: 'violations' }
    ],
    AE_TABLE_COLUMNS: [
        { label: 'Android Version', value: 'Android Version' },
        { label: 'Battery', value: 'Battery' },
        { label: 'Device Status', value: 'Device Status' },
        { label: 'Group', value: 'Group' },
        { label: 'IMEI', value: 'IMEI' },
        { label: 'Last Contact', value: 'Last Contact' },
        { label: 'User', value: 'User' },
        { label: 'Policy Version', value: 'Policy Version' },
        { label: 'Policy View', value: 'Policy View' },
        { label: 'Sequence', value: 'Sequence' },
        { label: 'Username', value: 'Username' },
        { label: 'Manufacturer', value: 'Manufacturer' },
        { label: 'Violations', value: 'Violations' },
        { label: 'Custom Field', value: 'Custom Field' }
    ],
    AE_ACTIONS: [
        { label: 'Antivirus', value: 'ANTIVIRUS' },
        { label: 'App Usage', value: 'APP_USAGE' },
        { label: 'Camera', value: 'CAMERA' },
        { label: 'Factory Mode', value: 'FACTORY_MODE' },
        { label: 'Factory Reset', value: 'FACTORY_RESET' },
        { label: 'Geofence', value: 'GEOFENCE' },
        { label: 'Kiosk Exit QR', value: 'KIOSK_EXIT' },
        { label: 'Launcher Password', value: 'LAUNCHER_PASSWORD' },
        { label: 'Location History', value: 'LOCATION_HISTORY' },
        { label: 'Lock', value: 'LOCK' },
        { label: 'Lost mode', value: 'LOST_MODE' },
        { label: 'Map View', value: 'MAP_VIEW' },
        { label: 'Reboot', value: 'REBOOT' },
        { label: 'Refresh', value: 'REFRESH' },
        { label: 'Remote', value: 'REMOTE' },
        { label: 'Reset Password', value: 'RESET_PASSWORD' },
        { label: 'Retire', value: 'RETIRE' },
        { label: 'Scream', value: 'SCREAM' },
        { label: 'Violations', value: 'VIOLATIONS' }
    ],
    AE_CONFIGURATIONS: [
        { label: 'Enable Call Logs', value: 'enable_call_logs_config' },
        { label: 'Enable App Usage Report', value: 'enable_app_usage_report_config' },
        { label: 'Enable Violations', value: 'enable_violations_cofig' },
        { label: 'Enable Location History', value: 'enable_location_history_config' },
        { label: 'Enable SIM Operators', value: 'enable_sim_operators_config' },
        { label: 'Enable Battery Percentage', value: 'enable_battery_config' },
        { label: 'Enable Last Modified Date', value: 'enable_last_modified_date_config' },
        { label: 'Enable Personal Account', value: 'enable_personal_account_config' },
        { label: 'Enable Wallpaper', value: 'enable_wallpaper_config' },
        { label: 'Enable Lock Screen Wallpaper', value: 'enable_lock_screen_wallpaper' },
        { label: 'Enable Boot Animation', value: 'enable_boot_animation_config' },
        { label: 'Enable Shutdown Logo', value: 'enable_shutdown_logo' }
    ],
    AE_POL_TYPES: [
        { label: 'Fully Managed Device', value: 'FM' },
        { label: 'Work Profile', value: 'WP' },
        { label: 'Dedicated Device', value: 'DD' }
    ],
    AE_SUB_POL_TYPES: [
        { label: 'Single APP', value: 'SA' },
        { label: 'Kiosk Launcher', value: 'KL' },
        { label: 'Tectoro Launcher', value: 'TL' }
    ],
    AE_REPORTS: [
        { label: 'Call logs', value: 'call_logs_reports' },
        { label: 'All User Activity', value: 'user_activity_report' },
        { label: 'App Versions Report', value: 'app_install' },
        { label: 'Device Power Status', value: 'device_power_status' },
        { label: 'Geo Fence', value: 'geo_fence_mobile' }
    ],
    AE_BULK_REQUESTS: [
        { label: 'Add/Update Notes on Device', value: 'DeviceNotes' },
        { label: 'Bulk upload Devices', value: 'deviceUpload' },
        { label: 'Device Factory Reset', value: 'DeviceReset' },
        { label: 'Lock Device', value: 'DeviceLock' },
        { label: 'Reboot Device', value: 'DeviceReboot' },
        { label: 'Retire Device', value: 'DeviceRetire' },
        { label: 'Update Device Group on Device', value: 'DeviceGroup' },
        { label: 'Update Policy on Device', value: 'DevicePolicy' },
        { label: 'Clear Cache', value: 'deviceclearcache' },
        { label: 'Reset Device Password', value: 'Deviceresetpassword' }
    ],
    IOS_WIDGETS: [
        { label: 'Device Status Cards', value: 'dashboard_devices' },
        { label: 'OS Distribution', value: 'dashboard_os_distribution' },
        { label: 'Supervised & UnSupervised Devices', value: 'super_unSuper_devices' },
        { label: 'Device Type', value: 'device_type' }
    ],
    IOS_FILTER_PANELS: [
        { label: 'Device Status', value: 'device_status' },
        { label: 'Groups', value: 'groups' },
        { label: 'Policies', value: 'policies' }
    ],
    IOS_ACTIONS: [
        { label: 'Reboot', value: 'REBOOT' },
        { label: 'Lock', value: 'LOCK' },
        { label: 'Shutdown', value: 'SHUTDOWN' },
        { label: 'Lost Mode Sound', value: 'LOST_MODE_SOUND' },
        { label: 'Lost Mode', value: 'LOST_MODE' },
        { label: 'App Install', value: 'APP_INSTALL' },
        { label: 'App Uninstall', value: 'APP_UNINSTALL' },
        { label: 'Wallpaper', value: 'SET_WALLPAPER' },
        { label: 'Activation Lock Bypass Code', value: 'ACTIVATION_LOCK' },
        { label: 'Erase Device', value: 'ERASE_DEVICE' },
        { label: 'Refresh', value: 'REFRESH' },
        { label: 'Sync Policy', value: 'SYNC_POLICY' }
    ],
    IOS_POL_TYPES: [
        { label: 'iOS', value: 'ios' },
        { label: 'macOS', value: 'macos' }
    ],
    IOS_SUB_POL_TYPES: [
        { label: 'Single App', value: 'SA' },
        { label: 'Multi App', value: 'MA' }
    ],
    WINDOWS_WIDGETS: [
        { label: 'Device Status Cards', value: 'dashboard_devices' },
        { label: 'OS Distribution', value: 'dashboard_os_distribution' },
        { label: 'Device Type', value: 'device_type' }
    ],
    WINDOWS_FILTER_PANEL: [
        { label: 'Device Status', value: 'device_status' },
        { label: 'Groups', value: 'groups' },
        { label: 'Policies', value: 'policies' }
    ],
    WINDOWS_ACTIONS: [
        { label: 'Reboot', value: 'REBOOT' },
        { label: 'Erase Device', value: 'ERASE_DEVICE' },
        { label: 'Sync', value: 'SYNC' },
        { label: 'Refresh', value: 'REFRESH' },
        { label: 'Unenroll Device', value: 'UNENROLL_DEVICE' },
        { label: 'Map View', value: 'MAP_VIEW' },
        { label: 'Add User', value: 'ADD_USER' },
        { label: 'Lock', value: 'LOCK' },
        { label: 'Scream', value: 'SCREAM' },
        { label: 'Shutdown', value: 'SHUTDOWN' },
        { label: 'Change Password', value: 'CHANGE_PASSWORD' },
        { label: 'Change Device Name', value: 'CHANGE_DEVICE_NAME' },
        { label: 'Remove Device User', value: 'REMOVE_DEVICE_USER' }
    ],
    WINDOWS_POLICY_TYPES: [
        { label: 'Fully Managed Device', value: 'FM' },
        { label: 'Kiosk Launcher', value: 'KL' }
    ],
    TV_WIDGETS: [
        { label: 'Device Status Cards', value: 'dashboard_devices' },
        { label: 'Device Online Summary', value: 'dashboard_device_online_summary' },
        { label: 'OS Distribution', value: 'dashboard_os_distribution' },
        { label: 'Device Distribution', value: 'dashboard_device_distribution' }
    ],
    TV_ACTIONS: [
        { label: 'App Usage', value: 'APP_USAGE' },
        { label: 'Factory Reset', value: 'FACTORY_RESET' },
        { label: 'Geofence', value: 'GEOFENCE' },
        { label: 'Launcher Password', value: 'LAUNCHER_PASSWORD' },
        { label: 'Location History', value: 'LOCATION_HISTORY' },
        { label: 'Lock', value: 'LOCK' },
        { label: 'Map View', value: 'MAP_VIEW' },
        { label: 'Refresh', value: 'REFRESH' },
        { label: 'Reboot', value: 'REBOOT' },
        { label: 'Volume', value: 'VOLUME' },
        { label: 'Power Controls', value: 'POWER_CONTROLS' },
        { label: 'Input Source', value: 'INPUT_METHOD' },
        { label: 'Shutdown', value: 'SHUTDOWN' }
    ],
    TV_BULK_REQUEST_TYPES: [
        { label: 'Bulk upload Android Tv', value: 'androidTv' },
        { label: 'Upload Teachers Data', value: 'teacherData' }
    ],
    TV_REPORTS: [
        { label: 'Teachers Sessions', value: 'teachers_audit' },
        { label: 'Geo Fence', value: 'geo_fence_tv' },
        { label: 'App Installation', value: 'tv_app_installation' },
        { label: 'Error Devices', value: 'tv_error_devices' },
        { label: 'Power Status', value: 'tv_power_status' }
    ],
    ADMIN_REPORTS: [{ label: 'Login history', value: 'login_history' }]
};
export default options;
