var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from '../../Components/Common/Loader';
import OffcanvasModal from '../../Components/Common/OffcanvasModal';
import TableContainer from '../../Components/Common/TableContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import { privilegesSchema, rolesSchema } from './RolesSchemas';
import DeleteModal from '../../Components/Common/DeleteModal';
import { AuthUser, convertTextCase, envVariables, getDate, privileges as privs } from '../../Components/Common/Util';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import * as domains from '../../helpers/domain_helper';
import { toastMessages } from '../../Components/Common/ToastMessages';
import { toast } from 'react-toastify';
var Roles = function () {
    document.title = 'Roles';
    var envConf = envVariables();
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var searchParams = { page: 1, size: 100 };
    var _b = useState(''), searchVal = _b[0], setSearchVal = _b[1];
    var _c = useState([]), priviligesBackup = _c[0], setPriviligesBackup = _c[1];
    var api = new APIClient();
    var _d = useState([]), roles = _d[0], setRoles = _d[1];
    var _e = useState(), selectedRole = _e[0], setSelectedRole = _e[1];
    var _f = useState(false), showAddRoleModal = _f[0], setShowAddRoleModal = _f[1];
    var _g = useState('add'), mode = _g[0], setMode = _g[1];
    var _h = useState(''), roleName = _h[0], setRoleName = _h[1];
    var _j = useState(false), deleteModal = _j[0], setDeleteModal = _j[1];
    var _k = useState([]), priviliges = _k[0], setPriviliges = _k[1];
    var _l = useState(false), checkAll = _l[0], setCheckAll = _l[1];
    var _m = useState(false), checkAllReaders = _m[0], setCheckAllReaders = _m[1];
    var _o = useState(false), checkAllEditors = _o[0], setCheckAllEditors = _o[1];
    var _p = useState(1), pageNumber = _p[0], setPageNumber = _p[1];
    var _q = useState(rolesSchema), schema = _q[0], setSchema = _q[1];
    var _r = useState([]), rolesBackup = _r[0], setRolesBackup = _r[1];
    useEffect(function () {
        var _a, _b;
        var user = AuthUser();
        var findPriv = (_b = (_a = user.data) === null || _a === void 0 ? void 0 : _a.privileges) === null || _b === void 0 ? void 0 : _b.includes(privs.SA_ROLE_EDITOR);
        var schemaArr = JSON.parse(JSON.stringify(rolesSchema));
        if (!findPriv)
            schemaArr = schemaArr.filter(function (obj) { return obj.Header !== 'Actions'; });
        setSchema(schemaArr);
        handlePromise(__assign({}, searchParams));
    }, []);
    var handlePromise = function (params) {
        setLoading(true);
        var getRoles = new Promise(function (resolve, reject) {
            setLoading(true);
            api.get(url.ROLES, params, domains.IDM_V1)
                .then(function (resp) {
                resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setSearchVal('');
                if ((resp === null || resp === void 0 ? void 0 : resp.status) === 'success')
                    resolve(resp === null || resp === void 0 ? void 0 : resp.data);
                reject('Roles failed.');
            })
                .catch(function (err) { return reject('Roles failed.'); });
        });
        var getPriviliges = new Promise(function (resolve, reject) {
            api.get(url.ADMIN_PRIVILEGES, '', domains.IDM_V1)
                .then(function (resp) {
                resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success')
                    resolve(resp.data);
                else
                    reject('privillages failsed');
            })
                .catch(function (err) { return reject('Roles failed.'); });
        });
        Promise.allSettled([getRoles, getPriviliges]).then(function (result) {
            var _a, _b, _c, _d;
            setLoading(false);
            if (((_a = result[0]) === null || _a === void 0 ? void 0 : _a.status) === 'fulfilled') {
                (_b = result[0].value) === null || _b === void 0 ? void 0 : _b.forEach(function (data) {
                    var _a;
                    data.roleId = data._id;
                    data.doNotDelete = data.exists || data.issystemrole;
                    data.privilegesLength = (_a = data.privileges) === null || _a === void 0 ? void 0 : _a.split(',').length;
                    data.modifiedBy = data.modifiedby;
                    data.modifiedDate = data.modifieddate ? getDate(data.modifieddate, true) : '—';
                });
                setRoles(result[0].value);
                setRolesBackup(result[0].value);
            }
            if (((_c = result[1]) === null || _c === void 0 ? void 0 : _c.status) === 'fulfilled') {
                var priviligesArray_1 = [];
                if (((_d = result[1].value) === null || _d === void 0 ? void 0 : _d.length) > 0) {
                    /*
                     * resp.data.push(privsArr.TENANTMASTER_EDITOR);
                     * resp.data.push(privsArr.TENANTMASTER_READER);
                     */
                    var priviligesArr_1 = result[1].value;
                    var sortArr = priviligesArr_1.sort();
                    sortArr.forEach(function (item, index) {
                        var label = item === null || item === void 0 ? void 0 : item.split('_');
                        var priviligeLabel = convertTextCase(label === null || label === void 0 ? void 0 : label[1], true);
                        // eslint-disable-next-line max-nested-callbacks
                        var found = priviligesArray_1.find(function (privilige) { return privilige.label === priviligeLabel; });
                        if (found) {
                            var obj = __assign(__assign({}, found), { editor: false, reader: false, all: false });
                            if (label[label.length - 1] === 'EDITOR')
                                obj.editorPriv = item;
                            else if (label[label.length - 1] === 'READER')
                                obj.readerPriv = item;
                            else
                                obj.readerPriv = item;
                            obj.anyPriv = obj.readerPriv ? obj.readerPriv : obj.editorPriv;
                            // eslint-disable-next-line max-nested-callbacks
                            var objInd = priviligesArr_1.findIndex(function (priv) { return priv.label === priviligeLabel; });
                            priviligesArray_1.splice(objInd, 1, obj);
                        }
                        else {
                            var obj = { label: priviligeLabel, index: index };
                            if (label[label.length - 1] === 'EDITOR')
                                obj.editorPriv = item;
                            else if (label[label.length - 1] === 'READER')
                                obj.readerPriv = item;
                            else
                                obj.readerPriv = item;
                            obj.anyPriv = obj.readerPriv ? obj.readerPriv : obj.editorPriv;
                            priviligesArray_1.push(obj);
                        }
                    });
                }
                var privLabel_1 = [];
                priviligesArray_1.forEach(function (field) {
                    if (field.label !== 'Tickets')
                        privLabel_1.push(field);
                });
                setPriviligesBackup(JSON.parse(JSON.stringify(privLabel_1)));
                setPriviliges(privLabel_1);
            }
        });
    };
    var handleAddNew = function () {
        setMode('add');
        setSelectedRole('');
        setShowAddRoleModal(true);
    };
    var handleEdit = function (row) {
        setSelectedRole(row.original);
        setRoleName(row.original.name);
        var privArr = JSON.parse(JSON.stringify(priviligesBackup));
        if ((privArr === null || privArr === void 0 ? void 0 : privArr.length) > 0) {
            privArr.forEach(function (a) {
                var _a, _b, _c;
                (_c = (_b = (_a = row.original) === null || _a === void 0 ? void 0 : _a.privileges) === null || _b === void 0 ? void 0 : _b.split(',')) === null || _c === void 0 ? void 0 : _c.forEach(function (b) {
                    if (a.editorPriv === b)
                        a.editor = true;
                    else if (a.readerPriv === b)
                        a.reader = true;
                    a.all = (a.editorPriv ? a.editor : true) && (a.readerPriv ? a.reader : true);
                });
            });
        }
        var editor = true;
        var reader = true;
        privArr.forEach(function (role) {
            if (role.editorPriv && !role.editor)
                editor = false;
            if (role.readerPriv && !role.reader)
                reader = false;
        });
        setPriviliges(privArr);
        setCheckAllEditors(editor);
        setCheckAllReaders(reader);
        setCheckAll(editor && reader);
        setMode('edit');
        setShowAddRoleModal(true);
    };
    var handlePriviligeView = function (row) {
        setSelectedRole(row.original);
        setRoleName(row.original.name);
        var privArr = JSON.parse(JSON.stringify(priviligesBackup));
        if ((privArr === null || privArr === void 0 ? void 0 : privArr.length) > 0) {
            privArr.forEach(function (a) {
                var _a, _b, _c;
                (_c = (_b = (_a = row.original) === null || _a === void 0 ? void 0 : _a.privileges) === null || _b === void 0 ? void 0 : _b.split(',')) === null || _c === void 0 ? void 0 : _c.forEach(function (b) {
                    if (a.editorPriv === b)
                        a.editor = true;
                    else if (a.readerPriv === b)
                        a.reader = true;
                    a.all = (a.editorPriv ? a.editor : true) && (a.readerPriv ? a.reader : true);
                });
            });
        }
        var editor = true;
        var reader = true;
        privArr.forEach(function (role) {
            if (role.editorPriv && !role.editor)
                editor = false;
            if (role.readerPriv && !role.reader)
                reader = false;
        });
        setPriviliges(privArr);
        setCheckAllEditors(editor);
        setCheckAllReaders(reader);
        setCheckAll(editor && reader);
        setMode('view');
        setShowAddRoleModal(true);
    };
    var handleDelete = function (row) {
        setSelectedRole(row.original);
        setDeleteModal(true);
    };
    var toggle = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setRoleName('');
            setPriviliges(JSON.parse(JSON.stringify(priviligesBackup)));
            setCheckAllEditors(false);
            setCheckAllReaders(false);
            setCheckAll(false);
            setShowAddRoleModal(!showAddRoleModal);
            return [2 /*return*/];
        });
    }); }, [showAddRoleModal]);
    var saveDisabled = function () {
        var privss = priviliges === null || priviliges === void 0 ? void 0 : priviliges.filter(function (role) { return (role.editorPriv && role.editor) || (role.readerPriv && role.reader); });
        return !roleName || privss.length === 0;
    };
    var handleCheckboxChange = function (e, _index, row, cell) {
        var priviligesArr = __spreadArray([], priviliges, true);
        priviligesArr.forEach(function (privilige) {
            var _a;
            if (((_a = row.original) === null || _a === void 0 ? void 0 : _a.anyPriv) === privilige.anyPriv) {
                privilige[cell.column.id] = e.target.checked;
                if (cell.column.id === 'all') {
                    privilige.reader = privilige.readerPriv && e.target.checked;
                    privilige.editor = privilige.editorPriv && e.target.checked;
                }
                else {
                    privilige.all = (privilige.editorPriv ? privilige.editor : true) && (privilige.readerPriv ? privilige.reader : true);
                }
            }
        });
        var editor = true;
        var reader = true;
        priviligesArr.forEach(function (privilige) {
            if (privilige.editorPriv && !privilige.editor)
                editor = false;
            if (privilige.readerPriv && !privilige.reader)
                reader = false;
        });
        setCheckAllReaders(reader);
        setCheckAllEditors(editor);
        setCheckAll(reader && editor);
        setPriviliges(__spreadArray([], priviligesArr, true));
    };
    var handleCheckAll = function (e, column) {
        if (column.id === 'all') {
            var priviligeArr = __spreadArray([], priviliges, true);
            priviligeArr.forEach(function (privilige) {
                if (privilige.editorPriv)
                    privilige.editor = e.target.checked;
                if (privilige.readerPriv)
                    privilige.reader = e.target.checked;
                privilige.all = e.target.checked;
            });
            setPriviliges(__spreadArray([], priviligeArr, true));
            setCheckAll(e.target.checked);
            setCheckAllEditors(e.target.checked);
            setCheckAllReaders(e.target.checked);
        }
        else if (column.id === 'editor') {
            var priviligesArr = __spreadArray([], priviliges, true);
            priviligesArr.forEach(function (privilige) {
                if (privilige.editorPriv)
                    privilige.editor = e.target.checked;
                privilige.all = (privilige.readerPriv ? privilige.reader : true) && e.target.checked;
            });
            var found_1 = false;
            priviligesArr.forEach(function (privilige) {
                if ((privilige.editorPriv ? !privilige.editor : false) || (privilige.readerPriv ? !privilige.reader : false)) {
                    privilige.all = false;
                    found_1 = true;
                }
            });
            setCheckAll(!found_1);
            setPriviliges(__spreadArray([], priviligesArr, true));
            setCheckAllEditors(e.target.checked);
        }
        else if (column.id === 'reader') {
            var priviligesArr = __spreadArray([], priviliges, true);
            priviligesArr.forEach(function (privilige) {
                if (privilige.readerPriv)
                    privilige.reader = e.target.checked;
                privilige.all = (privilige.editorPriv ? privilige.editor : true) && e.target.checked;
            });
            var found_2 = false;
            priviligesArr.forEach(function (privilige) {
                if ((privilige.editorPriv ? !privilige.editor : false) || (privilige.readerPriv ? !privilige.reader : false)) {
                    privilige.all = false;
                    found_2 = true;
                }
            });
            setCheckAll(!found_2);
            setPriviliges(__spreadArray([], priviligesArr, true));
            setCheckAllReaders(e.target.checked);
        }
    };
    var onPageChange = function (pgNum) {
        setPageNumber(pgNum);
    };
    var handleOffcanvasBody = function () {
        return (_jsxs(React.Fragment, { children: [_jsxs(Row, { className: "m-3", children: [_jsx(Col, { xs: 12, sm: 12, md: 12, xl: 3, lg: 3, className: "d-flex align-items-center", children: _jsxs(Label, { className: "form-label d-flex align-items-center", children: ["Role Name", mode === 'add' ? _jsx("span", { className: "text-danger ps-1", children: " *" }) : _jsx("span", { className: "ps-1", children: " :" })] }) }), _jsx(Col, { children: _jsx("div", { className: "input-group", children: mode === 'add' ? (_jsx(Input, { name: 'roleName', id: 'roleName', className: "form-control", placeholder: 'Enter Role Name', type: 'text', maxLength: 20, validate: { required: { value: true } }, onChange: function (e) { return setRoleName(e.target.value); }, value: roleName || '' })) : roleName ? (_jsx("span", { className: "d-flex align-items-center", children: roleName })) : ('–') }) })] }), _jsx(Row, { children: _jsx(Col, { xl: 12, children: _jsx(TableContainer, { loading: loading, isGlobalFilter: false, columns: privilegesSchema, data: priviliges || [], mode: mode, all: checkAll, write: checkAllEditors, read: checkAllReaders, hideDisplayTotalCount: true, hideCheckBoxTooltip: true, pageNum: pageNumber, hidePagination: true, customPageSize: 100, handleCheckAll: handleCheckAll, onPageChange: onPageChange, handleCheckboxChange: handleCheckboxChange, className: "custom-header-css", divClass: "table-responsive table-card rounded", tableClass: "table table-nowrap table-border table-centered align-middle privilege-table mb-0", theadClass: "bg-light text-muted" }) }) })] }));
    };
    var checkPrivilegeChange = function (privss) {
        var _a;
        var currentPrivs = (_a = selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.privileges) === null || _a === void 0 ? void 0 : _a.split(',');
        currentPrivs = currentPrivs.sort();
        var newPrivs = privss === null || privss === void 0 ? void 0 : privss.split(',');
        newPrivs = newPrivs === null || newPrivs === void 0 ? void 0 : newPrivs.sort();
        if ((newPrivs === null || newPrivs === void 0 ? void 0 : newPrivs.join(',')) === (currentPrivs === null || currentPrivs === void 0 ? void 0 : currentPrivs.join(',')))
            return undefined;
        return privss;
    };
    var handleSubmit = function () {
        if ((roleName === null || roleName === void 0 ? void 0 : roleName.length) > 20) {
            // toast.error(toastMessages.roleNameLengthExceeded);
        }
        setLoading(true);
        var selectedRoles = [];
        priviliges === null || priviliges === void 0 ? void 0 : priviliges.forEach(function (role) {
            if (role.editorPriv && (role === null || role === void 0 ? void 0 : role.editor))
                selectedRoles.push(role.editorPriv);
            if (role.readerPriv && role.reader)
                selectedRoles.push(role.readerPriv);
        });
        setCheckAllEditors(false);
        setCheckAllReaders(false);
        setCheckAll(false);
        var dataObj = { name: roleName, privileges: selectedRoles === null || selectedRoles === void 0 ? void 0 : selectedRoles.join(',') };
        createNdUpdate(dataObj);
    };
    var createNdUpdate = function (dataObj) {
        var serviceCall;
        if (mode === 'edit') {
            var params = {
                name: selectedRole.name === roleName ? undefined : roleName,
                privileges: checkPrivilegeChange(dataObj.privileges)
            };
            params = JSON.parse(JSON.stringify(params));
            serviceCall = api.update(url.ROLES + '/' + selectedRole._id, params, domains.IDM_V1);
        }
        else
            serviceCall = api.create(url.ROLES, dataObj, domains.IDM_V1);
        serviceCall
            .then(function (resp) {
            resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status === 'success') {
                toast.success(mode === 'edit' ? toastMessages.roleUpdated : toastMessages.roleCreated);
                setShowAddRoleModal(false);
                setRoleName('');
                setPriviliges(JSON.parse(JSON.stringify(priviligesBackup)));
                setSelectedRole('');
                setMode('add');
                handlePromise(searchParams);
            }
            setLoading(false);
        })
            .catch(function (_err) { return setLoading(false); });
    };
    var handleDeleteConfirmation = function () {
        setDeleteModal(false);
        setLoading(true);
        api.delete(url.ROLES + '/' + (selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole._id), undefined, domains.IDM_V1)
            .then(function (resp) {
            resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
            if (resp.status === 'success') {
                toast.success(toastMessages.roleDelete);
                handlePromise(searchParams);
            }
            setSelectedRole('');
            setLoading(false);
        })
            .catch(function (err) {
            setSelectedRole('');
            setLoading(false);
        });
    };
    var handleGlobalSearch = function (val) {
        var filteredRoles = __spreadArray([], rolesBackup, true);
        if ((val === null || val === void 0 ? void 0 : val.length) > 0) {
            filteredRoles = rolesBackup.filter(function (user) { var _a; return (_a = user.name) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(val === null || val === void 0 ? void 0 : val.toLowerCase()); });
            setRoles(filteredRoles);
            setSearchVal(val);
        }
        else
            handlePromise(searchParams);
    };
    return (_jsxs(React.Fragment, { children: [loading && _jsx(Loader, {}), _jsx("div", { className: "page-content h-100 ".concat(loading ? 'postion-relative mask' : ''), children: _jsxs(Container, { fluid: true, children: [_jsx(BreadCrumb, { title: "Roles", backLink: "" }), _jsx(TableContainer, { loading: loading, tableHeader: 'All Roles', isGlobalFilter: true, addButton: true, customPageSize: 10, searchVal: searchVal, largeSearchFilter: true, SearchFilterWidth: 'width-280', searchFilter2: true, searchPlaceHolder: 'Role Name', addButtonText: 'Add Role', columns: schema, handleAddNew: handleAddNew, handleEdit: handleEdit, handlePriviligeView: handlePriviligeView, handleDelete: handleDelete, handleGlobalSearch: handleGlobalSearch, data: roles, editor: privs.SA_ROLE_EDITOR, reader: privs.SA_ROLE_READER, className: "custom-header-css", divClass: "table-responsive table-card", tableClass: "table table-nowrap table-border table-centered align-middle", theadClass: "bg-light text-muted" }), _jsx(OffcanvasModal, { direction: "end", toggle: toggle, loading: loading, open: showAddRoleModal, handleCloseClick: toggle, saveDisabled: saveDisabled(), OffcanvasModalID: "role", hideSaveButton: mode === 'view', handleOffcanvasBody: handleOffcanvasBody, modalClassName: 'w-40', saveText: mode === 'edit' ? 'Update' : 'Save', handleSaveClick: handleSubmit, offcanvasHeader: mode === 'edit' ? 'Edit Role' : mode === 'view' ? 'View Role' : 'Add Role' }), _jsx(DeleteModal, { recordId: selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.name, show: deleteModal, onDeleteClick: handleDeleteConfirmation, onCloseClick: function () { return setDeleteModal(false); } })] }) })] }));
};
export default Roles;
