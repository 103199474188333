var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable no-shadow */
import axios from 'axios';
import { AuthUser, decrypt, encrypt } from '../Components/Common/Util';
import { toast } from 'react-toastify';
import { store } from '../slices/persistor';
var envConf;
var initializeAxios = function () {
    var _a;
    // Update baseURL and originVal
    axios.defaults.baseURL = ((_a = window.location.origin) === null || _a === void 0 ? void 0 : _a.includes('localhost'))
        ? (envConf === null || envConf === void 0 ? void 0 : envConf.SUPERADMIN_URL) || 'https://sa.mdmdev.tectoro.com'
        : window.location.origin;
};
// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
// content type
var authUser = sessionStorage.getItem('authUser');
var token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;
if (token)
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
var customTransformRequest = function (data) {
    return data;
};
// intercepting to capture errors
axios.interceptors.response.use(function (response) {
    var _a;
    var resp = (response === null || response === void 0 ? void 0 : response.data) ? response === null || response === void 0 ? void 0 : response.data : response;
    return (envConf === null || envConf === void 0 ? void 0 : envConf.REACT_APP_ENCRYPTION_ENABLED) === 'true'
        ? ((_a = response === null || response === void 0 ? void 0 : response.headers) === null || _a === void 0 ? void 0 : _a['x-encrypted']) === 'Y'
            ? decrypt(resp)
            : resp
        : resp;
}, function (error) {
    /*
     * Any status codes that falls outside the range of 2xx cause this function to trigger
     */
    var _a, _b, _c, _d, _e, _f;
    if (!navigator.onLine)
        window.location.href = '/#/auth-offline';
    var message;
    error.response.data =
        (envConf === null || envConf === void 0 ? void 0 : envConf.REACT_APP_ENCRYPTION_ENABLED) === 'true'
            ? ((_b = (_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === null || _b === void 0 ? void 0 : _b.toString()) === '400'
                ? JSON.parse(decrypt(error.response.data))
                : error.response.data
            : error.response.data;
    var route401 = localStorage.getItem('route401');
    switch (error.response.status) {
        case 500:
            // message = 'Internal Server Error';
            window.location.href = '/#/auth-500';
            break;
        case 502:
            message = 'Bad Gateway';
            break;
        case 503:
            message = 'Server maintenance';
            window.location.href = '/#/maintenance';
            break;
        case 401:
            // message = 'Invalid Credentials';
            if (!route401)
                localStorage.setItem('route401', (_c = window.location.hash) === null || _c === void 0 ? void 0 : _c.replace('#', ''));
            else if (route401 === 'auth-401') {
                sessionStorage.clear();
                localStorage.clear();
                store.dispatch(authUser(null));
                window.location.reload();
            }
            window.location.href = '/#/auth-401';
            break;
        case 404:
            // message = 'Sorry! the data you are looking for could not be found';
            window.location.href = '/#/auth-404';
            break;
        case 403:
            return Promise.reject(error.response.status);
        default:
            message = ((_f = (_e = (_d = error === null || error === void 0 ? void 0 : error.response) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.errors) === null || _f === void 0 ? void 0 : _f[0]) || error.response.data.error || error;
            toast.error(message);
            return Promise.reject(message);
    }
});
/**
 * Sets the default authorization
 * @param {*} token
 */
var setAuthorization = function (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
};
store.subscribe(function () {
    var _a, _b;
    var user = AuthUser();
    var env = store.getState().Env.env;
    if (env && ((_a = Object.entries(env)) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        envConf = env;
        initializeAxios();
    }
    var tokenVal = user ? (_b = user === null || user === void 0 ? void 0 : user.data) === null || _b === void 0 ? void 0 : _b.token : null;
    if (tokenVal)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenVal;
});
initializeAxios();
var APIClient = /** @class */ (function () {
    function APIClient() {
        /**
         * Fetches data from the given URL
         */
        this.get = function (url, params, customURL) {
            var response;
            var paramKeys = [];
            if (params) {
                Object.keys(params).map(function (key) {
                    paramKeys.push(key + '=' + params[key]);
                    return paramKeys;
                });
                var queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : '';
                response = axios.get("".concat(customURL ? customURL : '').concat(url, "?").concat(queryString), params);
            }
            else {
                response = axios.get("".concat(customURL ? customURL : '').concat(url), params);
            }
            return response;
        };
        /**
         * Posts the given data to the URL
         */
        this.create = function (url, data, customURL, noEnc) {
            var param = (envConf === null || envConf === void 0 ? void 0 : envConf.REACT_APP_ENCRYPTION_ENABLED) === 'true' ? (noEnc ? data : encrypt(data)) : data;
            return axios.post((customURL ? customURL : '') + url, param);
        };
        /**
         * Updates data
         */
        this.update = function (url, data, customURL, noEnc) {
            var param = (envConf === null || envConf === void 0 ? void 0 : envConf.REACT_APP_ENCRYPTION_ENABLED) === 'true' ? (noEnc ? data : encrypt(data)) : data;
            return axios.patch((customURL ? customURL : '') + url, param);
        };
        this.put = function (url, data, customURL, noEnc) {
            var param = (envConf === null || envConf === void 0 ? void 0 : envConf.REACT_APP_ENCRYPTION_ENABLED) === 'true' ? (noEnc ? data : encrypt(data)) : data;
            return axios.put((customURL ? customURL : '') + url, param);
        };
        /**
         * Deletes data
         */
        this.delete = function (url, config, customURL) {
            return axios.delete((customURL ? customURL : '') + url, config);
        };
        /**
         * Updates data
         */
        this.patch = function (url, config, customURL, noEnc) {
            var param = (envConf === null || envConf === void 0 ? void 0 : envConf.REACT_APP_ENCRYPTION_ENABLED) === 'true' ? (noEnc ? config : encrypt(config)) : config;
            return axios.patch((customURL ? customURL : '') + url, __assign({}, param));
        };
        /*
         * FormData POST
         */
        this.createFormData = function (url, data, customURL) {
            return axios({
                method: 'POST',
                url: (customURL ? customURL : '') + url,
                headers: { 'Content-Type': 'multipart/form-data' },
                data: data,
                transformRequest: customTransformRequest
            });
        };
    }
    return APIClient;
}());
var getLoggedinUser = function () {
    var _a, _b;
    var user = (_b = (_a = store.getState()) === null || _a === void 0 ? void 0 : _a.Login) === null || _b === void 0 ? void 0 : _b.user;
    if (!user)
        return null;
    return user;
};
export { APIClient, getLoggedinUser, setAuthorization };
