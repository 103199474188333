import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Layouts
import NonAuthLayout from '../Layouts/NonAuthLayout';
import VerticalLayout from '../Layouts/index';
// routes
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import AuthProtected from './AuthProtected';
var Index = function () {
    return (_jsx(React.Fragment, { children: _jsxs(Routes, { children: [_jsx(Route, { children: publicRoutes.map(function (route, idx) { return (_jsx(Route, { path: route.path, element: _jsx(NonAuthLayout, { children: route.component }) }, idx)); }) }), _jsx(Route, { children: authProtectedRoutes.map(function (route, idx) { return (_jsx(Route, { path: route.path, element: _jsx(AuthProtected, { children: _jsx(VerticalLayout, { children: route.component }) }) }, idx)); }) })] }) }));
};
export default Index;
