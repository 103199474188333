import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ParticlesAuth from '../../../pages/Authentication/ParticlesAuth';
// images import
import maintenanceImg from '../../../assets/images/commons/maintenance.png';
var Maintenance = function () {
    document.title = 'Maintenance';
    return (_jsx(React.Fragment, { children: _jsx(ParticlesAuth, { children: _jsx("div", { className: "auth-page-content mt-lg-5", children: _jsx(Container, { children: _jsx(Row, { children: _jsx(Col, { lg: 12, children: _jsxs("div", { className: "text-center mt-sm-5 pt-4", children: [_jsxs("div", { className: "mb-5 text-white-50", children: [_jsx("h1", { className: "display-5 coming-soon-text", children: "Site is Under Maintenance" }), _jsx("p", { className: "fs-14", children: "Please check back in sometime" }), _jsx("div", { className: "mt-4 pt-2", children: _jsxs(Link, { to: "/", className: "btn btn-success", children: [_jsx("i", { className: "mdi mdi-home me-1" }), " Back to Home"] }) })] }), _jsx(Row, { className: "justify-content-center mb-5", children: _jsx(Col, { xl: 4, lg: 8, children: _jsx("div", { children: _jsx("img", { src: maintenanceImg, alt: "", className: "img-fluid" }) }) }) })] }) }) }) }) }) }) }));
};
export default Maintenance;
