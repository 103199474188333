import { jsx as _jsx } from "react/jsx-runtime";
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { logoutUser } from '../../slices/thunks';
// redux
import { useSelector, useDispatch } from 'react-redux';
import withRouter from '../../Components/Common/withRouter';
import { createSelector } from 'reselect';
var Logout = function () {
    var dispatch = useDispatch();
    var logoutData = createSelector(function (state) { return state.Login; }, function (isUserLogout) { return isUserLogout.isUserLogout; });
    // Inside your component
    var isUserLogout = useSelector(logoutData);
    useEffect(function () {
        dispatch(logoutUser());
    }, [dispatch]);
    if (isUserLogout) {
        return _jsx(Navigate, { to: "/login" });
    }
    return _jsx(React.Fragment, {});
};
Logout.propTypes = {
    history: PropTypes.object
};
export default withRouter(Logout);
