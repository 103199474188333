var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Input } from 'reactstrap';
export var Filter = function (_a) {
    var column = _a.column;
    return _jsx("div", { className: "margin-top-5", children: column.canFilter && column.render('Filter') });
};
export var DefaultColumnFilter = function (_a) {
    var _b = _a.column, Header = _b.Header, filterValue = _b.filterValue, setFilter = _b.setFilter, preFilteredRows = _b.preFilteredRows;
    return _jsx(Input, { value: filterValue || '', onChange: function (e) { return setFilter(e.target.value || undefined); }, placeholder: "Search by ".concat(Header) });
};
export var SelectColumnFilter = function (_a) {
    var _b = _a.column, filterValue = _b.filterValue, setFilter = _b.setFilter, preFilteredRows = _b.preFilteredRows, id = _b.id;
    var options = React.useMemo(function () {
        var ops = new Set();
        preFilteredRows.forEach(function (row) {
            ops.add(row.values[id]);
        });
        return __spreadArray([], ops.values(), true);
    }, [id, preFilteredRows]);
    return (_jsxs("select", { id: "custom-select", className: "form-select", value: filterValue, onChange: function (e) {
            setFilter(e.target.value || undefined);
        }, children: [_jsx("option", { value: "", children: "All" }), options.map(function (option) { return (_jsx("option", { value: option, children: option }, option)); })] }));
};
