import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Flatpickr from 'react-flatpickr';
import { Col, FormFeedback, Label } from 'reactstrap';
// import { getDate } from '../Common/Util';
var DatePicker = function (props) {
    // formated date to fix date value issue should be fixed if it uses flatpickr latest version
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var handleDateChange = function (date) {
        var formattedDate;
        if (date[0]) {
            var before = new Date(date[0].getTime() + 1 * 24 * 60 * 60 * 1000);
            formattedDate = new Date(before.toISOString().slice(0, 10));
        }
        else {
            return null;
        }
        if (props.validation.values.startDate) {
            var startDate = new Date(props.validation.values.startDate);
            var diffInMillisec = formattedDate.getTime() - startDate.getTime();
            var diffInDays = Math.ceil(diffInMillisec / (24 * 60 * 60 * 1000));
            if (diffInDays > 0) {
                props.validation.setFieldValue('tenure', "".concat(diffInDays, " ").concat(diffInDays > 1 ? 'days' : 'day'));
            }
        }
        else if (props.validation.values.endDate) {
            var endDate = new Date(props.validation.values.endDate);
            var diffInMillisec = endDate.getTime() - formattedDate.getTime();
            var diffInDays = Math.ceil(diffInMillisec / (24 * 60 * 60 * 1000));
            if (diffInDays > 0) {
                props.validation.setFieldValue('tenure', "".concat(diffInDays, " ").concat(diffInDays > 1 ? 'days' : 'day'));
            }
        }
        return formattedDate;
    };
    var handleCheckIn = function (field) {
        var _a, _b;
        var val = props[(_a = field === null || field === void 0 ? void 0 : field.checkIn) === null || _a === void 0 ? void 0 : _a[0]];
        (_b = field === null || field === void 0 ? void 0 : field.checkIn) === null || _b === void 0 ? void 0 : _b.forEach(function (v) { return (v !== 'validation' ? (val = val[v]) : ''); });
        return val;
    };
    return (_jsxs(Col, { className: "mt-2", xs: ((_a = props.field) === null || _a === void 0 ? void 0 : _a.xs) || 12, sm: ((_b = props.field) === null || _b === void 0 ? void 0 : _b.sm) || 12, md: ((_c = props.field) === null || _c === void 0 ? void 0 : _c.md) || 8, lg: ((_d = props.field) === null || _d === void 0 ? void 0 : _d.lg) || 6, xl: ((_e = props.field) === null || _e === void 0 ? void 0 : _e.xl) || 6, children: [((_f = props.field) === null || _f === void 0 ? void 0 : _f.label) && (_jsxs(Label, { htmlFor: (_g = props.field) === null || _g === void 0 ? void 0 : _g.value, className: "form-label m-0 ".concat(props.labelCss && props.labelCss), children: [props.field.label, props.mode !== 'view' && props.field.mandatory ? (_jsx("span", { className: "text-danger", children: " *" })) : (_jsx("span", { className: "ps-1", children: " :" }))] })), ((_h = props.field) === null || _h === void 0 ? void 0 : _h.helpText) && _jsx("div", { className: "fs-13 text-muted", children: (_j = props === null || props === void 0 ? void 0 : props.field) === null || _j === void 0 ? void 0 : _j.helpText }), _jsxs("div", { className: "input-group flex-nowrap mt-2", children: [_jsx(Flatpickr, { className: 'form-control w-100 ', name: (_k = props.field) === null || _k === void 0 ? void 0 : _k.value, value: props.validation.values[(_l = props.field) === null || _l === void 0 ? void 0 : _l.value] || null, placeholder: "".concat('Select', " ").concat(props.field.label), 
                        // enable={props.field?.enable}
                        disabled: props.mode === 'view'
                            ? true
                            : props.field.disableWhileEdit
                                ? props.mode === 'edit' && props.field.disableWhileEdit
                                : (_m = props.field) === null || _m === void 0 ? void 0 : _m.disabled, onChange: function (e) {
                            var _a, _b, _c, _d;
                            var formatedDate = handleDateChange(e);
                            props === null || props === void 0 ? void 0 : props.validation.handleChange({
                                target: { name: (_a = props.field) === null || _a === void 0 ? void 0 : _a.value, id: (_b = props.field) === null || _b === void 0 ? void 0 : _b.value, value: formatedDate }
                            });
                            if (props.onChange) {
                                props.onChange({
                                    target: { name: (_c = props.field) === null || _c === void 0 ? void 0 : _c.value, id: (_d = props.field) === null || _d === void 0 ? void 0 : _d.value, value: formatedDate }
                                });
                            }
                        }, 
                        // onBlur={props.validation.handleBlur}
                        options: {
                            dateFormat: 'd M, Y',
                            mode: props.pickerMode ? props.pickerMode : 'single',
                            minDate: props.mode !== 'view' && props.field.minDate
                                ? props.field.checkIn
                                    ? new Date((_p = (_o = handleCheckIn(props.field)) === null || _o === void 0 ? void 0 : _o[props.field.minDate]) === null || _p === void 0 ? void 0 : _p.getFullYear(), (_r = (_q = handleCheckIn(props.field)) === null || _q === void 0 ? void 0 : _q[props.field.minDate]) === null || _r === void 0 ? void 0 : _r.getMonth(), ((_t = (_s = handleCheckIn(props.field)) === null || _s === void 0 ? void 0 : _s[props.field.minDate]) === null || _t === void 0 ? void 0 : _t.getDate()) + 1)
                                    : (props === null || props === void 0 ? void 0 : props.mode) === 'edit' && ((_u = props === null || props === void 0 ? void 0 : props.field) === null || _u === void 0 ? void 0 : _u.hideMinDateWhileEdit)
                                        ? ''
                                        : props.field.minDate
                                : '',
                            maxDate: props.pickerMaxDate ? props.pickerMaxDate : ''
                        } }), _jsx("div", { className: "input-group-text bg-primary border-primary text-white", children: _jsx("i", { className: "ri-calendar-2-line" }) })] }), props.validation.touched[(_v = props.field) === null || _v === void 0 ? void 0 : _v.value] && props.validation.errors[(_w = props.field) === null || _w === void 0 ? void 0 : _w.value] ? (_jsx(FormFeedback, { type: "invalid", children: _jsx("div", { children: props.validation.errors[(_x = props.field) === null || _x === void 0 ? void 0 : _x.value] }) })) : null] }));
};
export default DatePicker;
