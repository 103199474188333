import { combineReducers } from 'redux';
// Front
import LayoutReducer from './layouts/reducer';
// Authentication
import LoginReducer from './auth/login/reducer';
import EnvReducer from './envVals/reducer';
var rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Env: EnvReducer
});
export default rootReducer;
