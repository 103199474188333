var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Loader from '../../Components/Common/Loader';
import { envVariables, getLicenseParams } from '../../Components/Common/Util';
import DynamicFunction from '../../Components/DynamicComponents/DynamicFunction';
import { useFormik } from 'formik';
import { APIClient } from '../../helpers/api_helper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, CardHeader, Container, Row } from 'reactstrap';
import { LicenseSchemaaa, planTypes, productTypes } from './tenantSchemas';
import * as url from '../../helpers/url_helper';
import * as domains from '../../helpers/domain_helper';
import { toast } from 'react-toastify';
import { toastMessages } from '../../Components/Common/ToastMessages';
var AddLicense = function () {
    var _a, _b, _c, _d, _e, _f;
    var formTypeAndId = getLicenseParams(window.location.hash);
    var api = new APIClient();
    var envConf = envVariables();
    var navigate = useNavigate();
    document.title = "".concat(formTypeAndId.mode === 'edit' ? 'Edit' : formTypeAndId.mode === 'add' ? 'Add' : 'View', " License");
    var _g = useState(false), loading = _g[0], setLoading = _g[1];
    var _h = useState({}), formValues = _h[0], setFormValues = _h[1];
    var _j = useState({}), tenantDetails = _j[0], setTenantDetails = _j[1];
    useEffect(function () {
        handlePromise();
    }, []);
    var handlePromise = function () {
        setLoading(true);
        var tenantPromise = new Promise(function (resolve, reject) {
            api.get(url.ADMIN_TENANT + '/' + formTypeAndId.tenantId, '', domains.IDM_V1)
                .then(function (resp) {
                resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    resolve(resp.data);
                }
                else
                    reject('Tenant Failed');
            })
                .catch(function (err) { return reject(err); });
        });
        var licensePromise = new Promise(function (resolve, reject) {
            if (formTypeAndId.recordId) {
                api.get(url.LICENSE + '/' + formTypeAndId.recordId, '', domains.MDM_COMMONS)
                    .then(function (resp) {
                    resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        resolve(resp.data);
                    }
                    else
                        reject('License Failed');
                })
                    .catch(function (err) { return reject(err); });
            }
            else
                reject('No License');
        });
        Promise.allSettled([tenantPromise, licensePromise])
            .then(function (result) {
            var _a;
            if (result[0].status === 'fulfilled') {
                setTenantDetails(result[0].value);
            }
            if (result[1].status === 'fulfilled') {
                var values_1 = result[1].value;
                var obj = {
                    productType: productTypes.find(function (type) { return type.value === (values_1 === null || values_1 === void 0 ? void 0 : values_1.ProductType); }),
                    startDate: new Date(values_1 === null || values_1 === void 0 ? void 0 : values_1.StartDate),
                    endDate: new Date(values_1 === null || values_1 === void 0 ? void 0 : values_1.EndDate),
                    planType: planTypes === null || planTypes === void 0 ? void 0 : planTypes.find(function (type) { return (values_1 === null || values_1 === void 0 ? void 0 : values_1.PlanType) === type.value; }),
                    noOfLicenses: (_a = values_1 === null || values_1 === void 0 ? void 0 : values_1.Total) === null || _a === void 0 ? void 0 : _a.toString(),
                    note: values_1 === null || values_1 === void 0 ? void 0 : values_1.Note,
                    tenure: (values_1 === null || values_1 === void 0 ? void 0 : values_1.Tenure) + ' days'
                };
                setFormValues(obj);
            }
            setLoading(false);
        })
            .catch(function (err) { return setLoading(false); });
    };
    var validation = useFormik({
        enableReinitialize: true,
        initialValues: __assign({}, formValues),
        onSubmit: function (values) {
            var _a, _b, _c, _d, _e;
            setLoading(true);
            var data = formTypeAndId.recordId
                ? { enddate: (_a = new Date(values.endDate)) === null || _a === void 0 ? void 0 : _a.toISOString(), total: Number(values === null || values === void 0 ? void 0 : values.noOfLicenses) }
                : {
                    tenant: tenantDetails === null || tenantDetails === void 0 ? void 0 : tenantDetails.code,
                    producttype: (_b = values === null || values === void 0 ? void 0 : values.productType) === null || _b === void 0 ? void 0 : _b.value,
                    startdate: (_c = new Date(values.startDate)) === null || _c === void 0 ? void 0 : _c.toISOString(),
                    enddate: (_d = new Date(values === null || values === void 0 ? void 0 : values.endDate)) === null || _d === void 0 ? void 0 : _d.toISOString(),
                    plantype: (_e = values === null || values === void 0 ? void 0 : values.planType) === null || _e === void 0 ? void 0 : _e.value,
                    total: Number(values === null || values === void 0 ? void 0 : values.noOfLicenses),
                    note: values === null || values === void 0 ? void 0 : values.note
                };
            var apiService;
            if (formTypeAndId.recordId)
                apiService = api.patch(url.LICENSE + '/' + formTypeAndId.recordId + '/' + (tenantDetails === null || tenantDetails === void 0 ? void 0 : tenantDetails.code), data, domains.MDM_COMMONS);
            else
                apiService = api.create(url.LICENSE, data, domains.MDM_COMMONS);
            apiService
                .then(function (resp) {
                resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formTypeAndId.recordId ? toastMessages.licenseUpdated : toastMessages.licenseCreated);
                    navigate("/tenantmaster/view/".concat(formTypeAndId.tenantId, "/license"));
                }
                setLoading(false);
            })
                .catch(function (err) { return setLoading(false); });
        }
    });
    return (_jsxs(React.Fragment, { children: [loading && _jsx(Loader, {}), _jsx("div", { className: "page-content h-100 ".concat(loading ? 'postion-relative mask' : ''), children: _jsxs(Container, { fluid: true, children: [_jsx(BreadCrumb, { title: "".concat(formTypeAndId.mode === 'edit' ? 'Edit' : formTypeAndId.mode === 'add' ? 'Add' : 'View', " License"), showBack: true, backLink: "tenantmaster/view/".concat(formTypeAndId.tenantId, "/license") }), _jsxs(Card, { children: [_jsxs(CardHeader, { children: [_jsx("div", { className: "fs-14 fw-semibold", children: "License Information" }), _jsx("div", { className: "mt-1", children: "View your current licensing details, including type, expiration date, and usage limits, to ensure compliance and enhance management of the devices." })] }), _jsx(CardBody, { className: "pt-0", children: _jsx(Row, { children: LicenseSchemaaa === null || LicenseSchemaaa === void 0 ? void 0 : LicenseSchemaaa.map(function (field, index) { return (_jsx(DynamicFunction, { field: field, validation: validation, index: index, mode: (formTypeAndId === null || formTypeAndId === void 0 ? void 0 : formTypeAndId.mode) === 'edit' ? 'edit' : (formTypeAndId === null || formTypeAndId === void 0 ? void 0 : formTypeAndId.mode) === 'view' ? 'view' : 'add' }, index)); }) }) }), _jsx(CardFooter, { children: _jsxs("div", { className: "d-flex justify-content-end ", children: [_jsx(Button, { className: "bg-white border-primary text-primary", onClick: function () { return navigate("/tenantmaster/view/".concat(formTypeAndId.tenantId, "/license")); }, children: "Cancel" }), formTypeAndId.mode !== 'view' && (_jsx(Button, { color: "primary", disabled: ((_a = validation === null || validation === void 0 ? void 0 : validation.values) === null || _a === void 0 ? void 0 : _a.noOfLicenses) === '' ||
                                                    !((_b = validation === null || validation === void 0 ? void 0 : validation.values) === null || _b === void 0 ? void 0 : _b.noOfLicenses) ||
                                                    !((_c = validation.values) === null || _c === void 0 ? void 0 : _c.productType) ||
                                                    !((_d = validation.values) === null || _d === void 0 ? void 0 : _d.planType) ||
                                                    !((_e = validation === null || validation === void 0 ? void 0 : validation.values) === null || _e === void 0 ? void 0 : _e.startDate) ||
                                                    !((_f = validation.values) === null || _f === void 0 ? void 0 : _f.endDate), className: "mx-2 border-primary", onClick: function () { return validation.handleSubmit(); }, children: (formTypeAndId === null || formTypeAndId === void 0 ? void 0 : formTypeAndId.recordId) ? 'Update' : 'Save' }))] }) })] })] }) })] }));
};
export default AddLicense;
