import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
// import images
import { AuthUser } from './Util';
var ProfileDropdown = function () {
    // Inside your component
    var _a, _b, _c;
    var user = AuthUser();
    // Dropdown Toggle
    var _d = useState(false), isProfileDropdown = _d[0], setIsProfileDropdown = _d[1];
    var toggleProfileDropdown = function () {
        setIsProfileDropdown(!isProfileDropdown);
    };
    var userLabel = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        var name = (_b = (_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.username) === null || _b === void 0 ? void 0 : _b.split(' ');
        if ((name === null || name === void 0 ? void 0 : name.length) === 1)
            return ((_d = (_c = name === null || name === void 0 ? void 0 : name[0]) === null || _c === void 0 ? void 0 : _c.charAt(0)) === null || _d === void 0 ? void 0 : _d.toUpperCase()) + ((_f = (_e = name === null || name === void 0 ? void 0 : name[0]) === null || _e === void 0 ? void 0 : _e.charAt(1)) === null || _f === void 0 ? void 0 : _f.toUpperCase());
        else
            return ((_h = (_g = name === null || name === void 0 ? void 0 : name[0]) === null || _g === void 0 ? void 0 : _g.charAt(0)) === null || _h === void 0 ? void 0 : _h.toUpperCase()) + ((_k = (_j = name === null || name === void 0 ? void 0 : name[1]) === null || _j === void 0 ? void 0 : _j.charAt(0)) === null || _k === void 0 ? void 0 : _k.toUpperCase());
    };
    return (_jsx(React.Fragment, { children: _jsxs(Dropdown, { isOpen: isProfileDropdown, toggle: toggleProfileDropdown, className: "ms-sm-3 header-item topbar-user", children: [_jsx(DropdownToggle, { tag: "button", type: "button", className: "btn", children: _jsxs("span", { className: "d-flex align-items-center", children: [_jsx("span", { className: "rounded-circle header-profile-user border border-2 d-flex align-items-center justify-content-center fs-15 bg-success text-white p-3", children: userLabel() }), _jsxs("span", { className: "text-start ms-xl-2", children: [_jsxs("span", { className: "d-none d-xl-inline-block ms-1 fw-medium user-name-text", children: [' ', ((_a = user === null || user === void 0 ? void 0 : user.data) === null || _a === void 0 ? void 0 : _a.username) || 'Admin'] }), _jsx("span", { className: "d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text", children: (_b = user === null || user === void 0 ? void 0 : user.data) === null || _b === void 0 ? void 0 : _b.roleName })] })] }) }), _jsxs(DropdownMenu, { className: "dropdown-menu-end", children: [_jsxs("h6", { className: "dropdown-header", children: ["Welcome ", (_c = user === null || user === void 0 ? void 0 : user.data) === null || _c === void 0 ? void 0 : _c.username, "!"] }), _jsx(DropdownItem, { className: "p-0", children: _jsxs(Link, { to: "/logout", className: "dropdown-item", children: [_jsx("i", { className: "ri-logout-box-r-line text-muted fs-16 align-middle me-1" }), ' ', _jsx("span", { className: "align-middle", "data-key": "t-logout", children: "Logout" })] }) })] })] }) }));
};
export default ProfileDropdown;
