var _a;
import { createSlice } from '@reduxjs/toolkit';
// constants
import { LAYOUT_TYPES, LAYOUT_MODE_TYPES, LAYOUT_SIDEBAR_TYPES, LAYOUT_WIDTH_TYPES, LAYOUT_POSITION_TYPES, LAYOUT_TOPBAR_THEME_TYPES, LEFT_SIDEBAR_SIZE_TYPES, LEFT_SIDEBAR_VIEW_TYPES, LEFT_SIDEBAR_IMAGE_TYPES, PERLOADER_TYPES, SIDEBAR_VISIBILITY_TYPES } from '../../Components/constants/layout';
export var initialState = {
    layoutType: LAYOUT_TYPES.VERTICAL,
    layoutModeType: LAYOUT_MODE_TYPES.LIGHTMODE,
    leftSidebarType: LAYOUT_SIDEBAR_TYPES.LIGHT,
    layoutWidthType: LAYOUT_WIDTH_TYPES.FLUID,
    layoutPositionType: LAYOUT_POSITION_TYPES.FIXED,
    topbarThemeType: LAYOUT_TOPBAR_THEME_TYPES.LIGHT,
    leftsidbarSizeType: LEFT_SIDEBAR_SIZE_TYPES.DEFAULT,
    leftSidebarViewType: LEFT_SIDEBAR_VIEW_TYPES.DEFAULT,
    leftSidebarImageType: LEFT_SIDEBAR_IMAGE_TYPES.NONE,
    preloader: PERLOADER_TYPES.DISABLE,
    sidebarVisibilitytype: SIDEBAR_VISIBILITY_TYPES.SHOW
};
var LayoutSlice = createSlice({
    name: 'LayoutSlice',
    initialState: initialState,
    reducers: {
        changeLayoutAction: function (state, action) {
            state.layoutType = action.payload;
        },
        changeLayoutModeAction: function (state, action) {
            state.layoutModeType = action.payload;
        },
        changeSidebarThemeAction: function (state, action) {
            state.leftSidebarType = action.payload;
        },
        changeLayoutWidthAction: function (state, action) {
            state.layoutWidthType = action.payload;
        },
        changeLayoutPositionAction: function (state, action) {
            state.layoutPositionType = action.payload;
        },
        changeTopbarThemeAction: function (state, action) {
            state.topbarThemeType = action.payload;
        },
        changeLeftsidebarSizeTypeAction: function (state, action) {
            state.leftsidbarSizeType = action.payload;
        },
        changeLeftsidebarViewTypeAction: function (state, action) {
            state.leftSidebarViewType = action.payload;
        },
        changeSidebarImageTypeAction: function (state, action) {
            state.leftSidebarImageType = action.payload;
        },
        changePreLoaderAction: function (state, action) {
            state.preloader = action.payload;
        },
        changeSidebarVisibilityAction: function (state, action) {
            state.sidebarVisibilitytype = action.payload;
        }
    }
});
export var changeLayoutAction = (_a = LayoutSlice.actions, _a.changeLayoutAction), changeLayoutModeAction = _a.changeLayoutModeAction, changeSidebarThemeAction = _a.changeSidebarThemeAction, changeLayoutWidthAction = _a.changeLayoutWidthAction, changeLayoutPositionAction = _a.changeLayoutPositionAction, changeTopbarThemeAction = _a.changeTopbarThemeAction, changeLeftsidebarSizeTypeAction = _a.changeLeftsidebarSizeTypeAction, changeLeftsidebarViewTypeAction = _a.changeLeftsidebarViewTypeAction, changeSidebarImageTypeAction = _a.changeSidebarImageTypeAction, changePreLoaderAction = _a.changePreLoaderAction, changeSidebarVisibilityAction = _a.changeSidebarVisibilityAction;
export default LayoutSlice.reducer;
