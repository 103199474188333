var _a;
import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    env: null,
    error: '', // for error message
    loading: false,
    errorMsg: false // for error
};
var envSlice = createSlice({
    name: 'envVars',
    initialState: initialState,
    reducers: {
        apiError: function (state, action) {
            state.error = action.payload.data;
            state.loading = true;
            state.errorMsg = true;
        },
        envSuccess: function (state, action) {
            state.env = action.payload;
            state.loading = false;
            state.errorMsg = false;
        }
    }
});
export var apiError = (_a = envSlice.actions, _a.apiError), envSuccess = _a.envSuccess;
export default envSlice.reducer;
