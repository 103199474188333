/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';
import { getLoggedinUser } from '../../helpers/api_helper';
var useProfile = function () {
    var userProfileSession = getLoggedinUser();
    var token = userProfileSession && userProfileSession['token'];
    var _a = useState(userProfileSession ? false : true), loading = _a[0], setLoading = _a[1];
    var _b = useState(userProfileSession ? userProfileSession : null), userProfile = _b[0], setUserProfile = _b[1];
    useEffect(function () {
        var userProfileSession = getLoggedinUser();
        var token = userProfileSession && userProfileSession['token'];
        setUserProfile(userProfileSession ? userProfileSession : null);
        setLoading(token ? false : true);
    }, []);
    return { userProfile: userProfile, loading: loading, token: token };
};
var togglePassword = function (id, iconID) {
    var passwordInput = document.getElementById(id);
    var passwordIcon = document.getElementById(iconID);
    if (passwordInput && passwordIcon) {
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text';
            passwordIcon.classList.remove('ri-eye-off-line');
            passwordIcon.classList.add('ri-eye-line');
        }
        else {
            passwordInput.type = 'password';
            passwordIcon.classList.remove('ri-eye-line');
            passwordIcon.classList.add('ri-eye-off-line');
        }
    }
};
var togglePasswordStrengthIn = function (divID) {
    var passwordStrengthValidation = document.getElementById(divID);
    if (passwordStrengthValidation) {
        passwordStrengthValidation.style.display = 'block';
    }
};
var togglePasswordStrengthOut = function (divID) {
    var passwordStrengthValidation = document.getElementById(divID);
    if (passwordStrengthValidation) {
        passwordStrengthValidation.style.display = 'none';
    }
};
var togglePasswordChange = function (event) {
    var numberPattern = /(?=.*?[0-9])/;
    var specialSymbolPattern = /(?=.*?[#?!@$%^&*-])/;
    var lowercasePattern = /(?=.*?[a-z])/;
    var uppercasePattern = /(?=.*?[A-Z])/;
    var minLengthPattern = /.{8,}/;
    var classNameChangeFunction = function (id, oldClass, newClass) {
        var element = document.getElementById(id);
        if (element) {
            element.classList.remove(oldClass);
            element.classList.add(newClass);
        }
    };
    if (event) {
        classNameChangeFunction('pass-special', specialSymbolPattern.test(event) ? 'invalid' : 'valid', specialSymbolPattern.test(event) ? 'valid' : 'invalid');
        classNameChangeFunction('pass-number', numberPattern.test(event) ? 'invalid' : 'valid', numberPattern.test(event) ? 'valid' : 'invalid');
        classNameChangeFunction('pass-lower', lowercasePattern.test(event) ? 'invalid' : 'valid', lowercasePattern.test(event) ? 'valid' : 'invalid');
        classNameChangeFunction('pass-upper', uppercasePattern.test(event) ? 'invalid' : 'valid', uppercasePattern.test(event) ? 'valid' : 'invalid');
        classNameChangeFunction('pass-length', minLengthPattern.test(event) ? 'invalid' : 'valid', minLengthPattern.test(event) ? 'valid' : 'invalid');
        classNameChangeFunction('pass-max-length', event.length >= 8 && event.length <= 12 ? 'invalid' : 'valid', event.length >= 8 && event.length <= 12 ? 'valid' : 'invalid');
    }
    else {
        ['pass-number', 'pass-special', 'pass-lower', 'pass-upper', 'pass-length', 'pass-max-length'].forEach(function (id) {
            return classNameChangeFunction(id, 'valid', 'invalid');
        });
    }
};
var togglePasswordChangeVal = function (event) {
    var flag = [];
    if (event) {
        var numberPattern = /(?=.*?[0-9])/;
        var specialSymbolPattern = /(?=.*?[#?!@$%^&*-])/;
        var lowercasePattern = /(?=.*?[a-z])/;
        var uppercasePattern = /(?=.*?[A-Z])/;
        var maxLengthPattern = /.{8,}/;
        if (specialSymbolPattern.test(event))
            flag[0] = true;
        else
            flag[0] = false;
        if (numberPattern.test(event))
            flag[1] = true;
        else
            flag[1] = false;
        if (lowercasePattern.test(event))
            flag[2] = true;
        else
            flag[2] = false;
        if (uppercasePattern.test(event))
            flag[3] = true;
        else
            flag[3] = false;
        if (maxLengthPattern.test(event))
            flag[4] = true;
        else
            flag[4] = false;
        if (event.length <= 12)
            flag[5] = true;
        else
            flag[5] = false;
    }
    else {
        flag[0] = false;
        flag[1] = false;
        flag[2] = false;
        flag[3] = false;
        flag[4] = false;
        flag[5] = false;
    }
    return flag.every(function (ele) { return ele; });
};
export { useProfile, togglePassword, togglePasswordStrengthIn, togglePasswordStrengthOut, togglePasswordChange, togglePasswordChangeVal };
