/**
 * Changes the body attribute
 */
export var changeHTMLAttribute = function (attribute, value) {
    if (document.documentElement) {
        document.documentElement.setAttribute(attribute, value);
    }
    return true;
};
export var getDate = function (date, dateWithoutTime, includeSeconds) {
    var sDate = new Date();
    if (!date) {
        date = new Date(sDate);
    }
    date = new Date(date);
    var DATE_OPTIONS = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    var DATE_OPTIONS_NO_TIME = { year: 'numeric', month: 'short', day: 'numeric' };
    var DATE_OPTIONS_INCLUDE_SECONDS = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    return date.toLocaleDateString('en-US', dateWithoutTime ? DATE_OPTIONS_NO_TIME : includeSeconds ? DATE_OPTIONS_INCLUDE_SECONDS : DATE_OPTIONS);
};
