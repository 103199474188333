import { regex } from '../../Components/Common/Regex';
import AndroidImage from '../../assets/images/commons/android_image.png';
import BrandingImage from '../../assets/images/commons/BrandingImage.png';
import DeviceImage from '../../assets/images/commons/DeviceImage.png';
import LicenseImage from '../../assets/images/commons/LicenseImage.png';
import ModuleConImage from '../../assets/images/commons/ModuleConImage.png';
import Settingsimage from '../../assets/images/commons/Settingsimage.png';
import TVImage from '../../assets/images/commons/Tv_image.png';
import IosIamge from '../../assets/images/commons/ios_image.png';
import WindowsImage from '../../assets/images/commons/windows_image.png';
import google from '../../assets/images/Google.png';
import LDAP from '../../assets/images/LDAP.png';
import microsoft from '../../assets/images/microsoft.png';
import tectoro from '../../assets/images/tectoroo.png';
import dashboardImage from '../../assets/images/tenant_modules/dashboard.svg';
import setupImage from '../../assets/images/tenant_modules/setup.svg';
import appProtectionImage from '../../assets/images/tenant_modules/app_protection.svg';
import appsImage from '../../assets/images/tenant_modules/apps.svg';
import authImage from '../../assets/images/tenant_modules/auth_modes.svg';
import browserImage from '../../assets/images/tenant_modules/browser.svg';
import bulkImage from '../../assets/images/tenant_modules/bulk_requests.svg';
import callImage from '../../assets/images/tenant_modules/call_settings.svg';
import certificatesImage from '../../assets/images/tenant_modules/certificates.svg';
import contactImage from '../../assets/images/tenant_modules/contact_list.svg';
import contactMgmtImage from '../../assets/images/tenant_modules/contact_management.svg';
import devicesImage from '../../assets/images/tenant_modules/devices.svg';
import assetsImage from '../../assets/images/tenant_modules/assets.svg';
import filesImage from '../../assets/images/tenant_modules/files.svg';
import firewallImage from '../../assets/images/tenant_modules/firewall.svg';
import geofenceImage from '../../assets/images/tenant_modules/geofence.svg';
import groupsImage from '../../assets/images/tenant_modules/groups.svg';
import launcherImager from '../../assets/images/tenant_modules/launcher.svg';
import messagesImage from '../../assets/images/tenant_modules/messages.svg';
import policyImage from '../../assets/images/tenant_modules/policy.svg';
import reports from '../../assets/images/tenant_modules/reports.svg';
import simsettings from '../../assets/images/tenant_modules/simsettings.svg';
import timefence from '../../assets/images/tenant_modules/timefence.svg';
import wififence from '../../assets/images/tenant_modules/wififence.svg';
import licenseImage from '../../assets/images/tenant_modules/license.svg';
import adminReportImage from '../../assets/images/tenant_modules/admin_report.svg';
import odmFeatureImage from '../../assets/images/tenant_modules/odmFeatures.svg';
export var TableSchema = [
    {
        Header: 'Tenant ID',
        accessor: 'tenantID',
        sortable: true,
        customCell: true,
        fieldType: 'clickable',
        minWidth: 50,
        maxWidth: 50,
        width: 50
    },
    {
        Header: 'Name',
        accessor: 'name',
        sortable: true,
        minWidth: 150,
        maxWidth: 150,
        width: 150
    },
    {
        Header: 'Email ID',
        accessor: 'emailID',
        sortable: true,
        minWidth: 150,
        maxWidth: 150,
        width: 150
    },
    {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        sortable: true,
        minWidth: 150,
        maxWidth: 150,
        width: 150
    },
    {
        Header: 'Modified By',
        accessor: 'modifiedBy',
        sortable: true,
        minWidth: 100,
        maxWidth: 100,
        width: 100
    },
    {
        Header: 'Modified Date',
        accessor: 'modifiedDate',
        sortable: true,
        minWidth: 150,
        maxWidth: 150,
        width: 150
    },
    {
        Header: 'Status',
        accessor: 'status',
        sortable: false,
        customCell: true,
        fieldType: 'switch',
        width: '30px',
        maxWidth: '30px'
    },
    {
        Header: 'Actions',
        accessor: 'actions',
        sortable: true,
        customCell: true,
        fieldType: 'actions',
        edit: true
    }
];
export var FormSchema = [
    {
        label: 'Company Name',
        type: 'text',
        value: 'tenantName',
        ph: 'Enter Company Name',
        requiredMessage: 'Please Enter Company Name',
        regexMessage: 'Invalid Company Name',
        inputType: 'input',
        mandatory: true,
        regex: regex.alphaNumeric,
        lg: '12',
        xl: '12',
        md: '12'
    },
    {
        label: 'Contact Name',
        type: 'text',
        value: 'username',
        ph: 'Enter Contact Name',
        requiredMessage: 'Please Enter Contact Name',
        regexMessage: 'Invalid Contact Name',
        inputType: 'input',
        mandatory: true,
        regex: regex.alphabets,
        lg: '12',
        xl: '12',
        md: '12'
    },
    {
        label: 'Email ID',
        type: 'text',
        requiredMessage: 'Please Enter Email ID',
        regexMessage: 'Invalid Email ID',
        value: 'emailId',
        ph: 'Enter Email ID',
        inputType: 'input',
        mandatory: true,
        regex: regex.email,
        lg: '12',
        xl: '12',
        md: '12'
    },
    {
        label: 'Phone Number',
        type: 'tel',
        value: 'phoneNumber',
        requiredMessage: 'Enter Phone Number',
        regexMessage: 'Invalid Phone Number',
        ph: 'Enter Phone NUmber',
        inputType: 'input',
        maxLen: 10,
        mandatory: true,
        regex: regex.phone,
        lg: '12',
        xl: '12',
        md: '12'
    }
    /*
     * {
     *     label: 'Contract Start Date',
     *     type: 'date',
     *     value: 'startDate',
     *     minDate: 'today',
     *     requiredMessage: 'Enter Contract Start Date',
     *     ph: 'Enter the creator details',
     *     inputType: 'date',
     *     mandatory: true,
     *     hideMinDateWhileEdit: true,
     *     lg: '12',
     *     xl: '12',
     *     md: '12'
     * },
     * {
     *     label: 'Contract End Date',
     *     type: 'date',
     *     value: 'endDate',
     *     requiredMessage: 'Enter Contract End Date',
     *     minDate: 'startDate',
     *     ph: 'Enter the creator details',
     *     inputType: 'date',
     *     checkIn: ['validation', 'values'],
     *     mandatory: true,
     *     lg: '12',
     *     xl: '12',
     *     md: '12'
     * }
     */
];
export var TenantHeaderInfo = [
    { key: 'Tenant Name', accessor: 'name' },
    { key: 'Email ID', accessor: 'emailID' },
    { key: 'Contact Number', accessor: 'phone' },
    { key: 'Modified Date', accessor: 'modifiedDate' },
    { key: 'Modified By', accessor: 'modifiedBy' }
];
export var TenantBodyInfo = [
    {
        icon: BrandingImage,
        title: 'Branding Details',
        info: 'Personalize the portal with your branding by defining header and footer texts.',
        showCard: true
    },
    {
        icon: Settingsimage,
        title: 'Settings',
        info: 'Set platform preferences and configure the portal according to the organisations needs.',
        showCard: true
    },
    {
        icon: ModuleConImage,
        showCard: false,
        title: 'Module Config',
        info: 'Select and enable EMM modules based on your organizational needs.'
    },
    {
        icon: LicenseImage,
        title: 'License',
        info: 'Manage your licenses by allocating device count and its validity to match your current needs.',
        showCard: true
    }
    /*
     * {
     *     icon: AuthImage,
     *     title: 'Authentication Modes',
     *     info: 'You can Configure branding settings like Primary Colour, Logo, Header Colour.',
     *     showCard: true
     * },
     */
];
export var BrandingDetailsData = [
    {
        label: 'Primary Header',
        type: 'text',
        value: 'PRIMARY_HEADER',
        ph: 'Enter primary Header',
        inputType: 'input',
        mandatory: true,
        regex: regex.alphabets
    },
    {
        label: 'Secondary Header',
        type: 'text',
        value: 'SECONDARY_HEADER',
        ph: 'Enter Seconary Header',
        inputType: 'input',
        mandatory: true,
        regex: regex.alphabets
    },
    {
        label: 'Footer Text',
        type: 'text',
        value: 'COMPANY_FOOTER',
        ph: 'Enter footer text',
        inputType: 'input',
        mandatory: true,
        regex: regex.alphabets
    }
];
export var settingsData = [
    {
        icon: AndroidImage,
        title: 'Android',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'androidEnterpriseEnabled',
        settings: true
    },
    {
        icon: TVImage,
        title: 'Android TV',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'androidTvEnabled',
        settings: true
    },
    {
        icon: IosIamge,
        title: 'iOS',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'appleIOSEnabled',
        settings: true
    },
    {
        icon: WindowsImage,
        title: 'Windows',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'windowsEnabled',
        settings: true
    }
];
export var moduleConfigData = [
    {
        icon: dashboardImage,
        title: 'Dashboard',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'DASHBOARD',
        settings: true
    },
    {
        icon: policyImage,
        title: 'Policies',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'DEVICE_POLICY',
        settings: true
    },
    {
        icon: devicesImage,
        title: 'Devices',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'DEVICES',
        settings: true
    },
    {
        icon: bulkImage,
        title: 'Bulk Requests',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'BULK_REQUESTS',
        settings: true
    },
    {
        icon: reports,
        title: 'Reports',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'REPORTS',
        settings: true
    },
    {
        icon: messagesImage,
        title: 'Messages',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'MESSAGES',
        settings: true
    },
    {
        icon: adminReportImage,
        title: 'Admin Reports',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'ADMIN_REPORT',
        settings: true
    },
    {
        icon: setupImage,
        title: 'Setup',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'SETUP'
    },
    {
        icon: appsImage,
        title: 'Apps',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'APPS'
    },
    {
        icon: launcherImager,
        title: 'Launcher',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'LAUNCHER_SETUP'
    },
    {
        icon: filesImage,
        title: 'Files',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'FILES'
    },
    {
        icon: browserImage,
        title: 'Browser',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'BROWSER'
    },
    {
        icon: appProtectionImage,
        title: 'App Protection Policy',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'APP_PROTECTION'
    },
    {
        icon: firewallImage,
        title: 'Firewall',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'FIREWALL'
    },
    {
        icon: geofenceImage,
        title: 'Geo-fence',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'GEOFENCE'
    },
    {
        icon: timefence,
        title: 'Time-Fence',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'TIMEFENCE'
    },
    {
        icon: wififence,
        title: 'Wi-Fi Fence',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'WIFIFENCE'
    },
    {
        icon: simsettings,
        title: 'Sim Settings',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'SIM_SETTINGS'
    },
    {
        icon: contactImage,
        title: 'Contact List',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'CONTACT_LIST'
    },
    {
        icon: callImage,
        title: 'Call Settings',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'CALL_CONFIG'
    },
    {
        icon: certificatesImage,
        title: 'Certificates',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'CERTIFICATE_MANAGEMENT'
    },
    {
        icon: odmFeatureImage,
        title: 'ODM Features',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'ODM_FEATURES'
    },
    {
        icon: groupsImage,
        title: 'Groups',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'DEVICE_GROUPS'
    },
    {
        icon: assetsImage,
        title: 'Assets',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'ASSETS'
    },
    {
        icon: contactMgmtImage,
        title: 'Contact Management',
        info: 'Controls contact management',
        value: 'CONTACT_MANAGEMENT'
    },
    {
        icon: authImage,
        title: 'Authentication Modes',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes aa',
        value: 'AUTH_MODES'
    },
    {
        icon: licenseImage,
        title: 'License',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'LICENSE'
    },
    {
        icon: DeviceImage,
        title: 'Enrollment Settings',
        info: 'Apple Push Notification Certitude(APNs). This is basics and mandatory linking. This establishes ',
        value: 'ENROLLMENT_SETTNGS'
    }
];
export var LicenseSchema = [
    {
        Header: 'License ID',
        accessor: 'Code',
        sortable: true,
        customCell: true,
        fieldType: 'clickable',
        width: 50,
        minWidth: 50,
        maxWidth: 50
    },
    {
        Header: 'No.of Devices',
        accessor: 'Total',
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    /*
     * {
     *     Header: 'Remaining Licenses',
     *     accessor: 'remainingLicense',
     *     width: 150,
     *     minWidth: 150,
     *     maxWidth: 150
     * },
     */
    {
        Header: 'Plan Type',
        accessor: 'PlanType',
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        customCell: true,
        fieldType: 'customcss',
        customClass: 'fw-medium text-capitalize'
        /*
         * customCell: true,
         * fieldType: 'iconText'
         */
    },
    {
        Header: 'License Validity',
        accessor: 'Tenure',
        customCell: true,
        fieldType: 'coloredText',
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'License Start Date',
        accessor: 'startDate',
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'License End Date',
        accessor: 'endDate',
        width: 150,
        minWidth: 150,
        maxWidth: 150
    },
    {
        Header: 'Status',
        accessor: 'status',
        customCell: true,
        fieldType: 'badge',
        width: 100,
        minWidth: 100,
        maxWidth: 100
    },
    {
        Header: 'Actions',
        accessor: 'html',
        customCell: true,
        fieldType: 'actions',
        edit: true,
        // delete: true,
        // switch: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100
    }
];
export var LicenseBodyData = [
    {
        icon: tectoro,
        title: 'Tectoro',
        key: 'local',
        configured: false,
        enabled: true,
        info: 'Single Sign-on using a password securely set by an employee',
        status: 'Configure',
        route: '/tectoro'
    },
    {
        icon: microsoft,
        title: 'Microsoft',
        key: 'microsoft',
        configured: false,
        enabled: false,
        info: 'Single Sign-on using Microsoft Office 365 or Azure Active Directory Authentication',
        status: 'Configure',
        route: '/microsoft'
    },
    {
        icon: google,
        title: 'Google',
        key: 'google',
        configured: false,
        enabled: false,
        info: 'Single Sign-on using google for business',
        status: 'Configure',
        route: '/google'
    },
    {
        icon: LDAP,
        title: 'LDAP',
        key: 'ldap',
        configured: false,
        enabled: false,
        info: 'Single Sign-on using Office 365 or Azure Active Directory Authentication',
        status: 'Configure',
        route: '/ldap'
    }
];
export var series = [20, 30, 20, 20];
export var options = {
    labels: ['Android', 'Windows', 'Apple', 'Remaining'],
    chart: {
        type: 'donut',
        height: 200
    },
    plotOptions: {
        pie: {
            size: 100,
            offsetX: 0,
            offsetY: 0,
            donut: {
                size: '70%',
                labels: {
                    show: true,
                    name: {
                        show: true,
                        fontSize: '18px',
                        offsetY: -5
                    },
                    value: {
                        show: true,
                        fontSize: '20px',
                        color: '#343a40',
                        fontWeight: 500,
                        offsetY: 5,
                        formatter: function (val) {
                            return val;
                        }
                    },
                    total: {
                        show: true,
                        fontSize: '13px',
                        label: 'Total Devices',
                        color: '#9599ad',
                        fontWeight: 500,
                        formatter: function (w) {
                            return w.globals.seriesTotals.reduce(function (a, b) {
                                return a + b;
                            }, 0);
                        }
                    }
                }
            }
        }
    },
    dataLabels: {
        enabled: false
    },
    legend: {
        show: true,
        position: 'bottom',
        horizontalAlign: 'left',
        offsetX: 0,
        offsetY: 0,
        markers: {
            width: 5,
            height: 6,
            radius: 6
        },
        itemMargin: {
            horizontal: 42,
            vertical: 2
        }
    },
    stroke: {
        width: 0
    },
    yaxis: {
        labels: {
            formatter: function (value) {
                return value + ' Devices';
            }
        },
        tickAmount: 4,
        min: 0
    }
};
export var LicenseHeaderData = [
    { title: 'License Type', value: 'Purchased', labelCss: '' },
    { title: 'License Start Date', value: 'Apr 01, 2024 10:00 AM', labelCss: '' },
    { title: 'License End Date', value: 'Apr 01, 2024 10:00 AM', labelCss: 'text-danger' },
    { title: 'Remaining Days', value: '190 Days', labelCss: 'badge-soft-warning ', icon: true }
];
export var LicenseHeaderInfo = [
    { title: 'Total Quantity', value: '1,00,000' },
    { title: 'Used Licenses', value: '80,000' },
    { title: 'License Validity', value: '20,000', labelCss: 'text-success' }
];
export var LicenseInfo = [
    { title: 'Total Licenses', accessor: 'totallicenses' },
    { title: 'Used Licenses', accessor: 'totalassigned' },
    { title: 'Remaining Licenses', accessor: 'remainingLicenses' }
];
export var DevicesInfo = [
    { title: 'Android Devices', accessor: 'totalAndroid', icon: 'ri-android-fill px-1' },
    { title: 'Windows Devices', accessor: 'totalIOS', icon: 'ri-windows-fill px-1' },
    { title: 'IOS Devices', accessor: 'totalWindows', icon: 'ri-tablet-fill px-1' }
];
export var PayForm = [
    {
        label: 'Count Limit',
        labelIcon: 'ri-error-warning-fill',
        name: 'countLimit',
        value: 'countLimit',
        type: 'number',
        placeholder: 'Enter Device Count'
    },
    {
        label: 'Services',
        name: 'services',
        value: 'services',
        type: 'dropdown',
        placeholder: 'Select Services'
    },
    {
        label: 'Due Date',
        name: 'dueDate',
        value: 'dueDate',
        type: 'date',
        placeholder: 'Select Due Date'
    },
    {
        type: 'pay'
    }
];
export var LongTerm = [
    {
        label: 'Device Count',
        name: 'deviceCount',
        value: 'deviceCount',
        type: 'number',
        placeholder: 'Enter Device Count'
    },
    {
        label: 'Services',
        name: 'services',
        value: 'services',
        type: 'dropdown',
        placeholder: 'select services'
    },
    {
        type: 'longTerm'
    }
];
export var LicenseFullInfo = [
    { title: 'Device  Count', value: '1,00,000' },
    { title: 'License End Date', value: 'Apr 01, 2025 10:00 AM' },
    { title: 'Used Licenses', value: '80,000' },
    { title: 'License Start Date', value: 'Apr 01, 2025 10:00 AM' },
    { title: 'Remaining Licenses', value: '20,000' },
    { title: 'License Validity', value: '190 days' }
];
