import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
var Offlinepage = function () {
    document.title = 'Offline Page';
    return (_jsx(React.Fragment, { children: _jsxs("div", { className: "auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100", children: [_jsx("div", { className: "bg-overlay" }), _jsx("div", { className: "auth-page-content overflow-hidden pt-lg-5", children: _jsx(Container, { children: _jsx(Row, { className: "justify-content-center", children: _jsx(Col, { xl: 5, children: _jsx(Card, { className: "overflow-hidden", children: _jsx(CardBody, { className: "p-4", children: _jsxs("div", { className: "text-center", children: [_jsx("img", { src: "https://img.themesbrand.com/velzon/images/auth-offline.gif", alt: "", height: "210" }), _jsx("h3", { className: "mt-4 fw-semibold", children: "We're currently offline" }), _jsx("p", { className: "text-muted mb-4 fs-14", children: "We can't show you this images because you aren't connected to the internet. When you\u2019re back online refresh the page or hit the button below" }), _jsxs(Button, { color: "success", className: "btn-border", onClick: function () {
                                                        return navigator.onLine ? (window.location.href = '/') : undefined;
                                                    }, children: [_jsx("i", { className: "ri-refresh-line align-bottom" }), " Refresh"] })] }) }) }) }) }) }) })] }) }));
};
export default Offlinepage;
