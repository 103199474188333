import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-depth */
import React, { useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
// Formik Validation
import { useFormik } from 'formik';
import * as Yup from 'yup';
// redux
import { useDispatch } from 'react-redux';
import avatar from '../../assets/images/users/avatar-1.jpg';
var UserProfile = function () {
    var dispatch = useDispatch();
    var email = 'admin@gmail.com';
    var idx = '1';
    var userName = 'Admin';
    useEffect(function () {
        if (sessionStorage.getItem('authUser')) {
            var storedUser = sessionStorage.getItem('authUser');
            if (storedUser) {
                // const obj = JSON.parse(storedUser);
            }
        }
    }, [dispatch]);
    var validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            first_name: userName || 'Admin',
            idx: idx || ''
        },
        validationSchema: Yup.object({
            first_name: Yup.string().required('Please Enter Your UserName')
        }),
        onSubmit: function (values) {
            //
        }
    });
    document.title = 'Profile';
    return (_jsx(React.Fragment, { children: _jsx("div", { className: "page-content mt-lg-5", children: _jsxs(Container, { fluid: true, children: [_jsx(Row, { children: _jsx(Col, { lg: "12", children: _jsx(Card, { children: _jsx(CardBody, { children: _jsxs("div", { className: "d-flex", children: [_jsx("div", { className: "mx-3", children: _jsx("img", { src: avatar, alt: "", className: "avatar-md rounded-circle img-thumbnail" }) }), _jsx("div", { className: "flex-grow-1 align-self-center", children: _jsxs("div", { className: "text-muted", children: [_jsx("h5", { children: userName || 'Admin' }), _jsxs("p", { className: "mb-1", children: ["Email Id : ", email] }), _jsxs("p", { className: "mb-0", children: ["Id No : #", idx] })] }) })] }) }) }) }) }), _jsx("h4", { className: "card-title mb-4", children: "Change User Name" }), _jsx(Card, { children: _jsx(CardBody, { children: _jsxs(Form, { className: "form-horizontal", onSubmit: function (e) {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }, children: [_jsxs("div", { className: "form-group", children: [_jsx(Label, { className: "form-label", children: "User Name" }), _jsx(Input, { name: "first_name", 
                                                // value={name}
                                                className: "form-control", placeholder: "Enter User Name", type: "text", onChange: validation.handleChange, onBlur: validation.handleBlur, value: validation.values.first_name || '', invalid: validation.touched.first_name && validation.errors.first_name ? true : false }), validation.touched.first_name && validation.errors.first_name ? (_jsx(FormFeedback, { type: "invalid", children: validation.errors.first_name })) : null, _jsx(Input, { name: "idx", value: idx, type: "hidden" })] }), _jsx("div", { className: "text-center mt-4", children: _jsx(Button, { type: "submit", color: "danger", children: "Update User Name" }) })] }) }) })] }) }) }));
};
export default UserProfile;
