import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import TableContainer from '../../Components/Common/TableContainer';
import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { TableSchema } from '../../Components/constants/TenantData';
import { useNavigate } from 'react-router-dom';
import { APIClient } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';
import * as domains from '../../helpers/domain_helper';
import { AuthUser, convertUTCtoIST, envVariables, privileges } from '../../Components/Common/Util';
import Loader from '../../Components/Common/Loader';
import DeleteModal from '../../Components/Common/DeleteModal';
import { toast } from 'react-toastify';
import { toastMessages } from '../../Components/Common/ToastMessages';
var TenantMaster = function () {
    var _a, _b, _c, _d;
    document.title = 'Tenant Master';
    var envConf = envVariables();
    var _e = useState([]), allData = _e[0], setAllData = _e[1];
    var _f = useState(false), loading = _f[0], setLoading = _f[1];
    var _g = useState([]), schema = _g[0], setSchema = _g[1];
    var _h = useState({}), selectedRow = _h[0], setSelectedRow = _h[1];
    var _j = useState(false), deleteModal = _j[0], setDeleteModal = _j[1];
    var navigate = useNavigate();
    var api = new APIClient();
    var user = AuthUser();
    useEffect(function () {
        var _a, _b;
        var findPriv = (_b = (_a = user.data) === null || _a === void 0 ? void 0 : _a.privileges) === null || _b === void 0 ? void 0 : _b.includes(privileges.SA_TENANT_EDITOR);
        var schemaArr = JSON.parse(JSON.stringify(TableSchema));
        if (!findPriv)
            schemaArr = schemaArr.filter(function (obj) { return obj.Header !== 'Actions'; });
        setSchema(schemaArr);
        handlePromise();
    }, []);
    var handlePromise = function () {
        setLoading(true);
        var handleTenant = new Promise(function (resolve, reject) {
            var params = { page: 1, size: 100 };
            api.get(url.ADMIN_TENANT, params, domains.IDM_V1)
                .then(function (resp) {
                resp = envConf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                resolve(resp.data);
                setLoading(false);
            })
                .catch(function (err) {
                reject('tenant failed.');
                setLoading(false);
            });
        });
        Promise.allSettled([handleTenant]).then(function (result) {
            var _a, _b;
            setLoading(false);
            if (result[0].status === 'fulfilled') {
                (_b = (_a = result[0]) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.forEach(function (ele) {
                    ele.tenantID = ele.code;
                    ele.emailID = ele.email ? ele === null || ele === void 0 ? void 0 : ele.email : '_';
                    ele.modifiedBy = ele.modifiedby ? ele.modifiedby : '_';
                    ele.modifiedDate = ele.modifieddate ? convertUTCtoIST(ele.modifieddate, undefined) : '_';
                    ele.status = ele.status.toUpperCase() === 'A' ? 'active' : 'inactive';
                    ele.modifiedBy = ele.modifiedby ? ele.modifiedby : 'Tectoro';
                    ele.phoneNumber = ele.phone ? ele.phone : '_';
                });
                setAllData(result[0].value);
            }
        });
    };
    var handleStatusChange = function (row) {
        setSelectedRow(row);
        setDeleteModal(true);
    };
    var handleDeleteConfirmation = function () {
        var _a, _b;
        setLoading(true);
        setDeleteModal(false);
        api.update(url.ADMIN_TENANT + '/' + ((_a = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _a === void 0 ? void 0 : _a._id), { status: ((_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _b === void 0 ? void 0 : _b.status) === 'active' ? 'I' : 'A' }, domains.IDM_V1)
            .then(function (resp) {
            var _a, _b;
            if (((_a = resp === null || resp === void 0 ? void 0 : resp.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'success') {
                toast.success(((_b = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _b === void 0 ? void 0 : _b.status) === 'active' ? toastMessages.tenantDeactivated : toastMessages.tenantActivated);
                handlePromise();
            }
        })
            .catch(function (err) {
            setLoading(false);
        });
    };
    return (_jsxs(React.Fragment, { children: [loading && _jsx(Loader, {}), _jsx("div", { className: "page-content", children: _jsxs(Container, { fluid: true, children: [_jsxs("div", { children: [_jsx(BreadCrumb, { title: "Tenant Master", backLink: "tenantmaster" }), _jsx(TableContainer, { loading: loading, hideDisplayTotalCount: true, data: allData, columns: schema, isGlobalFilter: true, addButton: true, addButtonText: 'Add Tenant', searchFilter2: true, handleEdit: function (row) { var _a; return navigate("/tenantmaster/edit/".concat((_a = row === null || row === void 0 ? void 0 : row.original) === null || _a === void 0 ? void 0 : _a._id)); }, tableHeader: 'All Tenants', customPageSize: 10, reader: privileges.SA_TENANT_READER, editor: privileges.SA_TENANT_EDITOR, onClickSwitch: handleStatusChange, handleClickView: function (row) { var _a; return navigate("/tenantmaster/view/".concat((_a = row === null || row === void 0 ? void 0 : row.original) === null || _a === void 0 ? void 0 : _a._id)); }, handleAddNew: function () { return navigate('/tenantmaster/add'); }, className: "custom-header-css", divClass: "table-responsive table-card", tableClass: "table-nowrap table-border table-centered align-middle", theadClass: "bg-light text-muted" })] }), _jsx(DeleteModal, { show: deleteModal, onDeleteClick: handleDeleteConfirmation, onCloseClick: function () {
                                setDeleteModal(false);
                                setSelectedRow('');
                            }, messageText: ((_a = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _a === void 0 ? void 0 : _a.status) === 'active'
                                ? "Are you sure you want to deactivate this Tenant - ".concat((_b = selectedRow.original) === null || _b === void 0 ? void 0 : _b.name)
                                : "Are you sure you want to activate this Tenant - ".concat((_c = selectedRow.original) === null || _c === void 0 ? void 0 : _c.name), confirmText: ((_d = selectedRow === null || selectedRow === void 0 ? void 0 : selectedRow.original) === null || _d === void 0 ? void 0 : _d.status) === 'active' ? 'Yes, Deactivate' : 'Yes, Activate' })] }) })] }));
};
export default TenantMaster;
