import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import rootReducer from './index';
var persistConfig = { key: 'root', storage: storage };
var persistedReducer = persistReducer(persistConfig, rootReducer);
export var store = configureStore({
    reducer: persistedReducer,
    middleware: function () { return [thunk]; }
});
export var persistor = persistStore(store);
