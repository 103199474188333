var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useCallback, useMemo } from 'react';
import { uniqueID } from '../Util';
import ReactTooltip from 'react-tooltip';
var EllipsisToolTip = function (props) {
    // TOOL TIP STATE
    var _a = useState(false), showTooltip = _a[0], setShowTooltip = _a[1];
    // GENERATING - RANDOM ID
    var tid = useMemo(function () { return uniqueID(5); }, []);
    // MOUSE ENTER HANDLER
    var mouseEnterHandler = useCallback(function (e) {
        if (e.currentTarget.offsetWidth !== e.currentTarget.scrollWidth && !showTooltip) {
            setShowTooltip(true);
        }
        else if (e.currentTarget.offsetWidth === e.currentTarget.scrollWidth && showTooltip) {
            setShowTooltip(false);
        }
    }, [showTooltip, setShowTooltip]);
    return (_jsxs(React.Fragment, { children: [_jsx("div", { "data-tip": true, "data-for": tid, className: "word-ellipsis", onMouseEnter: mouseEnterHandler, style: props.style, children: props.children }), showTooltip && (_jsx(ReactTooltip, __assign({ id: tid, className: "custom-tooltip", effect: "solid" }, props.options, { children: props.children })))] }));
};
export default EllipsisToolTip;
